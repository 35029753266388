#app {
    height: 100%;
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.swiper {
    /* width: 100%; */
    /* height: 100%; */
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-slide {
    width: 60%;
}

.swiper-slide:nth-child(2n) {
    width: 40%;
}

.swiper-slide:nth-child(3n) {
    width: 20%;
}

.swiper-pagination {
    position: absolute;
    bottom: 13px !important;
    /* background-color: #D9D9D9 !important; */
}

.swiper-pagination-bullet {
    /* color: #FFFFFF !important; */
    color: #FFFFFF !important;
    background: #FFFFFF;

    margin: 0 4px !important;
    width: 8px !important;
    height: 8px !important;
    /* border: 1px solid pink !important; */
}

.swiper-pagination-bullet-active {
    background-color: #FF038D !important;
    width: 8px!important;
    height: 8px!important;
  }

/* .swiper-scrollbar {
    width: 350px !important;
    height: 4px !important;
    left: 5.13% !important;
    top: 270px !important;
    background-color: #D9D9D9 !important;
}

.swiper-scrollbar-drag {
    background-color: #FF038D !important;
}

.swiper1 {
    width: 100px;
    height: 200px;
    border-radius: 10px;
} */