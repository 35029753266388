/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .div {
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        background-color: #fff;
        height: 80px;
        transition: height 0.3s ease;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #222;
        font-family: NanumSquare;
        z-index: 10;
    }
    
    .div:hover {
        height: 317px;
    }
    
    .line {
        position: absolute;
        top: 78px;
        left: 0px;
        background-color: #d9d9d9;
        width: 100%;
    
        height: 2px;
        z-index: 11;
    }

    .icon {
        position: absolute;
        top: 25px;
        left: 30px;
        width: 108px;
        height: 28px;
        object-fit: cover;
        z-index: 11;
    }
    .parent1 {
        position: absolute;
        top: 39px;
        left: 50%;
        transform: translate(-50%, -50%);
    
        /* width: 1042px; */
        width: 54.27%;
    
        /* width: 1200px; */
        font-weight: 600; /* 폰트 굵기 변경 */
        color: #222222;
        height: 21px;
        font-family: NanumSquare;
        font-size: 18px;
        z-index: 12;
    }
    .parent1 div:hover {
        cursor: pointer; /* 클릭 커서 */
        font-weight: 800;
    }
    .parent1 a {
        text-decoration: none; /* 링크 밑줄 제거 */
        color: black; /* 링크 텍스트 색상 검정으로 설정 */
    }

    .b6 {
        position: absolute;
        top: 0px;
        left: 0%;
    }
    .b7 {
        position: absolute;
        top: 0px;
        /* left: 266px; */
        left: 25.53%;
    
    }
    .tih {
        position: absolute;
        top: 0px;
        left: 49.52%;
        /* font-weight: 800; */
        /* font-size: 18px; */
        /* font-family: Roboto;
        color: #222222; */
    }
    .b8 {
        position: absolute;
        top: 0px;
        /* left: 745px; */
        left: 71.5%;
    
    }
    .contact {
        position: absolute;
        top: 0px;
        /* left: 978px; */
        left: 93.86%;
    
        /* font-weight: 800; */
        /* font-size: 18px; */
        /* font-family: Roboto;
        color: #222222; */
    }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .div {
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        background-color: #fff;
        height: 70px;
        transition: height 0.3s ease;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #222;
        font-family: NanumSquare;
        z-index: 10;
    }
    
    .div:hover {
        height: 317px;
    }
    
    .line {
        position: absolute;
        top: 68px;
        left: 0px;
        background-color: #d9d9d9;
        width: 100%;
    
        height: 2px;
        z-index: 11;
    }
    
    .icon {
        position: absolute;
        top: 25px;
        left: 25px;
        width: 86.4px;
        height: 22.4px;
        object-fit: cover;
        z-index: 11;
    }
    .parent1 {
        position: absolute;
        top: 38px;
        left: 50%;
        transform: translate(-50%, -50%);
    
        /* width: 1042px; */
        width: 54.27%;
    
        /* width: 1200px; */
        font-weight: 600; /* 폰트 굵기 변경 */
        color: #222222;
        height: 21px;
        font-family: NanumSquare;
        font-size: 13px;
        z-index: 12;
    }
    .parent1 div:hover {
        cursor: pointer; /* 클릭 커서 */
        font-weight: 800;
    }
    .parent1 a {
        text-decoration: none; /* 링크 밑줄 제거 */
        color: black; /* 링크 텍스트 색상 검정으로 설정 */
    }

    .b6 {
        position: absolute;
        top: 0px;
        left: 0%;
    }
    .b7 {
        position: absolute;
        top: 0px;
        /* left: 266px; */
        left: 25.53%;
    
    }
    .tih {
        position: absolute;
        top: 0px;
        left: 49.52%;
        /* font-weight: 800; */
        /* font-size: 18px; */
        /* font-family: Roboto;
        color: #222222; */
    }
    .b8 {
        position: absolute;
        top: 0px;
        /* left: 745px; */
        left: 71.5%;
    
    }
    .contact {
        position: absolute;
        top: 0px;
        /* left: 978px; */
        left: 93.86%;
    
        /* font-weight: 800; */
        /* font-size: 18px; */
        /* font-family: Roboto;
        color: #222222; */
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) { /*스타일입력*/}


.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 1920px;
    height: 2433px;
}
.div1 {
    position: absolute;
    top: 855px;
    left: 370px;
    font-size: 24px;
    font-weight: 800;
}
.it {
    position: absolute;
    top: 932px;
    left: 370px;
}
.b {
    position: absolute;
    top: 932px;
    left: 1484px;
    color: #666;
    text-align: right;
}
.b1 {
    position: absolute;
    top: 982px;
    left: 370px;
}
.b2 {
    position: absolute;
    top: 982px;
    left: 1484px;
    color: #666;
    text-align: right;
}
.ai {
    position: absolute;
    top: 1032px;
    left: 370px;
}
.b3 {
    position: absolute;
    top: 1032px;
    left: 1484px;
    color: #666;
    text-align: right;
}
.ai1 {
    position: absolute;
    top: 1082px;
    left: 370px;
}
.b4 {
    position: absolute;
    top: 1082px;
    left: 1484px;
    color: #666;
    text-align: right;
}
.ai2 {
    margin-block-start: 0;
    margin-block-end: 12px;
}
.p {
    margin: 0;
}
.aiContainer {
    position: absolute;
    top: 253px;
    left: 130px;
    font-size: 56px;
    line-height: 88px;
    font-weight: 800;
    color: #fff;
}
.div2 {
    position: absolute;
    top: 1352px;
    left: 370px;
    font-size: 24px;
    font-weight: 800;
}
.b5 {
    position: absolute;
    top: 1356px;
    left: 1430px;
    color: #666;
    text-align: right;
}
.groupItem {
    position: absolute;
    top: 1429px;
    left: 370px;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 380px;
    height: 275px;
}
.groupInner {
    position: absolute;
    top: 1429px;
    left: 770px;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 380px;
    height: 275px;
}
.rectangleDiv {
    position: absolute;
    top: 1429px;
    left: 1170px;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 380px;
    height: 275px;
}
.groupIcon {
    position: absolute;
    top: 1754px;
    left: 892px;
    width: 136px;
    height: 14px;
}

/* .b6, .b7, .tih, .b8, .contact {
    font-weight: 600;
    transition: font-weight 0.3s ease;
} */

/* .b6:hover, .b7:hover, .tih:hover, .b8:hover, .contact:hover {
    font-weight: 900;
} */
.parent {
    position: relative;
    top: 12px;
    left: 432px;

    /* width: 1042px; */
    width: 1400px;

    height: 21px;
    /* font-family: NanumSquare;
    font-size: 18px; */
    z-index: 11;
}

.menuButton {
    /* position: relative; */
    /* display: flex; */
    margin-right: 165px;
    left: 0px;
    top: 0px;
    width: 80px;
    height: 50px;
    background-color: #fff;
    /* border: none; */
     font-family: NanumSquare;
    font-size: 18px;
    font-weight: 700;
    /* text-align: left; */
    cursor: pointer; /* 커서 변경 */
    text-align: left;
}

.menuButton1 {
    margin-right: 165px;
    padding: 10px 20px; /* 버튼의 내부 여백 설정 */
    background-color: #fff;
    border: none; /* 보더 제거 */
    font-family: NanumSquare;
    font-size: 18px;
    font-weight: 700;
    transition: font-weight 0.3s ease;
    cursor: pointer; /* 커서 변경 */
}

.menuButtonHover:hover {
    font-weight: 800;
}
.menuButton button:hover {
    cursor: pointer; /* 클릭 커서 */
    font-weight: 800; /* 폰트 굵기 변경 */
}


/* @media (max-width: 1280px) {
    .icon {
        right: 1152px;
        left: auto;
    }
} */
.groupChild1 {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 1920px;
    height: 675px;
    object-fit: cover;
}
.ellipseDiv {
    position: absolute;
    top: 716px;
    left: 956px;
    border-radius: 50%;
    background-color: #fff;
    width: 8px;
    height: 8px;
}
.groupChild2 {
    position: absolute;
    top: 716px;
    left: 938px;
    border-radius: 50%;
    background-color: #ff038d;
    width: 8px;
    height: 8px;
}
.groupChild3 {
    position: absolute;
    top: 716px;
    left: 974px;
    border-radius: 50%;
    background-color: #fff;
    width: 8px;
    height: 8px;
}
.aiContainer1 {
    position: absolute;
    top: 180px;
    left: 130px;
    font-size: 56px;
    line-height: 88px;
    font-weight: 800;
    color: #fff;
}
.groupChild4 {
    position: absolute;
    top: 2018px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.groupChild5 {
    position: absolute;
    top: 1924px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.div3 {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.b9 {
    position: absolute;
    top: 73px;
    left: 0px;
}
.rdCenter {
    position: absolute;
    top: 111px;
    left: 0px;
}
.tel82322771700 {
    position: absolute;
    top: 149px;
    left: 0px;
}
.mailBizvdigmcom {
    position: absolute;
    top: 187px;
    left: 0px;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div4 {
    position: absolute;
    top: 0px;
    left: 1560px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div5 {
    position: absolute;
    top: 0px;
    left: 1620px;
    width: 40px;
    height: 40px;
}
.group {
    position: absolute;
    top: 2128px;
    left: 130px;
    width: 1660px;
    height: 205px;
    font-size: 16px;
    color: #999;
}
.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.korOff {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}
.korOn {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
    color: #3c3c3c;
}
.engOff {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}
.engOn {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #3c3c3c;
}
.container {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    color: #3c3c3c;
    font-family: Roboto;
    z-index: 10;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    left: 0px;
    /* width: 1920px; */
    width: 100%;

    height: 2433px;
}












