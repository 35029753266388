.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1161px;
}

.icon {
    position: absolute;
    top: 770px;
    left: 22px;
    width: 346px;
    height: 223px;
}

.icon1 {
    position: absolute;
    top: 747px;
    left: 70px;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    object-fit: cover;
}

.icon2 {
    position: absolute;
    top: 851px;
    left: 275px;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    object-fit: cover;
}

.icon3 {
    position: absolute;
    top: 955px;
    left: 69px;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    object-fit: cover;
}

.icon4 {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon5 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.tihtomorrowInHere {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
}

.p {
    margin-block-start: 0;
    margin-block-end: 10px;
}

.div {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
}

.tomorrowInHere {
    position: absolute;
    top: 515px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}

.tih1 {
    position: absolute;
    top: 782px;
    left: 126px;
    line-height: 15px;
}

.tihAiNpc {
    position: absolute;
    top: 990px;
    left: 126px;
    line-height: 15px;
}

.stage {
    position: absolute;
    top: 886px;
    left: 59px;
    line-height: 15px;
    text-align: right;
}

.tih2 {
    position: absolute;
    top: 711px;
    left: 20px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
}

.d3d {
    margin-block-start: 0;
    margin-block-end: 8px;
}

.d3dContainer {
    position: absolute;
    top: 550px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}

.k {
    margin: 0;
}

.kContainer {
    position: absolute;
    top: 807px;
    left: 126px;
    font-size: 12px;
    line-height: 15px;
    color: #3c3c3c;
}

.aiNpc {
    margin-block-start: 0;
    margin-block-end: 4px;
}

.tihContainer {
    position: absolute;
    top: 1015px;
    left: 126px;
    font-size: 12px;
    line-height: 15px;
    color: #3c3c3c;
}

.personaContainer {
    position: absolute;
    top: 911px;
    left: 37px;
    font-size: 12px;
    line-height: 15px;
    color: #3c3c3c;
    text-align: right;
}

.b {
    position: absolute;
    top: 515px;
    left: 326px;
    font-size: 12px;
    line-height: 15px;
    color: #666;
    text-align: right;
}

.div1 {
    position: absolute;
    top: 1222px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}

.b1 {
    position: absolute;
    top: 1257px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.rdCenter {
    position: absolute;
    top: 1282px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.c304 {
    position: absolute;
    top: 1302px;
    left: 103px;
    line-height: 15px;
    color: #999;
}

.tel82322771700 {
    position: absolute;
    top: 1327px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.mailBizvdigmcom {
    position: absolute;
    top: 1352px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.icon6 {
    position: absolute;
    top: 1170px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}

.icon7 {
    position: absolute;
    top: 1417px;
    left: 326px;
    width: 44px;
    height: 44px;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div2 {
    position: absolute;
    top: 1387px;
    left: 20px;
    width: 24px;
    height: 24px;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div3 {
    position: absolute;
    top: 1387px;
    left: 54px;
    width: 24px;
    height: 24px;
}

.tihImageIcon {
    position: absolute;
    top: 285px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}

.icon8 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 1357px;
    object-fit: contain;
    opacity: 0.6;
}

.icon9 {
    position: absolute;
    top: 567px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1161px;
    left: 50%;
    transform: translate(-50%);
}

.tih {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1161px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #222;
    font-family: NanumSquare;
}