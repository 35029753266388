/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .div {
        width: 100%;
        position: relative;
        left: 0px;
        top: 0px;
        background-color: #fff;
        /* height: 80px; */
        height: 1800px;
        /* height: 100%; */

    
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #222;
        /* font-family: NanumSquare; */
        z-index: 10;
    }
    .groupChild4 {
        position: absolute;
        top: 156px;
        /* left: 0px; */
        background-color: #fff;
        width: 100%;
        /* width: 100%; */
        left: 50%;
        transform: translate(-50%, -50%);
        height: 317px;
        z-index: 20;
        font-size: 16px;
        color: #666666;
        font-family: NanumSquare;
        font-weight: 400;
        /* visibility: hidden; */
    }
    .groupChild4 div:hover {
        cursor: pointer; /* 클릭 커서 */
        color: #222222; /* 텍스트 색 변경 */
        font-weight: 600; /* 폰트 굵기 변경 */
    }
    .groupChild4 a {
        text-decoration: none; /* 링크 밑줄 제거 */
        color: black; /* 링크 텍스트 색상 검정으로 설정 */
    }
    .div3 {
        position: absolute;
        top: 105px;
        /* left: 439px; */
        left: 22.86%;
    
        font-size: 16px;
    
        font-weight: 400;
        /* color: #222; */
        /* color: #666666; */
        color: #666;
        font-family: NanumSquare;
    }
    .div4 {
        position: absolute;
        top: 105px;
        left: 61.67%;
        font-weight: 400;
        font-family: NanumSquare;
        color: #666;
    }
    
    .aiAvatar {
        position: absolute;
        top: 105px;
        left: 36.72%;
        font-weight: 300;
        font-family: Roboto;
        color: #666;
    }
    
    
    .tih {
        position: absolute;
        top: 105px;
        left: 49.74%;
        font-weight: 300;
        font-family: Roboto;
        color: #666;
    }
    .businessContact {
        position: absolute;
        top: 105px;
        left: 73.8%;
        font-weight: 300;
        font-family: Roboto;
        color: #666;
    }
    .div5 {
        position: absolute;
        top: 153px;
        left: 22.86%;
        font-family: NanumSquare;
        font-weight: 400;
        color: #666;
    }
    .div6 {
        position: absolute;
        top: 153px;
        left: 61.67%;
        font-family: NanumSquare;
        font-weight: 400;
        color: #666;
    }
    .deepReal {
        position: absolute;
        top: 154px;
        left: 36.72%;
        font-weight: 300;
        font-family: Roboto;
        color: #666;
    }
    .div7 {
        position: absolute;
        top: 201px;
        left: 22.86%;
        font-family: NanumSquare;
        font-weight: 400;
        color: #666;
    }
    .div8 {
        position: absolute;
        top: 201px;
        left: 61.67%;
        font-family: NanumSquare;
        font-weight: 400;
        color: #666;
    }
    .dVirtualStage {
        position: absolute;
        top: 203px;
        left: 36.72%;
        font-weight: 300;
        font-family: Roboto;
        color: #666;
    }
    .div9 {
        position: absolute;
        top: 249px;
        left: 22.86%;
        font-family: NanumSquare;
        font-weight: 400;
        color: #666;
    }
    .container {
        position: absolute;
        top: 28px;
        /* left: 1761px; */
        right: 30px;
    
        width: 129px;
        height: 18px;
        /* height: 0.01%; */

        color: #3c3c3c;
        font-family: Roboto;
        z-index: 20;
    }
    
    /* @media (max-width: 1280px) {
        .container {
            left: 1144px;
            right: auto;
        }
    } */
    
    .icon1 {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 18px;
        height: 18px;
    }
    .korOff {
        position: absolute;
        top: 2px;
        left: 38px;
        line-height: 15px;
        font-weight: 600;
        color: #666;
    }
    .korOn {
        position: absolute;
        top: 2px;
        left: 38px;
        line-height: 15px;
        font-weight: 600;
        color: #3c3c3c;
    }
    .engOff {
        position: absolute;
        top: 2px;
        left: 93px;
        line-height: 15px;
        font-weight: 600;
        color: #666;
    }
    .engOn {
        position: absolute;
        top: 2px;
        left: 93px;
        line-height: 15px;
        font-weight: 600;
        color: #3c3c3c;
    }
    .korOn:hover,  .korOff:hover, .engOn:hover, .engOff:hover {
        cursor: pointer;
    }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1279px) {
    .div {
        width: 100%;
        position: relative;
        left: 0px;
        top: 0px;
        background-color: #fff;
        /* height: 80px; */
        height: 1800px;
    
        overflow: hidden;
        text-align: left;
        font-size: 15px;
        color: #222;
        /* font-family: NanumSquare; */
        z-index: 10;
    }
    .groupChild4 {
        position: absolute;
        top: 156px;
        /* left: 0px; */
        background-color: #fff;
        width: 100%;
        /* width: 100%; */
        left: 50%;
        transform: translate(-50%, -50%);
        height: 317px;
        z-index: 20;
        font-size: 13px;
        color: #666666;
        font-family: NanumSquare;
        font-weight: 400;
        /* visibility: hidden; */
    }
    .groupChild4 div:hover {
        cursor: pointer; /* 클릭 커서 */
        color: #222222; /* 텍스트 색 변경 */
        font-weight: 600; /* 폰트 굵기 변경 */
    }
    .groupChild4 a {
        text-decoration: none; /* 링크 밑줄 제거 */
        color: black; /* 링크 텍스트 색상 검정으로 설정 */
    }
    .div3 {
        position: absolute;
        top: 105px;
        /* left: 439px; */
        left: 22.86%;
    
        font-size: 13px;
    
        font-weight: 400;
        /* color: #222; */
        /* color: #666666; */
        color: #666;
        font-family: NanumSquare;
    }
    .div4 {
        position: absolute;
        top: 105px;
        left: 61.67%;
        font-weight: 400;
        font-family: NanumSquare;
        color: #666;
    }
    
    .aiAvatar {
        position: absolute;
        top: 105px;
        left: 36.72%;
        font-weight: 300;
        font-family: Roboto;
        color: #666;
    }
    
    
    .tih {
        position: absolute;
        top: 105px;
        left: 49.74%;
        font-weight: 300;
        font-family: Roboto;
        color: #666;
    }
    .businessContact {
        position: absolute;
        top: 105px;
        left: 73.8%;
        font-weight: 300;
        font-family: Roboto;
        color: #666;
    }
    .div5 {
        position: absolute;
        top: 153px;
        left: 22.86%;
        font-family: NanumSquare;
        font-weight: 400;
        color: #666;
    }
    .div6 {
        position: absolute;
        top: 153px;
        left: 61.67%;
        font-family: NanumSquare;
        font-weight: 400;
        color: #666;
    }
    .deepReal {
        position: absolute;
        top: 154px;
        left: 36.72%;
        font-weight: 300;
        font-family: Roboto;
        color: #666;
    }
    .div7 {
        position: absolute;
        top: 201px;
        left: 22.86%;
        font-family: NanumSquare;
        font-weight: 400;
        color: #666;
    }
    .div8 {
        position: absolute;
        top: 201px;
        left: 61.67%;
        font-family: NanumSquare;
        font-weight: 400;
        color: #666;
    }
    .dVirtualStage {
        position: absolute;
        top: 203px;
        left: 36.72%;
        font-weight: 300;
        font-family: Roboto;
        color: #666;
    }
    .div9 {
        position: absolute;
        top: 249px;
        left: 22.86%;
        font-family: NanumSquare;
        font-weight: 400;
        color: #666;
    }
    .container {
        position: absolute;
        top: 28px;
        /* left: 1761px; */
        right: 20px;
    
        width: 129px;
        height: 18px;
        color: #3c3c3c;
        font-family: Roboto;
        z-index: 20;
    }
    
    /* @media (max-width: 1280px) {
        .container {
            left: 1144px;
            right: auto;
        }
    } */
    
    .icon1 {
        position: absolute;
        top: 0px;
        left: 35px;
        width: 15px;
        height: 15px;
    }
    .korOff {
        position: absolute;
        top: 2px;
        left: 63px;
        line-height: 12px;
        font-weight: 600;
        color: #666;
    }
    .korOn {
        position: absolute;
        top: 2px;
        left: 63px;
        line-height: 12px;
        font-weight: 600;
        color: #3c3c3c;
    }
    .engOff {
        position: absolute;
        top: 2px;
        left: 103px;
        line-height: 12px;
        font-weight: 600;
        color: #666;
    }
    .engOn {
        position: absolute;
        top: 2px;
        left: 103px;
        line-height: 12px;
        font-weight: 600;
        color: #3c3c3c;
    }
    .korOn:hover,  .korOff:hover, .engOn:hover, .engOff:hover {
        cursor: pointer;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}


.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 1920px;
    height: 2018px; 
}
.div1 {
    position: absolute;
    top: 855px;
    left: 370px;
    font-size: 24px;
    font-weight: 800;
}
.it {
    position: absolute;
    top: 932px;
    left: 370px;
}

.ai {
    position: absolute;
    top: 1032px;
    left: 370px;
}
.b3 {
    position: absolute;
    top: 1032px;
    left: 1484px;
    color: #666;
    text-align: right;
}
.ai1 {
    position: absolute;
    top: 1082px;
    left: 370px;
}
.b4 {
    position: absolute;
    top: 1082px;
    left: 1484px;
    color: #666;
    text-align: right;
}



/* .groupItem {
    position: absolute;
    top: 1429px;
    left: 370px;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 380px;
    height: 275px;
}
.groupInner {
    position: absolute;
    top: 1429px;
    left: 770px;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 380px;
    height: 275px;
}
.rectangleDiv {
    position: absolute;
    top: 1429px;
    left: 1170px;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 380px;
    height: 275px;
} */
.groupIcon {
    position: absolute;
    top: 1754px;
    left: 892px;
    width: 136px;
    height: 14px;
}
.b6 {
    position: absolute;
    top: 0px;
    left: 0px;
}
.b7 {
    position: absolute;
    top: 0px;
    left: 266px;
}
/* .tih {
    position: absolute;
    top: 0px;
    left: 516px;
    font-weight: 500;
    font-family: Roboto;
} */
.b8 {
    position: absolute;
    top: 0px;
    left: 745px;
}
.contact {
    position: absolute;
    top: 0px;
    left: 978px;
    font-weight: 500;
    font-family: Roboto;
}
.parent {
    position: absolute;
    top: 28px;
    left: 439px;
    width: 1042px;
    height: 21px;
}
/* .icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}
.groupChild1 {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 1920px;
    height: 675px;
    object-fit: cover;
}
.ellipseDiv {
    position: absolute;
    top: 716px;
    left: 956px;
    border-radius: 50%;
    background-color: #fff;
    width: 8px;
    height: 8px;
} */
/* .groupChild2 {
    position: absolute;
    top: 716px;
    left: 938px;
    border-radius: 50%;
    background-color: #ff038d;
    width: 8px;
    height: 8px;
}
.groupChild3 {
    position: absolute;
    top: 716px;
    left: 974px;
    border-radius: 50%;
    background-color: #fff;
    width: 8px;
    height: 8px;
} */

/* .groupChild4 {
    position: absolute;
    top: 2018px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
} */
.groupChild5 {
    position: absolute;
    top: 1924px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
/* .div3 {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
    font-family: NanumSquare;
    font-weight: 400;
} */



/* .div4 {
    position: absolute;
    top: 0px;
    left: 1560px;
    width: 40px;
    height: 40px;
    font-family: NanumSquare;
    font-weight: 400;
} */
/* .item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
} */

/* .div5 {
    position: absolute;
    top: 0px;
    left: 1620px;
    width: 40px;
    height: 40px;
    font-family: NanumSquare;
    font-weight: 400;
} */
/* .group {
    position: absolute;
    top: 2128px;
    left: 130px;
    width: 1660px;
    height: 205px;
    font-size: 16px;
    color: #999;
}
.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
} */
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}


/* .div {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 2018px; 
    overflow: hidden;
    text-align: left;
    font-size: 18px;
    color: #222;
    font-family: NanumSquare;
} */




.category {
    position: relative;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 1920px;
    height: 317px;
    z-index: 20;
}

.groupChild5 {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}
.groupChild6 {
    position: absolute;
    top: 2018px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}

.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
    z-index: 11;
}

.parent {
    position: absolute;
    top: 2128px;
    left: 130px;
    width: 1660px;
    height: 205px;
    color: #999;
}

.parent {
    position: absolute;
    top: 28px;
    left: 439px;
    width: 1042px;
    height: 21px;
}
.b7 {
    position: absolute;
    top: 0px;
    left: 266px;
}

.b8 {
    position: absolute;
    top: 0px;
    left: 745px;
}
.contact {
    position: absolute;
    top: 0px;
    left: 978px;
    font-weight: 500;
    font-family: Roboto;
}



/* @media (max-width: 1280px) {
    .container {
        left: 1144px;
        right: auto;
    }
} */


.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}

/* .div {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 2433px;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    color: #666;
    font-family: NanumSquare;
} */



