body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'NanumSquare';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; 
}

.body::-webkit-scrollbar-thumb {
  background: #e89a3e;
  border: 2px solid #9b6aef;
  border-radius: 12px 12px 12px 12px;
}

.body::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

.body::-webkit-scrollbar-track {
  background: #9b6a2f;
}

.body::-webkit-scrollbar-button {
  visibility:hidden;
}

/* @font-face {
  font-family: 'NanumSquare'; 
  src: url('../public/assets/fonts/NanumSquare_acL.ttf'); 
  font-weight: 300;
}
@font-face {
  font-family: 'NanumSquare'; 
  src: url('../public/assets/fonts/NanumSquare_acR.ttf'); 
  font-weight: 500;
}
@font-face {
  font-family: 'NanumSquare7'; 
  src: url('../public/assets/fonts/NanumSquare_acB.ttf'); 
  font-weight: 700;
}
@font-face {
  font-family: 'NanumSquare'; 
  src: url('../public/assets/fonts/NanumSquare_acEB.ttf'); 
  font-weight: 800;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/assets/fonts/RobotoCondensed-Regular.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url('../public/assets/fonts/RobotoCondensed-Bold.ttf');
  font-weight: 700;
} */
/* @font-face {
  font-family: 'Roboto';
  src: url('../public/assets/fonts/RobotoCondensed-Regular.ttf');
  font-weight: 800;
}
@font-face {
  font-family: 'Roboto';
  src: url('../public/assets/fonts/RobotoCondensed-Regular.ttf');
}
@font-face {
  font-family: 'Roboto';
  src: url('../public/assets/fonts/RobotoCondensed-Regular.ttf');
  font-weight: 800;
}
@font-face {
  font-family: 'Roboto';
  src: url('../public/assets/fonts/RobotoCondensed-Regular.ttf');
}*/
