/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .news {
        position: absolute;
        top: 855px;
        /* left: 19.27%; */
        width: 1165px;
        left: 50%; /* 요소의 가운데를 부모 요소의 가운데로 이동 */
        transform: translateX(-50%); /* 요소의 가운데를 부모 요소의 가운데로 이동 */
        
    }
    .div1 {
        position: absolute;
        top: 0px;
        /* left: 370px; */
        left: 0px;
    
        font-size: 24px;
        font-weight: 800;
        font-family: NanumSquare;
        color: #222;
    }
    .div1En {
        position: absolute;
        /* top: 855px; */
        top: 0px;
    
        /* left: 370px; */
        left: 0%;
    
        font-size: 24px;
        font-weight: 800;
        font-family: Roboto;
        color: #222;
    }
    .bb {
        position: relative;
        /* top: 917px; */
        top: 62px;
    
        /* left: 1504px; */
        left: 98%;
    
        color: #666;
        text-decoration: none;
        font-weight: 700;
        font-family: NanumSquare;
    }
    .bbEn {
        position: relative;
        /* top: 917px; */
        top: 62px;
        /* left: 1536px; */
        /* left: 1166px; */
        /* left: 60.73%; */
        left: 100%;
        font-size: 18px;
        color: #666;
        text-decoration: none;
        font-weight: 600;
        font-family: Roboto;
    }
    .title {
        position: relative;
        /* top: 917px; */
        top: 0%;
    
        left: 0%;
        line-height: 50px;
        color: #3c3c3c;
    }
    .titleKo {
        position: relative;
        top: 62px;
        /* left: 20px; */
        left: -5.6%;
    
        line-height: 50px;
        color: #3c3c3c;
        font-family: NanumSquare;
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
    
    }
    .titleEn {
        position: absolute;
        /* top: 917px; */
        top: 62px;
        /* left: 20px; */
        /* left: 324px; */
        /* left: 330px; */
        left: 0%;
        line-height: 50px;
        color: #3c3c3c;
        font-family: Roboto;
        font-weight: 500;
        font-style: normal;
        font-size: 18px;
    }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .news {
        position: absolute;
        top: 684px;
        /* left: 19.27%; */
        width: 700px;
        left: 50%; /* 요소의 가운데를 부모 요소의 가운데로 이동 */
        transform: translateX(-50%); /* 요소의 가운데를 부모 요소의 가운데로 이동 */
        
    }
    .div1 {
        position: absolute;
        top: 0px;
        /* left: 370px; */
        left: 0%;
    
        font-size: 20px;
        font-weight: 800;
        font-family: NanumSquare;
        color: #222;
    }
    .div1En {
        position: absolute;
        /* top: 855px; */
        top: 0px;
    
        /* left: 370px; */
        left: 0%;
    
        font-size: 20px;
        font-weight: 800;
        font-family: Roboto;
        color: #222;
    }
    .bb {
        position: relative;
        /* top: 917px; */
        top: 50px;
    
        /* left: 1504px; */
        left: 97%;
        font-size: 15px;
        color: #666;
        text-decoration: none;
        font-weight: 700;
        font-family: NanumSquare;
    }
    .bbEn {
        position: relative;
        /* top: 917px; */
        top: 50px;
        /* left: 1536px; */
        /* left: 1166px; */
        /* left: 60.73%; */
        left: 100%;
        font-size: 15px;
        color: #666;
        text-decoration: none;
        font-weight: 600;
        font-family: Roboto;
    }
    .title {
        position: relative;
        /* top: 917px; */
        top: 0%;
    
        left: 0%;
        line-height: 50px;
        color: #3c3c3c;
    }
    .titleKo {
        position: absolute;
        top: 49.6px;
        /* left: 20px; */
        /* left: 304px; */
        left: 0%;
    
        line-height: 50px;
        color: #3c3c3c;
        font-family: NanumSquare;
        font-weight: 700;
        font-style: normal;
        font-size: 15px;
    
    }
    .titleEn {
        position: absolute;
        /* top: 917px; */
        top: 49.6px;
        /* left: 20px; */
        /* left: 324px; */
        /* left: 330px; */
        left: 0%;
        line-height: 50px;
        color: #3c3c3c;
        font-family: Roboto;
        font-weight: 500;
        font-style: normal;
        font-size: 15px;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}





.div {
    position: absolute;
    top: 750px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
}

/* .bb {
    position: relative;
    top: 780px;
    left: 337px;
    font-size: 12px;
    line-height: 35px;
    text-align: right;
    color: #666666;
    z-index: 10;
} */

/* .title {
    position: relative;
    top: 917px;
    top: 62px;

    left: 304px;
    line-height: 50px;
    color: #3c3c3c;
} */



.b {
    position: absolute;
    top: 932px;
    left: 1484px;
    color: #666;

}

.b1 {
    position: absolute;
    top: 982px;
    left: 370px;

}

.b2 {
    position: absolute;
    top: 982px;
    left: 1484px;
    color: #666;
    text-align: right;

}

.b3 {
    position: absolute;
    top: 1032px;
    left: 1484px;
    color: #666;
    text-align: right;

}

.b4 {
    position: absolute;
    top: 1082px;
    left: 1484px;
    color: #666;
    text-align: right;

}

.ai {
    position: absolute;
    top: 1032px;
    left: 370px;

}


.it {
    position: absolute;
    top: 932px;
    left: 370px;

}


.ai1 {
    position: absolute;
    top: 1082px;
    left: 370px;

}


#app {
    height: 100%;
}

/* html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;

    scrollbar-highlight-color: #D9D9D9;
    scrollbar-shadow-color: #D9D9D9;
    scrollbar-face-color: #D9D9D9;
} */

body::-webkit-scrollbar {
    width: 5px;
    /* 스크롤바의 너비 */

}

body::-webkit-scrollbar-thumb {
    height: 20%;
    /* 스크롤바의 길이 */
    background: #c0c0c0;

    border-radius: 10px;
    border-color: #c0c0c0;
}

body::-webkit-scrollbar-track {
    background: #DEDEDE;
}

/* body::-webkit-scrollbar {
    display: none;
  }
  
body:hover::-webkit-scrollbar {
    display: auto;
  } */

.mySwiper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.postImage {
    width: 300px;
    height: 300px;
    position: relative;
    overflow: hidden;
}

.imgDiv {
    display: flex;
    width: 100%;
    height: 100%;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}