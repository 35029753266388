.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 769px;
}

.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.div1 {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    color: #666;
}
.div2 {
    position: absolute;
    top: 220px;
    left: 137px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
}
.div3 {
    position: absolute;
    top: 220px;
    left: 91px;
    line-height: 15px;
    color: #666;
}
.div4 {
    position: absolute;
    top: 220px;
    left: 196px;
    line-height: 15px;
    color: #666;
}
.ai {
    margin-block-start: 0;
    margin-block-end: 10px;
}
.p {
    margin: 0;
}
.aiContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
    color: #222;
}
.icon2 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 965px;
    object-fit: contain;
    opacity: 0.6;
}
.icon3 {
    position: absolute;
    top: 816px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.div5 {
    position: absolute;
    top: 830px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}
.b {
    position: absolute;
    top: 865px;
    left: 20px;
    line-height: 15px;
}
.rdCenter {
    position: absolute;
    top: 890px;
    left: 20px;
    line-height: 15px;
}
.c304 {
    position: absolute;
    top: 910px;
    left: 103px;
    line-height: 15px;
}
.tel82322771700 {
    position: absolute;
    top: 935px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}
.mailBizvdigmcom {
    position: absolute;
    top: 960px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}
.icon4 {
    position: absolute;
    top: 778px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.icon5 {
    position: absolute;
    top: 1025px;
    left: 326px;
    width: 44px;
    height: 44px;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.div6 {
    position: absolute;
    top: 995px;
    left: 20px;
    width: 24px;
    height: 24px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.div7 {
    position: absolute;
    top: 995px;
    left: 54px;
    width: 24px;
    height: 24px;
}
.ceo {
    position: absolute;
    top: 335px;
    left: 260px;
    font-size: 16px;
    line-height: 15px;
    font-family: Roboto;
    color: #222;
    font-weight: 700;
}
.cto {
    position: absolute;
    top: 370px;
    left: 260px;
    font-size: 16px;
    line-height: 15px;
    font-family: Roboto;
    color: #222;
    font-weight: 700;
}
.ai1 {
    position: absolute;
    top: 434px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
    text-align: right;
    font-weight: 700;
}
.b1 {
    position: absolute;
    top: 498px;
    left: 260px;
    line-height: 15px;
    color: #3c3c3c;
    font-weight: 700;
}
.tih {
    position: absolute;
    top: 536px;
    left: 260px;
    line-height: 15px;
    color: #3c3c3c;
    font-weight: 700;
}
.d {
    position: absolute;
    top: 574px;
    left: 260px;
    line-height: 15px;
    color: #3c3c3c;
    font-weight: 700;
}
.b2 {
    position: absolute;
    top: 612px;
    left: 260px;
    line-height: 15px;
    color: #3c3c3c;
    font-weight: 700;
}
.groupItem {
    position: absolute;
    top: 286px;
    left: 194px;
    width: 2px;
    height: 389px;
    object-fit: contain;
}
.groupInner {
    position: absolute;
    top: 341px;
    left: 195px;
    width: 55px;
    height: 2px;

    object-fit: contain;
}
.lineIcon {
    position: absolute;
    top: 376px;
    left: 195px;
    width: 55px;
    height: 2px;
    object-fit: contain;
}
.groupChild1 {
    position: absolute;
    top: 504px;
    left: 195px;
    max-height: 100%;
    width: 55px;
    object-fit: contain;
    height: 2px;

}
.groupChild2 {
    position: absolute;
    top: 542px;
    left: 195px;
    max-height: 100%;
    width: 55px;
    object-fit: contain;
    height: 2px;

}
.groupChild3 {
    position: absolute;
    top: 580px;
    left: 195px;
    max-height: 100%;
    width: 55px;
    object-fit: contain;
    height: 2px;

}
.groupChild4 {
    position: absolute;
    top: 618px;
    left: 195px;
    max-height: 100%;
    width: 55px;
    object-fit: contain;
    height: 2px;

}
.groupChild5 {
    position: absolute;
    top: 440px;
    left: 140px;
    max-height: 100%;
    width: 53px;
    object-fit: contain;
    height: 2px;

}
.ellipseDiv {
    position: absolute;
    top: 337px;
    left: 189px;
    border-radius: 50%;
    background-color: #ff038d;
    width: 12px;
    height: 12px;
}
.groupChild6 {
    position: absolute;
    top: 372px;
    left: 189px;
    border-radius: 50%;
    background-color: #ff038d;
    width: 12px;
    height: 12px;
}
.groupChild7 {
    position: absolute;
    top: 500px;
    left: 189px;
    border-radius: 50%;
    background-color: #8200ff;
    width: 12px;
    height: 12px;
}
.groupChild8 {
    position: absolute;
    top: 538px;
    left: 189px;
    border-radius: 50%;
    background-color: #8200ff;
    width: 12px;
    height: 12px;
}
.groupChild9 {
    position: absolute;
    top: 576px;
    left: 189px;
    border-radius: 50%;
    background-color: #8200ff;
    width: 12px;
    height: 12px;
}
.groupChild10 {
    position: absolute;
    top: 614px;
    left: 189px;
    border-radius: 50%;
    background-color: #8200ff;
    width: 12px;
    height: 12px;
}
.groupChild11 {
    position: absolute;
    top: 436px;
    left: 189px;
    border-radius: 50%;
    background-color: #ff038d;
    width: 12px;
    height: 12px;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 769px;
    left: 50%;
    transform: translate(-50%);
}
.div {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 769px;

    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #999;
    font-family: NanumSquare;
}

