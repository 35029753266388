.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 724px;
}
.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.aiAvatar {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    color: #666;
}
.dVirtualStage {
    position: absolute;
    top: 220px;
    left: 181px;
    line-height: 15px;
    color: #666;
}
.deepReal1 {
    position: absolute;
    top: 220px;
    left: 97px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.vdigmsTechnologySpearheadin {
    margin-block-start: 0;
    margin-block-end: 10px;
}
.dLiveStreaming {
    margin: 0;
}
.vdigmsTechnologySpearheadinContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    color: #000;
}
.vdigmInc {
    position: absolute;
    top: 761px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}
.headOffice {
    font-weight: 500;
    font-family: Roboto;
}
.headOffice10f1012293Bu {
    margin-block-start: 0;
    margin-block-end: 2px;
}
.headOffice10fContainer {
    position: absolute;
    top: 796px;
    left: 20px;
    line-height: 15px;
}
.rdCenterC304Container {
    position: absolute;
    top: 838px;
    left: 20px;
    line-height: 15px;
}
.tel {
    font-weight: 500;
}
.tel82322771700 {
    position: absolute;
    top: 890px;
    left: 20px;
    line-height: 15px;
}
.mailBizvdigmcom {
    position: absolute;
    top: 915px;
    left: 20px;
    line-height: 15px;
}
.icon2 {
    position: absolute;
    top: 709px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.groupItem {
    position: absolute;
    top: 980px;
    left: 326px;
    box-shadow: 0px 0px 8.27px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    background-color: #fff;
    width: 44px;
    height: 44px;
}
.groupInner {
    position: absolute;
    top: 992px;
    left: 348px;
    width: 0px;
    height: 20px;
    object-fit: contain;
}
.ellipseDiv {
    position: absolute;
    top: 950px;
    left: 20px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram3288413192011Icon {
    position: absolute;
    top: 957px;
    left: 25px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.groupChild1 {
    position: absolute;
    top: 950px;
    left: 54px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram3288413192012Icon {
    position: absolute;
    top: 955.5px;
    left: 60.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.generativeAiAvatar {
    position: absolute;
    top: 515px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    color: #222;
}
.vdigmsAiTechnology {
    margin-block-start: 0;
    margin-block-end: 6px;
}
.vdigmsAiTechnologyContainer {
    position: absolute;
    top: 550px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}
.deeprealImageIcon {
    position: absolute;
    top: 285px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}
.icon3 {
    position: absolute;
    top: 89px;
    left: 385px;
    width: 0px;
    height: 933px;
    object-fit: contain;
    opacity: 0.6;
}
.icon4 {
    position: absolute;
    top: 690px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 724px;
    left: 50%;
    transform: translate(-50%);
}
.deepReal {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 724px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #999;
    font-family: Roboto;
}

