.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1707px;
}
.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.people {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    cursor: pointer;
}
.recruitmentProcess {
    position: absolute;
    top: 220px;
    left: 156px;
    line-height: 15px;
    cursor: pointer;
}
.benefits1 {
    position: absolute;
    top: 220px;
    left: 83px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
    cursor: pointer;
}
.vdigmCaresAbout {
    margin-block-start: 0;
    margin-block-end: 10px;
}
.itsEmployees {
    margin: 0;
}
.vdigmCaresAboutContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    color: #000;
}
.groupItem {
    position: absolute;
    top: 795px;
    left: 0px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}
.holidayGift {
    position: absolute;
    top: 1005px;
    left: 49px;
    line-height: 15px;
}
.maskGroupIcon {
    position: absolute;
    top: 815px;
    left: 20px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.groupInner {
    position: absolute;
    top: 795px;
    left: 180px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}
.weShareYour {
    margin-block-start: 0;
    margin-block-end: 8px;
}
.weShareYourContainer {
    position: absolute;
    top: 956px;
    left: 202px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.bereavementLeave {
    position: absolute;
    top: 1005px;
    left: 206px;
    line-height: 15px;
}
.maskGroupIcon1 {
    position: absolute;
    top: 815px;
    left: 200px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.rectangleDiv {
    position: absolute;
    top: 265px;
    left: 0px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}
.freeDrinks {
    position: absolute;
    top: 475px;
    left: 21px;
    line-height: 15px;
}
.maskGroupIcon2 {
    position: absolute;
    top: 285px;
    left: 20px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.groupChild1 {
    position: absolute;
    top: 265px;
    left: 180px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}
.lunchIsOn {
    position: absolute;
    top: 438px;
    left: 211px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.weOfferLunch {
    position: absolute;
    top: 475px;
    left: 220px;
    line-height: 15px;
}
.maskGroupIcon3 {
    position: absolute;
    top: 285px;
    left: 200px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.groupChild2 {
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}
.ofCourseWeContainer {
    position: absolute;
    top: 161px;
    left: 49px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.majorInsurances {
    position: absolute;
    top: 210px;
    left: 25px;
    line-height: 15px;
}
.maskGroupIcon4 {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.groupChild3 {
    position: absolute;
    top: 530px;
    left: 180px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}
.anUnforcedDiningContainer {
    position: absolute;
    top: 691px;
    left: 216px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.maskGroupIcon5 {
    position: absolute;
    top: 550px;
    left: 200px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.groupChild4 {
    position: absolute;
    top: 1060px;
    left: 180px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}
.embraceYourIndividualityContainer {
    position: absolute;
    top: 1221px;
    left: 202px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.wearWhateverYou {
    position: absolute;
    top: 1270px;
    left: 189px;
    line-height: 15px;
}
.maskGroupIcon6 {
    position: absolute;
    top: 1080px;
    left: 200px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.groupChild5 {
    position: absolute;
    top: 1060px;
    left: 0px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}
.celebrateLaborDayContainer {
    position: absolute;
    top: 1221px;
    left: 50px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.laborDayOff {
    position: absolute;
    top: 1270px;
    left: 44px;
    line-height: 15px;
}
.maskGroupIcon7 {
    position: absolute;
    top: 1080px;
    left: 20px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.groupChild6 {
    position: absolute;
    top: 530px;
    left: 0px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}
.dontCareAboutContainer {
    position: absolute;
    top: 691px;
    left: 40px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.takeAHalf {
    position: absolute;
    top: 740px;
    left: 23px;
    line-height: 15px;
}
.maskGroupIcon8 {
    position: absolute;
    top: 550px;
    left: 20px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.groupChild7 {
    position: absolute;
    top: 0px;
    left: 180px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}
.yourHealthOurContainer {
    position: absolute;
    top: 161px;
    left: 220px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.maskGroupIcon9 {
    position: absolute;
    top: 20px;
    left: 200px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.medicalCheckup {
    position: absolute;
    top: 210px;
    left: 211px;
    line-height: 15px;
}
.enjoyDrinksAndContainer {
    position: absolute;
    top: 426px;
    left: 39px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.duringWorkHours {
    position: absolute;
    top: 740px;
    left: 208px;
    line-height: 15px;
}
.holidayCheerByContainer {
    position: absolute;
    top: 956px;
    left: 22px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}
.rectangleGroup {
    position: absolute;
    top: 285px;
    left: 20px;
    width: 350px;
    height: 1305px;
    text-align: center;
}
.icon2 {
    position: absolute;
    top: 98px;
    left: 385px;
    width: 0px;
    height: 1907px;
    object-fit: contain;
    opacity: 0.6;
}
.icon3 {
    position: absolute;
    top: 690px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.vdigmInc {
    position: absolute;
    top: 1744px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
}
.headOffice {
    font-weight: 500;
    font-family: Roboto;
}
.headOffice10f1012293Bu {
    margin-block-start: 0;
    margin-block-end: 2px;
}
.headOffice10fContainer {
    position: absolute;
    top: 1779px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.rdCenterC304Container {
    position: absolute;
    top: 1821px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.tel {
    font-weight: 500;
}
.tel82322771700 {
    position: absolute;
    top: 1873px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.mailBizvdigmcom {
    position: absolute;
    top: 1898px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.icon4 {
    position: absolute;
    top: 1692px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.ellipseDiv {
    position: absolute;
    top: 1963px;
    left: 326px;
    box-shadow: 0px 0px 8.27px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    background-color: #fff;
    width: 44px;
    height: 44px;
}
.lineIcon {
    position: absolute;
    top: 1975px;
    left: 348px;
    width: 0px;
    height: 20px;
    object-fit: contain;
}
.groupChild8 {
    position: absolute;
    top: 1933px;
    left: 20px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram3288413192027Icon {
    position: absolute;
    top: 1940px;
    left: 25px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.groupChild9 {
    position: absolute;
    top: 1933px;
    left: 54px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram3288413192028Icon {
    position: absolute;
    top: 1938.5px;
    left: 60.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1707px;
    left: 50%;
    transform: translate(-50%);
}
.benefits {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1707px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #666;
    font-family: Roboto;
}

