/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .recruitmentProcess {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2246px;
        overflow: hidden;
        text-align: left;
        font-size: 28px;
        color: #222;
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .thisIsTheContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
    }
    .thisIsThe {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 2246px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 2246px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 456px;
        width: 100%;
        z-index: 2;
        font-size: 18px;
    }
    .people {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 18px;
        font-weight: 600;
        color: #999;
    }
    .benefits {
        position: absolute;
        top: 0px;
        left: 156px;
        font-size: 18px;
        font-weight: 600;
        color: #999;
    }
    .recruitmentProcess1 {
        position: absolute;
        top: 0px;
        left: 323px;
        font-size: 18px;
        font-weight: 600;
    }
    .ellipseDiv {
        position: absolute;
        top: -4px;
        left: 493px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .feelFreeToAskIfYouHaveAParent {
        position: absolute;
        top: 1694px;
        left: 370px;
        width: 513px;
        height: 205px;
    }
    .feelFreeToContainer {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .feelFreeTo {
        margin-block-start: 0;
        margin-block-end: 6px;
    }
    .forAnyInquiriesContainer {
        position: absolute;
        top: 122px;
        left: 0px;
        font-size: 18px;
        color: #666;
    }
    .forAnyInquiries {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    .us {
        font-family: Roboto;
        color: #666;
    }
    .bizvdigmcom {
        font-family: Roboto;
        color: #222;
    }
    .b {
        position: absolute;
        top: 577px;
        left: 370px;
    }
    .submitYourResumes {
        position: absolute;
        top: 1085px;
        left: 370px;
    }
    .groupChild1 {
        position: absolute;
        top: 648px;
        left: 569px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 155px;
        height: 155px;
    }
    .groupChild2 {
        position: absolute;
        top: 648px;
        left: 985px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 155px;
        height: 155px;
    }
    .groupChild3 {
        position: absolute;
        top: 678px;
        left: 781px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 155px;
        height: 155px;
    }
    .groupChild4 {
        position: absolute;
        top: 678px;
        left: 1194.05px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 155px;
        height: 155px;
    }
    .vectorIcon {
        position: absolute;
        top: 645px;
        left: 537px;
        width: 845px;
        height: 190px;
    }
    .submitAResumeContainer {
        position: absolute;
        top: 689px;
        left: 588px;
        line-height: 36.5px;
        color: #fff;
        text-align: center;
    }
    .onlineInterview {
        position: absolute;
        top: 718px;
        left: 797px;
        line-height: 36.5px;
        color: #fff;
        text-align: center;
    }
    .finalPass {
        position: absolute;
        top: 737px;
        left: 1208.09px;
        line-height: 36.5px;
        color: #fff;
        text-align: center;
    }
    .interview1 {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 36.5px;
    }
    .negotiation {
        position: absolute;
        top: 36.5px;
        left: 11.04px;
        font-size: 18px;
        line-height: 36.5px;
    }
    .interviewParent {
        position: absolute;
        top: 689px;
        left: 1004px;
        width: 117px;
        height: 73.5px;
        text-align: center;
        color: #fff;
    }
    .groupChild5 {
        position: absolute;
        top: 0px;
        left: 550px;
        box-shadow: 0px 0px 8.21px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: #fff;
        width: 500px;
        height: 80px;
    }
    .imageIcon {
        position: absolute;
        top: 28px;
        left: 737px;
        width: 125.3px;
        height: 23px;
        object-fit: cover;
    }
    .groupChild6 {
        position: absolute;
        top: 0px;
        left: 0px;
        box-shadow: 0px 0px 8.21px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: #fff;
        width: 500px;
        height: 80px;
    }
    .typeLogoIcon {
        position: absolute;
        top: 22px;
        left: 187px;
        width: 119.5px;
        height: 28.8px;
        object-fit: cover;
    }
    .rectangleGroup {
        position: absolute;
        top: 1266px;
        left: 435px;
        width: 1050px;
        height: 80px;
    }
    .thankYouForContainer {
        position: absolute;
        top: 1168px;
        left: 370px;
        font-size: 18px;
        color: #666;
    }
    .maskGroupIcon {
        position: absolute;
        top: 1596px;
        left: 890px;
        width: 660px;
        height: 400px;
        object-fit: cover;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .recruitmentProcess {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1796px;
        overflow: hidden;
        text-align: left;
        font-size: 28px;
        color: #222;
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .thisIsTheContainer {
        position: absolute;
        top: 144px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
    }
    .thisIsThe {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 1796px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1280px;
        height: 1796px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 364.8px;
        width: 100%;
        z-index: 2;
        font-size: 15px;
    }
    .people {
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 600;
        color: #999;
    }
    .benefits {
        position: absolute;
        top: 0px;
        left: 124.8px;
        font-weight: 600;
        color: #999;
    }
    .recruitmentProcess1 {
        position: absolute;
        top: 0px;
        left: 258.4px;
        font-weight: 600;
    }
    .ellipseDiv {
        position: absolute;
        top: -4px;
        left: 399px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .feelFreeToAskIfYouHaveAParent {
        position: absolute;
        /* top: 1694px; */
        top: 1315.2px;

        /* left: 370px; */
        left: 247.9px;

        width: 513px;
        height: 205px;
    }
    .feelFreeToContainer {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .feelFreeTo {
        margin-block-start: 0;
        margin-block-end: 5px;
    }
    .forAnyInquiriesContainer {
        position: absolute;
        /* top: 122px; */
        top: 97.6px;

        left: 0px;
        font-size: 15px;
        color: #666;
    }
    .forAnyInquiries {
        margin-block-start: 0;
        margin-block-end: 8px;
    }
    .us {
        font-family: Roboto;
        color: #666;
    }
    .bizvdigmcom {
        font-family: Roboto;
        color: #222;
    }
    .b {
        position: absolute;
        /* top: 577px; */
        top: 461.6px;

        left: 247.9px;
    }
    .submitYourResumes {
        position: absolute;
        top: 868px;
        left: 247.9px;
    }
    .groupChild1 {
        position: absolute;
        top: 648px;
        top: 518.4px;

        left: 569px;
        left: 381.23px;

        border-radius: 50%;
        background-color: #ff038d;
        width: 155px;
        width: 124px;

        height: 155px;
        height: 124px;

    }
    .groupChild2 {
        position: absolute;
        top: 518.4px;
        left: 985px;
        left: 659.95px;

        border-radius: 50%;
        background-color: #ff038d;
        width: 124px;
        height: 124px;
    }
    .groupChild3 {
        position: absolute;
        top: 678px;
        top: 542.4px;

        left: 781px;
        left: 523.27px;

        border-radius: 50%;
        background-color: #8200ff;
        width: 124px;
        height: 124px;
    }
    .groupChild4 {
        position: absolute;
        top: 542.4px;
        left: 1194.05px;
        left: 800.01px;

        border-radius: 50%;
        background-color: #8200ff;
        width: 124px;
        height: 124px;
    }
    .vectorIcon {
        position: absolute;
        top: 645px;
        top: 516px;

        left: 537px;
        left: 369.79px;

        width: 845px;
        width: 566.15px;

        height: 190px;
        height: 152px;

    }
    .submitAResumeContainer {
        position: absolute;
        top: 689px;
        top: 551.2px;

        left: 588px;
        left: 393.96px;

        line-height: 29.2px;
        color: #fff;
        text-align: center;
        font-size: 23px;
    }
    .onlineInterview {
        position: absolute;
        top: 718px;
        top: 574.4px;

        left: 797px;
        left: 536.99px;

        line-height: 29.2px;
        color: #fff;
        text-align: center;
        font-size: 23px;
    }
    .finalPass {
        position: absolute;
        top: 737px;
        top: 589.6px;

        left: 1208.09px;
        left: 809.42px;

        line-height: 29.2px;
        color: #fff;
        text-align: center;
        font-size: 23px;
    }
    .interview1 {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 29.2px;
    }
    .negotiation {
        position: absolute;
        top: 36.5px;
        left: 11.04px;
        font-size: 15px;
        line-height: 29.2px;
    }
    .interviewParent {
        position: absolute;
        top: 689px;
        top: 551.2px;

        left: 1004px;
        left: 672.68px;

        width: 117px;
        height: 73.5px;
        text-align: center;
        color: #fff;
        font-size: 23px;
    }
    .groupChild5 {
        position: absolute;
        top: 0px;
        left: 550px;
        left: 368.5px;

        box-shadow: 0px 0px 8.21px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: #fff;
        width: 500px;
        width: 335px;

        height: 80px;
        height: 53.6px;

    }
    .imageIcon {
        position: absolute;
        top: 28px;
        top: 22.4px;

        left: 737px;
        left: 493.79px;

        width: 125.3px;
        width: 83.95px;

        height: 23px;
        height: 15.41px;

        object-fit: cover;
    }
    .groupChild6 {
        position: absolute;
        top: 0px;
        left: 0px;
        box-shadow: 0px 0px 8.21px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: #fff;
        width: 335px;
        height: 53.6px;
    }
    .typeLogoIcon {
        position: absolute;
        top: 22px;
        top: 17.6px;

        left: 187px;
        left: 125.29px;

        width: 119.5px;
        width: 80.07px;

        height: 28.8px;
        height: 19.3px;

        object-fit: cover;
    }
    .rectangleGroup {
        position: absolute;
        top: 1266px;
        top: 1012.8px;

        left: 435px;
        left: 291.45px;

        width: 1050px;
        width: 703.5px;

        height: 80px;
        height: 53.6px;

    }
    .thankYouForContainer {
        position: absolute;
        top: 1168px;
        top: 934.4px;

        left: 370px;
        left: 247.9px;

        font-size: 15px;
        color: #666;
    }
    .maskGroupIcon {
        position: absolute;
        top: 1596px;
        top: 1276.8px;

        left: 890px;
        left: 596.3px;

        width: 660px;
        width: 442.2px;

        height: 400px;
        height: 268px;

        object-fit: cover;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}

.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}

.groupItem {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}
.groupInner {
    position: absolute;
    top: 0px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.rectangleDiv {
    position: absolute;
    top: 94px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.vdigmInc {
    position: absolute;
    top: 204px;
    left: 130px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.headOffice {
    position: absolute;
    top: 277px;
    left: 130px;
}
.f1012293 {
    position: absolute;
    top: 277px;
    left: 240px;
    font-weight: 500;
}
.c304SeoulAi {
    position: absolute;
    top: 316px;
    left: 240px;
    font-weight: 500;
}
.rdCenter {
    position: absolute;
    top: 316px;
    left: 130px;
}
.tel82322771700 {
    position: absolute;
    top: 355px;
    left: 130px;
    font-family: NanumSquare;
}
.mailBizvdigmcom {
    position: absolute;
    top: 393px;
    left: 130px;
    font-family: NanumSquare;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div {
    position: absolute;
    top: 204px;
    left: 1690px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div1 {
    position: absolute;
    top: 204px;
    left: 1750px;
    width: 40px;
    height: 40px;
}
.groupParent {
    position: absolute;
    top: 2152px;
    left: 0px;
    width: 1920px;
    height: 411px;
    font-size: 16px;
    color: #999;
}


.aboutUs {
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 500;
}
.solution {
    position: absolute;
    top: 0px;
    left: 274px;
    font-weight: 500;
}
.tih {
    position: absolute;
    top: 0px;
    left: 541px;
    font-weight: 500;
}
.career {
    position: absolute;
    top: 0px;
    left: 770px;
}
.contact {
    position: absolute;
    top: 0px;
    left: 1024px;
    font-weight: 500;
}
.aboutUsParent {
    position: absolute;
    top: 28px;
    left: 416px;
    width: 1088px;
    height: 21px;
    font-size: 18px;
}
.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #3c3c3c;
}
.parent {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    font-size: 18px;
    color: #666;
}

.people:hover, .benefits:hover, .recruitmentProcess1:hover {
    cursor: pointer;
}