/* .groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 923px;
}

.div1 {
    position: absolute;
    top: 304px;
    left: 46px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
    color: #222;
}

.div2 {
    position: absolute;
    top: 414px;
    left: 46px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
    color: #222;
}

.div3 {
    position: absolute;
    top: 610px;
    left: 46px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
    color: #222;
}

.div4 {
    position: absolute;
    top: 745px;
    left: 46px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
    color: #222;
}

.div5 {
    position: absolute;
    top: 499px;
    left: 46px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
    color: #222;
}

.div6 {
    position: absolute;
    top: 439px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div7 {
    position: absolute;
    top: 464px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div8 {
    position: absolute;
    top: 329px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div9 {
    position: absolute;
    top: 354px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div10 {
    position: absolute;
    top: 524px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div11 {
    position: absolute;
    top: 635px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div12 {
    position: absolute;
    top: 770px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div13 {
    position: absolute;
    top: 795px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div14 {
    position: absolute;
    top: 820px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div15 {
    position: absolute;
    top: 845px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div16 {
    position: absolute;
    top: 685px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div17 {
    position: absolute;
    top: 549px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.div18 {
    position: absolute;
    top: 574px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
    font-family: Roboto;
}

.b {
    position: absolute;
    top: 439px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.nipaAi {
    position: absolute;
    top: 464px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.sba {
    margin-block-start: 0;
    margin-block-end: 10px;
}

.p {
    margin: 0;
}

.sbaContainer {
    position: absolute;
    top: 635px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.worldOf2048 {
    position: absolute;
    top: 770px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.lgCnsAi {
    position: absolute;
    top: 795px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.aiAvatarTutor {
    position: absolute;
    top: 820px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.b1 {
    position: absolute;
    top: 845px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.nipaContainer {
    position: absolute;
    top: 685px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.tih {
    position: absolute;
    top: 329px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.nipaAiContainer {
    position: absolute;
    top: 354px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.tips {
    position: absolute;
    top: 524px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.koc {
    position: absolute;
    top: 549px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.nipaAi2 {
    position: absolute;
    top: 574px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.groupItem {
    position: absolute;
    top: 283px;
    left: 27px;
    width: 2px;
    height: 596px;
    object-fit: contain;
}

.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.div19 {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    color: #666;
}

.div20 {
    position: absolute;
    top: 220px;
    left: 162px;

    line-height: 15px;
    color: #666;
}

.div21 {
    position: absolute;
    top: 220px;
    left: 97px;

    line-height: 15px;
    font-weight: 800;
    color: #222;
}

.div22 {
    position: absolute;
    top: 220px;
    left: 299px;

    line-height: 15px;
    color: #666;
}

.aiContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
    color: #222;
}

.div23 {
    position: absolute;
    top: 316px;
    left: 32px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    transform-origin: 0 0;
}

.div24 {
    position: absolute;
    top: 426px;
    left: 32px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    transform-origin: 0 0;
}

.div25 {
    position: absolute;
    top: 511px;
    left: 32px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    transform-origin: 0 0;
}

.div26 {
    position: absolute;
    top: 622px;
    left: 32px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    transform-origin: 0 0;
}

.div27 {
    position: absolute;
    top: 757px;
    left: 32px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    transform-origin: 0 0;
}

.icon2 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 1169px;
    object-fit: contain;
    opacity: 0.6;
}

.div28 {
    position: absolute;
    top: 52px;
    left: 18px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}

.b2 {
    position: absolute;
    top: 87px;
    left: 18px;
    line-height: 15px;
}

.rdCenter {
    position: absolute;
    top: 112px;
    left: 18px;
    line-height: 15px;
}

.c304 {
    position: absolute;
    top: 132px;
    left: 101px;
    line-height: 15px;
}

.tel82322771700 {
    position: absolute;
    top: 157px;
    left: 18px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}

.mailBizvdigmcom {
    position: absolute;
    top: 182px;
    left: 18px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}

.icon3 {
    position: absolute;
    top: 0px;
    left: 0px;
    max-height: 100%;
    width: 386px;
}

.icon4 {
    position: absolute;
    top: 247px;
    left: 324px;
    width: 44px;
    height: 44px;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div29 {
    position: absolute;
    top: 217px;
    left: 18px;
    width: 24px;
    height: 24px;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div30 {
    position: absolute;
    top: 217px;
    left: 52px;
    width: 24px;
    height: 24px;
}

.parent {
    position: absolute;
    top: 982px;
    left: 2px;
    width: 386px;
    height: 291px;
    color: #999;
}

.icon5 {
    position: absolute;
    top: 810px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 390px;
    height: 923px;
}

.div {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 923px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #3c3c3c;
    font-family: NanumSquare;
} */

.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1118px;
}
.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.aboutUs {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    color: #666;
}
.organizationChart {
    position: absolute;
    top: 220px;
    left: 162px;
    line-height: 15px;
    color: #666;
}
.history1 {
    position: absolute;
    top: 220px;
    left: 96px;
    line-height: 15px;
    color: #222;
    font-weight: 700;
}
.vision {
    position: absolute;
    top: 220px;
    left: 299px;
    line-height: 15px;
    color: #666;
}
.shapingTheFuture {
    margin-block-start: 0;
    margin-block-end: 10px;
}
.experiencesWithGenerative {
    margin: 0;
}
.shapingTheFutureContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    color: #000;
    font-weight: 700;
}
.div {
    position: absolute;
    top: 483px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.div1 {
    position: absolute;
    top: 602px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.div2 {
    position: absolute;
    top: 644px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.div3 {
    position: absolute;
    top: 458px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.div4 {
    position: absolute;
    top: 560px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.div5 {
    position: absolute;
    top: 721px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.div6 {
    position: absolute;
    top: 857px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.div7 {
    position: absolute;
    top: 882px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.div8 {
    position: absolute;
    top: 924px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.div9 {
    position: absolute;
    top: 966px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.div10 {
    position: absolute;
    top: 780px;
    left: 45px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
}
.nipaAiVoucher {
    margin-block-start: 0;
    margin-block-end: 2px;
}
.nipaAiVoucherContainer {
    position: absolute;
    top: 483px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.venturesquareIncAndContainer {
    position: absolute;
    top: 602px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.nipaAiVoucherContainer1 {
    position: absolute;
    top: 644px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.establishmentOfA {
    position: absolute;
    top: 458px;
    left: 73px;
    letter-spacing: -0.01em;
    line-height: 15px;
    color: #3d3d3d;
}
.selectionForTheContainer {
    position: absolute;
    top: 560px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.selectionForSba {
    margin-block-start: 0;
    margin-block-end: 2px;
    letter-spacing: -0.02em;
}
.metaverseFashionShow {
    margin: 0;
    letter-spacing: -0.01em;
}
.selectionForSbaContainer {
    position: absolute;
    top: 721px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.mobileGameWorld {
    position: absolute;
    top: 857px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.lgCnsDevelopsContainer {
    position: absolute;
    top: 882px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.completionOfAiContainer {
    position: absolute;
    top: 924px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.establishACompany {
    position: absolute;
    top: 966px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.selectionOfDataContainer {
    position: absolute;
    top: 780px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}

.headOffice {
    font-weight: 500;
    font-family: Roboto;
}

.tel {
    font-weight: 500;
}

.icon2 {
    position: absolute;
    top: 1103px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.groupItem {
    position: absolute;
    top: 1374px;
    left: 326px;
    box-shadow: 0px 0px 8.27px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    background-color: #fff;
    width: 44px;
    height: 44px;
}
.groupInner {
    position: absolute;
    top: 1386px;
    left: 348px;
    width: 0px;
    height: 20px;
    object-fit: contain;
}
.ellipseDiv {
    position: absolute;
    top: 1344px;
    left: 20px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram328841319205Icon {
    position: absolute;
    top: 1351px;
    left: 25px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.groupChild1 {
    position: absolute;
    top: 1344px;
    left: 54px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram328841319206Icon {
    position: absolute;
    top: 1349.5px;
    left: 60.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.icon3 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 1314px;
    object-fit: contain;
    opacity: 0.6;
}
.icon4 {
    position: absolute;
    top: 816px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.div11 {
    position: absolute;
    top: 314px;
    left: 45px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    color: #222;
}
.div12 {
    position: absolute;
    top: 433px;
    left: 45px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    color: #222;
}
.div13 {
    position: absolute;
    top: 696px;
    left: 45px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    color: #222;
}
.div14 {
    position: absolute;
    top: 832px;
    left: 45px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    color: #222;
}
.div15 {
    position: absolute;
    top: 535px;
    left: 45px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    color: #222;
}
.div16 {
    position: absolute;
    top: 339px;
    left: 45px;
    line-height: 15px;
    font-weight: 700;
}
.div17 {
    position: absolute;
    top: 381px;
    left: 45px;
    line-height: 15px;
    font-weight: 700;
}
.tihVirtualStreamersContainer {
    position: absolute;
    top: 339px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.nipaAiVoucherContainer2 {
    position: absolute;
    top: 381px;
    left: 73px;
    line-height: 15px;
    color: #3d3d3d;
}
.lineIcon {
    position: absolute;
    top: 150px;
    left: 26px;
    width: 2px;
    height: 980px;
    object-fit: contain;
}
.div18 {
    position: absolute;
    top: 318px;
    left: 31px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform:  rotate(180deg);
    transform-origin: 0 0;
}
.div19 {
    position: absolute;
    top: 437px;
    left: 31px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform:  rotate(180deg);
    transform-origin: 0 0;
}
.div20 {
    position: absolute;
    top: 539px;
    left: 31px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform:  rotate(180deg);
    transform-origin: 0 0;
}
.div21 {
    position: absolute;
    top: 700px;
    left: 31px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform:  rotate(180deg);
    transform-origin: 0 0;
}
.div22 {
    position: absolute;
    top: 836px;
    left: 31px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform:  rotate(180deg);
    transform-origin: 0 0;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1118px;
    left: 50%;
    transform: translate(-50%);
}

/* .article {
    position: relative;
    top: -10px;
    width: 390px;
    height: 1118px;
    left: 50%;
    transform: translate(-50%);
} */

.history {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1118px;

    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #3D3D3D;
    font-family: Roboto;
    font-weight: 400;
}

