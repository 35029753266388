/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .contact {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2639px;
        overflow: hidden;
        text-align: left;
        font-size: 28px;
        color: #666;
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .businessContact {
        position: absolute;
        top: 224px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
        color: #222;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 2639px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 2639px;
    }
    .vdigmInc {
        position: absolute;
        top: 727px;
        left: 370px;
        font-weight: 800;
        font-family: NanumSquare;
        color: #222;
    }
    .tel {
        position: absolute;
        top: 859px;
        left: 370px;
    }
    .eMail {
        position: absolute;
        top: 942px;
        left: 370px;
    }
    .adress {
        position: absolute;
        top: 1075px;
        left: 370px;
        font-weight: 800;
        color: #222;
    }
    .rdCenter {
        position: absolute;
        top: 1241px;
        left: 370px;
    }
    .div {
        position: absolute;
        top: 1374px;
        left: 370px;
        font-weight: 800;
        font-family: NanumSquare;
        color: #222;
    }
    
    .head {
        position: absolute;
        top: 1456px;
        left: 370px;
        font-weight: 400;
        /* line-height: 15px; */
        color: #666;
        z-index: 5;
    }
    
    .headBold {
        position: absolute;
        top: 1456px;
        left: 370px;
        font-weight: 600;
        /* line-height: 15px; */
        color: #3c3c3c;
        z-index: 5;
    
    }
    
    .rd {
        position: absolute;
        top: 1456px;
        left: 568px;
        /* line-height: 15px; */
        color: #666;
        font-weight: 400;
        z-index: 5;
    
    }
    
    .rdBold {
        position: absolute;
        top: 1456px;
        left: 568px;
        /* line-height: 15px; */
        color: #3c3c3c;
        font-weight: 600;
        z-index: 5;
    
    }
    .headOffice {
        position: absolute;
        top: 1158px;
        left: 370px;
    }
    .div1 {
        position: absolute;
        top: 859px;
        left: 518px;
        font-weight: 500;
    }
    .bizvdigmcom {
        position: absolute;
        top: 942px;
        left: 518px;
        font-weight: 500;
    }
    .b {
        position: absolute;
        top: 1158px;
        left: 618px;
        font-family: NanumSquare;
    }
    .ai {
        position: absolute;
        top: 1241px;
        left: 618px;
        font-family: NanumSquare;
        white-space: pre-wrap;
    }
    .icon1 {
        position: absolute;
        top: 439px;
        left: 646px;
        width: 629px;
        height: 161px;
        object-fit: cover;
    }
    .map {
        position: absolute;
        top: 1539px;
        left: 370px;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .contact {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2111.2px;
        overflow: hidden;
        text-align: left;
        font-size: 20px;
        color: #666;
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .businessContact {
        position: absolute;
        top: 179.2px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
        color: #222;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 2639px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1280px;
        height: 2639px;
    }
    .vdigmInc {
        position: absolute;
        position: absolute;
        top: 727px;
        top: 581.6px;

        left: 370px;
        left: 247.9px;

        font-weight: 800;
        color: #222;
    }
    .tel {
        position: absolute;
        top: 859px;
        top: 687.2px;

        left: 247.9px;
    }
    .eMail {
        position: absolute;
        top: 942px;
        top: 753.6px;

        left: 247.9px;
    }
    .adress {
        position: absolute;
        top: 1075px;
        top: 860px;

        left: 247.9px;
        font-weight: 800;
        color: #222;
    }
    .rdCenter {
        position: absolute;
        top: 1241px;
        top: 992.8px;

        left: 247.9px;
    }
    .div {
        position: absolute;
        top: 1374px;
        top: 1099.2px;

        left: 247.9px;
        font-weight: 800;
        font-family: NanumSquare;
        color: #222;
    }
    
    .head {
        position: absolute;
        top: 1456px;
        top: 1164.8px;

        left: 247.9px;
        font-weight: 400;
        /* line-height: 15px; */
        color: #666;
        z-index: 5;
        font-family: NanumSquare;
    }
    
    .headBold {
        position: absolute;
        top: 1164.8px;
        left: 247.9px;
        font-weight: 600;
        /* line-height: 15px; */
        color: #3c3c3c;
        z-index: 5;
        font-family: NanumSquare;
    }
    
    .rd {
        position: absolute;
        top: 1164.8px;
        left: 568px;
        left: 380.56px;

        /* line-height: 15px; */
        color: #666;
        font-weight: 400;
        z-index: 5;
        font-family: Roboto;
    }
    
    .rdBold {
        position: absolute;
        top: 1164.8px;
        left: 380.56px;
        /* line-height: 15px; */
        color: #3c3c3c;
        font-weight: 600;
        z-index: 5;
        font-family: Roboto;
    }
    .headOffice {
        position: absolute;
        top: 926.4px;
        left: 247.9px;
    }
    .div1 {
        position: absolute;
        /* top: 859px; */
        top: 687.2px;

        /* left: 518px; */
        left: 347.06px;

        font-weight: 500;
    }
    .bizvdigmcom {
        position: absolute;
        top: 942px;
        top: 753.6px;

        left: 347.06px;
        font-weight: 500;
    }
    .b {
        position: absolute;
        top: 1158px;
        top: 926.4px;

        left: 618px;
        left: 399.06px;

        font-family: NanumSquare;
    }
    .ai {
        position: absolute;
        top: 1241px;
        top: 992.8px;

        left: 618px;
        left: 399.06px;

        font-family: NanumSquare;
        white-space: pre-wrap;
    }
    .icon1 {
        position: absolute;
        top: 439px;
        top: 351.2px;

        left: 646px;
        left: 402.82px;

        width: 629px;
        width: 503.2px;

        height: 161px;
        height: 128.8px;

        object-fit: cover;
    }
    .map {
        position: absolute;
        top: 1539px;
        top: 1231.2px;

        left: 370px;
        left: 247.9px;

    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) { /*스타일입력*/}



.head:hover, .headBold:hover, .rd:hover, .rdBold:hover {
    cursor: pointer;
}

.headOffice1 {
    position: absolute;
    top: 1456px;
    left: 370px;
    font-weight: 600;
    color: #3c3c3c;
}
.rdCenter1 {
    position: absolute;
    top: 1456px;
    left: 568px;
}
.b1 {
    position: absolute;
    top: 0px;
    left: 0px;
}
.b2 {
    position: absolute;
    top: 0px;
    left: 266px;
}
.tih {
    position: absolute;
    top: 0px;
    left: 516px;
    font-weight: 500;
    font-family: Roboto;
}
.b3 {
    position: absolute;
    top: 0px;
    left: 745px;
}
.contact1 {
    position: absolute;
    top: 0px;
    left: 978px;
    font-family: Roboto;
}
.parent {
    position: absolute;
    top: 28px;
    left: 439px;
    width: 1042px;
    height: 21px;
    font-size: 18px;
    color: #222;
    font-family: NanumSquare;
}
.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}

.groupItem {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}

.groupInner {
    position: absolute;
    top: 1539px;
    left: 370px;
    background-color: #d9d9d9;
    width: 1180px;
    height: 800px;
}

.icon2 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}
.group {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    font-size: 18px;
    color: #3c3c3c;
}

