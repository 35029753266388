/* @media screen and (max-width: 1080px) { */
.bbb {
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    z-index: 6;
}

.icon3 {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
    z-index: 7;

}

.icon5 {
    position: absolute;
    top: 52px;
    /* left: 344px; */
    right: 22px;
    width: 24px;
    height: 18px;
    z-index: 7;
    cursor: pointer;
}

.div {
    position: absolute;
    /* display: flex; */
    top: 0px;
    left: 0px;
    /* width: 100%; */
    font-size: 16px;
    line-height: 15px;
    /* font-weight: 800; */
    color: #222;
    /* z-index: 5; */
    /* width: 390px;
    height: 844px; */
}

.header {
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
} 

.rectangleParent {
    position: absolute;
    width: 390px;
    left: 50%;
    transform: translate(-50%);
}


/* .div {
    width: 100%;
    position: absolute;
    height: 844px;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    color: #666;
    font-family: NanumSquare;
    z-index: 6;
    top: 0px;
    left: 0px;
} */
/* } */