.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 844px;

}

.multiLang {
    position: relative;
    top: 50px;
    left: 150px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 600;
    z-index: 8;
}

.korLang {
    position: absolute;
    top: 0px;
    left: 30px;
    color: #666666;
}

.korLangBold {
    position: absolute;
    top: 0px;
    left: 30px;
    color: #3C3C3C;
}

.enLang {
    position: absolute;
    top: 0px;
    left: 250px;
    color: #666666;
}

.enLangBold {
    position: absolute;
    top: 0px;
    left: 250px;
    color: #3C3C3C;
}

.language {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.div1 {
    position: absolute;
    top: 124px;
    left: 20px;
    line-height: 15px;
    color: #666666;
    font-family: NanumSquare;
    font-weight: 700;
}

.div1Bold {
    position: absolute;
    top: 124px;
    left: 20px;
    line-height: 15px; 
    font-family: NanumSquare;
    font-weight: 800;
    color: #222;
}
.div1En {
    position: absolute;
    top: 124px;
    left: 20px;
    line-height: 15px;
    color: #666666;
    font-family: Roboto;
    font-weight: 500;
}

.div1BoldEn {
    position: absolute;
    top: 124px;
    left: 20px;
    line-height: 15px; 
    font-family: Roboto;
    font-weight: 700;
    color: #222;
}

.b {
    position: absolute;
    top: 189px;
    left: 20px;
    line-height: 15px;
    color: #666;
    font-family: NanumSquare;
    font-weight: 700; 
}

.bBold {
    position: absolute;
    top: 189px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
    font-family: NanumSquare;
    font-weight: 800;
}
.bEn {
    position: absolute;
    top: 189px;
    left: 20px;
    line-height: 15px;
    color: #666;
    font-family: Roboto;
    font-weight: 500;
}

.bBoldEn {
    position: absolute;
    top: 189px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
    font-family: Roboto;
    font-weight: 700;
}

.b1 {
    position: absolute;
    top: 319px;
    left: 20px;
    line-height: 15px;
    color: #666;
    font-family: NanumSquare;
    font-weight: 700;
}

.b1Bold {
    position: absolute;
    top: 319px;
    left: 20px;
    line-height: 15px;
    color: #222;
    font-family: NanumSquare;
    font-weight: 800;
}
.b1En {
    position: absolute;
    top: 319px;
    left: 20px;
    line-height: 15px;
    color: #666;
    font-family: Roboto;
    font-weight: 500;
}

.b1BoldEn {
    position: absolute;
    top: 319px;
    left: 20px;
    line-height: 15px;
    color: #222;
    font-family: Roboto;
    font-weight: 700;
}

.tihTomorrowIn {
    position: absolute;
    top: 254px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
    font-family: Roboto;
    color: #666;
}

.tihTomorrowInBold {
    position: absolute;
    top: 254px;
    left: 20px;
    line-height: 15px;
    font-weight: 700;
    font-family: Roboto;
    color: #222;
}

.contact {
    position: absolute;
    top: 384px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
    font-family: Roboto;
    color: #666;
}

.contactBold {
    position: absolute;
    top: 384px;
    left: 20px;
    line-height: 15px;
    font-weight: 700;
    font-family: Roboto;
    color: #222;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 390px;
    height: 844px;
    /* left: 50%;
    transform: translate(-50%); */
}

.div {
    width: 100%;
    position: absolute;
    height: 844px;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    color: #666;
    font-family: NanumSquare;
    z-index: 6;
    top: 0px;
    left: 0px;
}

/* .div {
    position: absolute;
    top: 100px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    color: #222;

} */

.multiLanguage {
    position: absolute;
    top: 53px;
    left: 188px;
    /* width: 18px;
    height: 18px; */
    width: 109px;
    height: 18px;
    color: #3c3c3c;
    font-family: Roboto;
    z-index: 20;
}

.iconLang {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.korOff {
    position: absolute;
    top: 2px;
    left: 28px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
    cursor: pointer;
}
.korOn {
    position: absolute;
    top: 2px;
    left: 28px;
    line-height: 15px;
    font-weight: 600;
    color: #3c3c3c;
    cursor: pointer;
}
.engOff {
    position: absolute;
    top: 2px;
    left: 70px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
    cursor: pointer;
}
.engOn {
    position: absolute;
    top: 2px;
    left: 70px;
    line-height: 15px;
    font-weight: 600;
    color: #3c3c3c;
    cursor: pointer;
}
/* .korOn:hover,  .korOff:hover, .engOn:hover, .engOff:hover {
    cursor: pointer;
} */

.icon2 {
    position: absolute;
    top: 54px;
    left: 198px;
    width: 18px;
    height: 18px;
}

/* .kor {
    position: absolute;
    top: 56px;
    left: 226px;
    line-height: 15px;
    font-weight: 600;
    color: #3c3c3c;
}
.eng {
    position: absolute;
    top: 56px;
    left: 268px;
    line-height: 15px;
    font-weight: 600;
} */
