.icon2 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 390px;
    /* width: 100%; */

    height: 700px;
    object-fit: cover;
    /* z-index: 1; */

}

.manager {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 10;
}

.ai {
    margin: 0;
}

.aiContainerKo {
    position: absolute;
    /* top: 246px; */
    top: 210px;

    left: 20px;
    font-size: 36px;
    line-height: 88px;
    font-family: NanumSquare;
    color: #fff;
    z-index: 1;
}
.aiContainerEn {
    position: absolute;
    /* top: 246px; */
    top: 210px;

    left: 20px;
    font-size: 36px;
    line-height: 88px;
    font-family: Roboto;
    color: #fff;
    z-index: 1;
}

.swiperMain {
    display: flex;
    /* top: 0px; */
    /* left: 0px; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-items: center;
    /* z-index: 10; */
}

/* .swiperMain {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 390px;
    height: 700px;
    object-fit: cover;
} */