/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .contactHeadOffice {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2679px;
        overflow: hidden;
        text-align: left;
        font-size: 28px;
        color: #666;
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .businessContact {
        position: absolute;
        top: 224px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
        color: #222;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 2679px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 2679px;
    }
    .vdigmInc {
        position: absolute;
        top: 727px;
        left: 370px;
        font-weight: 800;
        color: #222;
    }
    .tel {
        position: absolute;
        top: 859px;
        left: 370px;
    }
    .eMail {
        position: absolute;
        top: 942px;
        left: 370px;
    }
    .adress {
        position: absolute;
        top: 1075px;
        left: 370px;
        font-weight: 800;
        color: #222;
    }
    .rdCenter {
        position: absolute;
        top: 1241px;
        left: 370px;
    }
    .location {
        position: absolute;
        top: 1413px;
        left: 370px;
        font-weight: 800;
        color: #222;
    }
    .head {
        position: absolute;
        top: 1496px;
        left: 373px;
        font-weight: 400;
        /* line-height: 15px; */
        color: #666;
        z-index: 5;
    }
    
    .headBold {
        position: absolute;
        top: 1496px;
        left: 373px;
        font-weight: 600;
        /* line-height: 15px; */
        color: #3c3c3c;
        z-index: 5;
    
    }
    
    .rd {
        position: absolute;
        top: 1496px;
        left: 571px;
        /* line-height: 15px; */
        color: #666;
        font-weight: 400;
        z-index: 5;
    
    }
    
    .rdBold {
        position: absolute;
        top: 1496px;
        left: 571px;
        /* line-height: 15px; */
        color: #3c3c3c;
        font-weight: 600;
        z-index: 5;
    
    }
    .headOffice {
        position: absolute;
        top: 1158px;
        left: 370px;
    }
    .div {
        position: absolute;
        top: 859px;
        left: 517px;
        font-weight: 500;
    }
    .bizvdigmcom {
        position: absolute;
        top: 942px;
        left: 517px;
        font-weight: 500;
    }
    .f1012293 {
        position: absolute;
        top: 1158px;
        left: 618px;
        font-weight: 500;
    }
    .c304SeoulAi {
        margin-block-start: 0;
        margin-block-end: 6px;
    }
    .republicOfKorea {
        margin: 0;
    }
    .c304SeoulAiContainer {
        position: absolute;
        top: 1241px;
        left: 618px;
        font-weight: 500;
    }
    .icon1 {
        position: absolute;
        top: 439px;
        left: 646px;
        width: 629px;
        height: 161px;
        object-fit: cover;
    }
    .map {
        position: absolute;
        top: 1579px;
        left: 370px;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .contactHeadOffice {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2679px;
        overflow: hidden;
        text-align: left;
        font-size: 20px;
        color: #666;
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .businessContact {
        position: absolute;
        top: 224px;
        top: 179.2px;

        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
        color: #222;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 2679px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1280px;
        height: 2679px;
    }
    .vdigmInc {
        position: absolute;
        top: 727px;
        top: 581.6px;

        left: 370px;
        left: 247.9px;

        font-weight: 800;
        color: #222;
    }
    .tel {
        position: absolute;
        top: 859px;
        top: 687.2px;

        left: 247.9px;
    }
    .eMail {
        position: absolute;
        top: 942px;
        top: 753.6px;

        left: 247.9px;
    }
    .adress {
        position: absolute;
        top: 1075px;
        top: 860px;

        left: 247.9px;
        font-weight: 800;
        color: #222;
    }
    .rdCenter {
        position: absolute;
        top: 1241px;
        top: 992.8px;

        left: 247.9px;
    }
    .location {
        position: absolute;
        top: 1413px;
        top: 1130.4px;

        left: 247.9px;
        font-weight: 800;
        color: #222;
    }
    .head {
        position: absolute;
        top: 1496px;
        top: 1196.8px;

        left: 373px;
        left: 249.91px;

        font-weight: 400;
        /* line-height: 15px; */
        color: #666;
        z-index: 5;
    }
    
    .headBold {
        position: absolute;
        top: 1196.8px;
        left: 249.91px;
        font-weight: 600;
        /* line-height: 15px; */
        color: #3c3c3c;
        z-index: 5;
    
    }
    
    .rd {
        position: absolute;
        top: 1196.8px;
        left: 571px;
        left: 382.57px;

        /* line-height: 15px; */
        color: #666;
        font-weight: 400;
        z-index: 5;
    
    }
    
    .rdBold {
        position: absolute;
        top: 1196.8px;
        left: 382.57px;
        /* line-height: 15px; */
        color: #3c3c3c;
        font-weight: 600;
        z-index: 5;
    
    }
    .headOffice {
        position: absolute;
        top: 1158px;
        top: 926.4px;

        left: 247.9px;
    }
    .div {
        position: absolute;
        top: 859px;
        top: 687.2px;

        left: 517px;
        left: 346.39px;

        font-weight: 500;
    }
    .bizvdigmcom {
        position: absolute;
        top: 942px;
        top: 753.6px;

        left: 346.39px;
        font-weight: 500;
    }
    .f1012293 {
        position: absolute;
        top: 1158px;
        top: 926.4px;

        left: 618px;
        left: 399.06px;

        font-weight: 500;
    }
    .c304SeoulAi {
        margin-block-start: 0;
        margin-block-end: 5px;
    }
    .republicOfKorea {
        margin: 0;
    }
    .c304SeoulAiContainer {
        position: absolute;
        top: 1241px;
        top: 992.8px;

        left: 618px;
        left: 414.06px;

        font-weight: 500;
    }
    .icon1 {
        position: absolute;
        top: 439px;
        top: 351.2px;

        left: 646px;
        left: 402.82px;

        width: 629px;
        width: 503.2px;

        height: 161px;
        height: 128.8px;

        object-fit: cover;
    }
    .map {
        position: absolute;
        top: 1579px;
        top: 1263.2px;

        left: 247.9px;
        width: 944px;
        height: 640px;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}


.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}

.groupItem {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}

.vdigmInc2 {
    position: absolute;
    top: 727px;
    left: 400px;
    font-weight: 800;
    color: #222;
    font-family: NanumSquare;
}


.head:hover, .headBold:hover, .rd:hover, .rdBold:hover {
    cursor: pointer;
}
.headOffice1 {
    position: absolute;
    top: 1496px;
    left: 373px;
    font-weight: 600;
    color: #3c3c3c;
}
.rdCenter1 {
    position: absolute;
    top: 1496px;
    left: 571px;
}

.groupInner {
    position: absolute;
    top: 1579px;
    left: 370px;
    background-color: #d9d9d9;
    width: 1180px;
    height: 800px;
}

.aboutUs {
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 500;
}
.solution {
    position: absolute;
    top: 0px;
    left: 274px;
    font-weight: 500;
}
.tih {
    position: absolute;
    top: 0px;
    left: 541px;
    font-weight: 500;
}
.career {
    position: absolute;
    top: 0px;
    left: 770px;
    font-weight: 500;
}
.contact {
    position: absolute;
    top: 0px;
    left: 1024px;
}
.aboutUsParent {
    position: absolute;
    top: 28px;
    left: 416px;
    width: 1088px;
    height: 21px;
    font-size: 18px;
    color: #222;
}
.icon2 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #3c3c3c;
}
.parent {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    font-size: 18px;
}


