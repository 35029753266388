.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1708px;
}

.div {
    position: absolute;
    top: 750px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
}

.divv {
    position: absolute;

}

.divvv {
    position: relative
}

.div1 {
    position: absolute;
    top: 1006px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
}

.b {
    position: absolute;
    top: 1006px;
    left: 326px;
    font-size: 12px;
    line-height: 15px;
}

.b1 {
    position: absolute;
    top: 785px;
    left: 337px;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #666666;
}

.b2 {
    position: absolute;
    top: 820px;
    left: 337px;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #666666;
}

.b3 {
    position: absolute;
    top: 855px;
    left: 337px;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #666666;
}

.b4 {
    position: absolute;
    top: 890px;
    left: 337px;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #666666;
}

.div2 {
    position: absolute;
    top: 1041px;
    left: 0px;
    width: 100%;
    height: 200px;
}

/* .div2 {
    position: absolute;
    top: 1041px;
    left: 85px;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 220px;
    height: 200px;
} */

.r {
    position: absolute;
    top: 1041px;
    left: 325px;
    border-radius: 10px 0px 0px 10px;
    background-color: #d9d9d9;
    width: 65px;
    height: 200px;
}

.l {
    position: absolute;
    top: 1241px;
    left: 65px;
    border-radius: 10px 0px 0px 10px;
    background-color: #d9d9d9;
    width: 65px;
    height: 200px;
    transform: rotate(-180deg);
    transform-origin: 0 0;
}

.icon {
    position: absolute;
    top: 1263px;
    left: 20px;
    max-height: 100%;
    width: 350px;
}

.icon1 {
    position: absolute;
    top: 1263px;
    left: 56px;
    max-height: 100%;
    width: 60px;
}

.icon2 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 390px;
    /* width: 100%; */

    height: 700px;
    object-fit: cover;
    /* z-index: 1; */

}

.ai {
    margin: 0;
}

.aiContainer {
    position: absolute;
    top: 246px;
    left: 20px;
    font-size: 36px;
    line-height: 88px;
    font-family: Inter;
    color: #fff;
}

.icon3 {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon4 {
    position: absolute;
    top: 674px;
    left: 182px;
    width: 26px;
    height: 6px;
}

.icon5 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.div3 {
    position: absolute;
    top: 1419px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
}

.b5 {
    position: absolute;
    top: 1454px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.rdCenter {
    position: absolute;
    top: 1479px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.c304 {
    position: absolute;
    top: 1499px;
    left: 103px;
    line-height: 15px;
    color: #999;
}

.tel82322771700 {
    position: absolute;
    top: 1524px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.mailBizvdigmcom {
    position: absolute;
    top: 1549px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.groupItem {
    position: absolute;
    top: 1367px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}

.icon6 {
    position: absolute;
    top: 1614px;
    left: 326px;
    width: 44px;
    height: 44px;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div4 {
    position: absolute;
    top: 1584px;
    left: 20px;
    width: 24px;
    height: 24px;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div5 {
    position: absolute;
    top: 1584px;
    left: 54px;
    width: 24px;
    height: 24px;
}

.it {
    position: absolute;
    top: 785px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}

.aiAi {
    position: absolute;
    top: 855px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}

.ai1 {
    position: absolute;
    top: 890px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}

.b6 {
    position: absolute;
    top: 820px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}

.icon7 {
    position: absolute;
    top: 103px;
    left: 385px;
    width: 0px;
    height: 1554px;
    object-fit: contain;
    opacity: 0.6;
}

.icon8 {
    position: absolute;
    top: 861px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: -1px;
    left: 0px;
    width: 390px;
    height: 1709px;
    left: 50%;
    transform: translate(-50%);
}

.home {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1358px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #666;
    font-family: NanumSquare;
    z-index: 1;
}

.aaaa {
    position: static;
    /* top: 0;
    left: 0; */
}

.bbb {
    position: absolute;
    top: 0;
    left: 0;

}

#app {
    height: 100%;
}

/* html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;

    scrollbar-highlight-color: #D9D9D9;
    scrollbar-shadow-color: #D9D9D9;
    scrollbar-face-color: #D9D9D9;
} */

body::-webkit-scrollbar {
    width: 5px;  /* 스크롤바의 너비 */
    
}

body::-webkit-scrollbar-thumb {
    height: 20%; /* 스크롤바의 길이 */
    background: #c0c0c0;
    
    border-radius: 10px;
    border-color: #c0c0c0;
}

body::-webkit-scrollbar-track {
    background: #DEDEDE;
}

/* body::-webkit-scrollbar {
    display: none;
  }
  
body:hover::-webkit-scrollbar {
    display: auto;
  } */

.mySwiper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.postImage {
    width: 300px;
    height: 300px;
    position: relative;
    overflow: hidden;
}

.imgDiv {
    display: flex;
    width: 100%;
    height: 100%;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}