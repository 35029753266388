/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 3006px;
        overflow: hidden;
        text-align: center;
        font-size: 18px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .div3 {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
        text-align: left;
    }
    .p {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 3006px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 3006px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 456px;
        width: 100%;
        z-index: 2;
        font-size: 18px;
    }
    .b4 {
        position: absolute;
        top: 0px;
        left: 0px;
        color: #999;
        text-align: left;
    }
    .div1 {
        position: absolute;
        top: 0px;
        left: 150px;
        font-weight: 800;
        text-align: left;
    }
    .b {
        position: absolute;
        top: 0px;
        left: 316px;
        color: #999;
        text-align: left;
    }
    .ellipseDiv {
        position: absolute;
        top: -8px;
        left: 216px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .groupChild1 {
        position: absolute;
        top: 577px;
        left: 370px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild2 {
        position: absolute;
        top: 1147px;
        left: 370px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild3 {
        position: absolute;
        top: 1717px;
        left: 370px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild4 {
        position: absolute;
        top: 2287px;
        left: 370px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild5 {
        position: absolute;
        top: 577px;
        left: 1224px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild6 {
        position: absolute;
        top: 1147px;
        left: 1224px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild7 {
        position: absolute;
        top: 1717px;
        left: 1224px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild8 {
        position: absolute;
        top: 577px;
        left: 797px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild9 {
        position: absolute;
        top: 1147px;
        left: 797px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild10 {
        position: absolute;
        top: 1717px;
        left: 797px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .p2 {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    .div7 {
        position: absolute;
        top: 902px;
        left: 478px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 800;
    }
    .div8 {
        position: absolute;
        top: 1472px;
        left: 465px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 800;
    }
    .div9 {
        position: absolute;
        top: 2042px;
        left: 449px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 800;
    }
    .div10 {
        position: absolute;
        top: 2633px;
        left: 436px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 800;
    }
    .div11 {
        position: absolute;
        top: 902px;
        left: 880px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 800;
    }
    .div12 {
        position: absolute;
        top: 1493px;
        left: 863px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 800;
    }
    .div13 {
        position: absolute;
        top: 2042px;
        left: 867px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 800;
    }
    .div14 {
        position: absolute;
        top: 902px;
        left: 1319px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 800;
    }
    .div15 {
        position: absolute;
        top: 1472px;
        left: 1333px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 800;
    }
    .div16 {
        position: absolute;
        top: 2042px;
        left: 1294px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 800;
    }
    .div17 {
        position: absolute;
        top: 986px;
        left: 501px;
        line-height: 20px;
        color: #666;
    }
    .div18 {
        position: absolute;
        top: 1556px;
        left: 482px;
        line-height: 20px;
        color: #666;
    }
    .div19 {
        position: absolute;
        top: 2126px;
        left: 500px;
        line-height: 20px;
        color: #666;
    }
    .div20 {
        position: absolute;
        top: 2696px;
        left: 500px;
        line-height: 20px;
        color: #666;
    }
    .div21 {
        position: absolute;
        top: 986px;
        left: 927px;
        line-height: 20px;
        color: #666;
    }
    .div22 {
        position: absolute;
        top: 1556px;
        left: 904px;
        line-height: 20px;
        color: #666;
    }
    .div23 {
        position: absolute;
        top: 2126px;
        left: 917px;
        line-height: 20px;
        color: #666;
    }
    .div24 {
        position: absolute;
        top: 986px;
        left: 1311px;
        line-height: 20px;
        color: #666;
    }
    .div25 {
        position: absolute;
        top: 1556px;
        left: 1325px;
        line-height: 20px;
        color: #666;
    }
    .div26 {
        position: absolute;
        top: 2126px;
        left: 1325px;
        line-height: 20px;
        color: #666;
    }
    .maskGroupIcon {
        position: absolute;
        top: 617px;
        left: 410px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon1 {
        position: absolute;
        top: 1187px;
        left: 1264px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon2 {
        position: absolute;
        top: 1187px;
        left: 837px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon3 {
        position: absolute;
        top: 617px;
        left: 837px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon4 {
        position: absolute;
        top: 617px;
        left: 1264px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon5 {
        position: absolute;
        top: 1187px;
        left: 410px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon6 {
        position: absolute;
        top: 1757px;
        left: 410px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon7 {
        position: absolute;
        top: 1757px;
        left: 837px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon8 {
        position: absolute;
        top: 2327px;
        left: 410px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon9 {
        position: absolute;
        top: 1757px;
        left: 1264px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2405px;
        overflow: hidden;
        text-align: center;
        font-size: 18px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .div3 {
        position: absolute;
        top: 144px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
        text-align: left;
    }
    .p {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 2405px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1280px;
        height: 2405px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 364.8px;
        width: 100%;
        z-index: 2;
        font-size: 15px;
    }
    .b4 {
        position: absolute;
        top: 0px;
        left: 0px;
        color: #999;
        text-align: left;
    }
    .div1 {
        position: absolute;
        top: 0px;
        left: 124.8px;
        font-weight: 800;
        text-align: left;
    }
    .b {
        position: absolute;
        top: 0px;
        left: 258.4px;
        color: #999;
        text-align: left;
    }
    .ellipseDiv {
        position: absolute;
        top: -6px;
        left: 180px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .groupChild1 {
        position: absolute;
        top: 461.6px;
        left: 247.9px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild2 {
        position: absolute;
        top: 917.6px;
        left: 247.9px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild3 {
        position: absolute;
        top: 1373.6px;
        left: 247.9px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild4 {
        position: absolute;
        top: 1829.6px;
        left: 247.9px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild5 {
        position: absolute;
        top: 461.6px;
        left: 820.08px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild6 {
        position: absolute;
        top: 917.6px;
        left: 820.08px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild7 {
        position: absolute;
        top: 1373.6px;
        left: 820.08px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild8 {
        position: absolute;
        top: 461.6px;
        left: 533.99px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild9 {
        position: absolute;
        top: 917.6px;
        left: 533.99px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild10 {
        position: absolute;
        top: 1373.6px;
        left: 533.99px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .div7 {
        position: absolute;
        /* top: 902px; */
        top: 661.6px;

        /* left: 478px; */
        left: 315.26px;

        font-size: 23px;
        line-height: 10px;
        font-weight: 800;
    }
    .div8 {
        position: absolute;
        top: 1472px;
        top: 1117.6px;

        left: 465px;
        left: 306.55px;

        font-size: 23px;
        line-height: 10px;
        font-weight: 800;
    }
    .div9 {
        position: absolute;
        top: 2042px;
        top: 1573.6px;

        left: 449px;
        left: 290.83px;

        font-size: 23px;
        line-height: 10px;
        font-weight: 800;
    }
    .div10 {
        position: absolute;
        top: 2633px;
        top: 2061.4px;

        left: 436px;
        left: 282.12px;

        font-size: 23px;
        line-height: 10px;
        font-weight: 800;
    }
    .div11 {
        position: absolute;
        top: 902px;
        top: 661.6px;

        left: 880px;
        left: 579.6px;

        font-size: 23px;
        line-height: 10px;
        font-weight: 800;
    }
    .div12 {
        position: absolute;
        top: 1493px;
        top: 1154.4px;

        left: 863px;
        left: 563.21px;

        font-size: 23px;
        line-height: 10px;
        font-weight: 800;
    }
    .div13 {
        position: absolute;
        top: 2042px;
        top: 1573.6px;

        left: 867px;
        left: 565.89px;

        font-size: 23px;
        line-height: 10px;
        font-weight: 800;
    }
    .div14 {
        position: absolute;
        top: 902px;
        top: 661.6px;

        left: 1319px;
        left: 873.73px;

        font-size: 23px;
        line-height: 10px;
        font-weight: 800;
    }
    .div15 {
        position: absolute;
        top: 1472px;
        top: 1117.6px;

        left: 1333px;
        left: 883.11px;

        font-size: 23px;
        line-height: 10px;
        font-weight: 800;
    }
    .div16 {
        position: absolute;
        top: 2042px;
        top: 1573.6px;

        left: 1294px;
        left: 851.98px;

        font-size: 23px;
        line-height: 10px;
        font-weight: 800;
    }
    .div17 {
        position: absolute;
        top: 986px;
        top: 743.8px;

        left: 501px;
        left: 330.67px;

        line-height: 14px;
        color: #666;
        font-size: 15px;
    }
    .div18 {
        position: absolute;
        top: 1556px;
        top: 1199.8px;

        left: 482px;
        left: 312.94px;

        line-height: 14px;
        color: #666;
        font-size: 15px;
    }
    .div19 {
        position: absolute;
        top: 2126px;
        top: 1655.8px;

        left: 500px;
        left: 330px;

        line-height: 14px;
        color: #666;
        font-size: 15px;
    }
    .div20 {
        position: absolute;
        top: 2696px;
        top: 2111.8px;

        left: 500px;
        left: 330px;

        line-height: 14px;
        color: #666;
        font-size: 15px;
    }
    .div21 {
        position: absolute;
        top: 986px;
        top: 743.8px;

        left: 927px;
        left: 616.09px;

        line-height: 14px;
        color: #666;
        font-size: 15px;
    }
    .div22 {
        position: absolute;
        top: 1556px;
        top: 1199.8px;

        left: 904px;
        left: 593.68px;

        line-height: 14px;
        color: #666;
        font-size: 15px;
    }
    .div23 {
        position: absolute;
        top: 2126px;
        top: 1655.8px;

        left: 917px;
        left: 604.39px;

        line-height: 14px;
        color: #666;
        font-size: 15px;
    }
    .div24 {
        position: absolute;
        top: 986px;
        top: 743.8px;

        left: 1311px;
        left: 863.37px;

        line-height: 14px;
        color: #666;
        font-size: 15px;
    }
    .div25 {
        position: absolute;
        top: 1556px;
        top: 1199.8px;

        left: 1325px;
        left: 875.75px;

        line-height: 14px;
        color: #666;
        font-size: 15px;
    }
    .div26 {
        position: absolute;
        top: 2126px;
        top: 1655.8px;

        left: 1325px;
        left: 872.75px;

        line-height: 14px;
        color: #666;
        font-size: 15px;
    }
    .maskGroupIcon {
        position: absolute;
        /* top: 617px; */
        top: 493.6px;

        /* left: 410px; */
        left: 274.7px;

        /* width: 246px; */
        width: 164.82px;

        /* height: 246px; */
        height: 164.82px;

        object-fit: cover;
    }
    .maskGroupIcon1 {
        position: absolute;
        /* top: 1187px; */
        top: 949.6px;

        /* left: 1264px; */
        left: 846.88px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon2 {
        position: absolute;
        top: 949.6px;
        /* left: 837px; */
        left: 560.79px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon3 {
        position: absolute;
        /* top: 617px; */
        top: 493.6px;

        left: 560.79px;
        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon4 {
        position: absolute;
        top: 493.6px;
        /* left: 1264px; */
        left: 846.88px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon5 {
        position: absolute;
        /* top: 1187px; */
        top: 949.6px;

        /* left: 410px; */
        left: 274.7px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon6 {
        position: absolute;
        top: 1405.6px;
        left: 274.7px;
        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon7 {
        position: absolute;
        top: 1405.6px;
        /* left: 837px; */
        left: 560.79px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon8 {
        position: absolute;
        /* top: 2327px; */
        top: 1861.6px;

        /* left: 410px; */
        left: 274.7px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon9 {
        position: absolute;
        /* top: 1757px; */
        top: 1405.6px;

        /* left: 1264px; */
        left: 846.88px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}

.b1 {
    position: absolute;
    top: 0px;
    left: 0px;
}
.b2 {
    position: absolute;
    top: 0px;
    left: 266px;
}
.tih {
    position: absolute;
    top: 0px;
    left: 516px;
    font-weight: 500;
    font-family: Roboto;
}
.div2 {
    position: absolute;
    top: 0px;
    left: 745px;
    font-weight: 800;
}
.contact {
    position: absolute;
    top: 0px;
    left: 978px;
    font-weight: 500;
    font-family: Roboto;
}
.parent {
    position: absolute;
    top: 28px;
    left: 439px;
    width: 1042px;
    height: 21px;
    text-align: left;
}
.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}

.groupItem {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}
.groupInner {
    position: absolute;
    top: 0px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.rectangleDiv {
    position: absolute;
    top: 94px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.div4 {
    position: absolute;
    top: 204px;
    left: 130px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.b3 {
    position: absolute;
    top: 277px;
    left: 130px;
}
.rdCenter {
    position: absolute;
    top: 315px;
    left: 130px;
}
.tel82322771700 {
    position: absolute;
    top: 353px;
    left: 130px;
}
.mailBizvdigmcom {
    position: absolute;
    top: 391px;
    left: 130px;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div5 {
    position: absolute;
    top: 204px;
    left: 1690px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div6 {
    position: absolute;
    top: 204px;
    left: 1750px;
    width: 40px;
    height: 40px;
}
.groupParent {
    position: absolute;
    top: 2912px;
    left: 0px;
    width: 1920px;
    height: 409px;
    text-align: left;
    font-size: 16px;
    color: #999;
}

/* .groupChild1 {
    position: absolute;
    top: 576px;
    left: 370px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 326px;
    height: 470px;
}
.groupChild2 {
    position: absolute;
    top: 1146px;
    left: 370px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 326px;
    height: 470px;
}
.groupChild3 {
    position: absolute;
    top: 1716px;
    left: 370px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 326px;
    height: 470px;
}
.groupChild4 {
    position: absolute;
    top: 2286px;
    left: 370px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 326px;
    height: 470px;
}
.groupChild5 {
    position: absolute;
    top: 576px;
    left: 1224px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 326px;
    height: 470px;
}
.groupChild6 {
    position: absolute;
    top: 1146px;
    left: 1224px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 326px;
    height: 470px;
}
.groupChild7 {
    position: absolute;
    top: 1716px;
    left: 1224px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 326px;
    height: 470px;
}
.groupChild8 {
    position: absolute;
    top: 576px;
    left: 797px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 326px;
    height: 470px;
}
.groupChild9 {
    position: absolute;
    top: 1146px;
    left: 797px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 326px;
    height: 470px;
}
.groupChild10 {
    position: absolute;
    top: 1716px;
    left: 797px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 326px;
    height: 470px;
} */

/* .maskGroupIcon {
    position: absolute;
    top: 616px;
    left: 410px;
    width: 246px;
    height: 246px;
    object-fit: cover;
}
.maskGroupIcon1 {
    position: absolute;
    top: 1186px;
    left: 1264px;
    width: 246px;
    height: 246px;
    object-fit: cover;
}
.maskGroupIcon2 {
    position: absolute;
    top: 1186px;
    left: 837px;
    width: 246px;
    height: 246px;
    object-fit: cover;
}
.maskGroupIcon3 {
    position: absolute;
    top: 616px;
    left: 837px;
    width: 246px;
    height: 246px;
    object-fit: cover;
}
.maskGroupIcon4 {
    position: absolute;
    top: 616px;
    left: 1264px;
    width: 246px;
    height: 246px;
    object-fit: cover;
}
.maskGroupIcon5 {
    position: absolute;
    top: 1186px;
    left: 410px;
    width: 246px;
    height: 246px;
    object-fit: cover;
}
.maskGroupIcon6 {
    position: absolute;
    top: 1756px;
    left: 410px;
    width: 246px;
    height: 246px;
    object-fit: cover;
}
.maskGroupIcon7 {
    position: absolute;
    top: 1756px;
    left: 837px;
    width: 246px;
    height: 246px;
    object-fit: cover;
}
.maskGroupIcon8 {
    position: absolute;
    top: 2326px;
    left: 410px;
    width: 246px;
    height: 246px;
    object-fit: cover;
}
.maskGroupIcon9 {
    position: absolute;
    top: 1756px;
    left: 1264px;
    width: 246px;
    height: 246px;
    object-fit: cover;
} */
.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}
.group {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    text-align: left;
    color: #3c3c3c;
    font-family: Roboto;
}

.b4:hover, .div1:hover, .b:hover {
    cursor: pointer;
}