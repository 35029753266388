.responsiveAiAvatarImage {
    position: absolute;
    top: 661px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1489px;
    /* left: 50%;
    transform: translate(-50%); */
}
.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1489px;
}
.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.aiAvatar1 {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
    cursor: pointer;
}
.dVirtualStage {
    position: absolute;
    top: 220px;
    left: 181px;
    line-height: 15px;
    color: #666;
    cursor: pointer;
}
.deepReal {
    position: absolute;
    top: 220px;
    left: 98px;
    line-height: 15px;
    color: #666;
    cursor: pointer;
}
.vdigmsTechnologySpearheadin {
    margin-block-start: 0;
    margin-block-end: 10px;
}
.dLiveStreaming {
    margin: 0;
}
.vdigmsTechnologySpearheadinContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    color: #000;
}
.vdigmInc {
    position: absolute;
    top: 1526px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}
.headOffice {
    font-weight: 500;
    font-family: Roboto;
}
.headOffice10f1012293Bu {
    margin-block-start: 0;
    margin-block-end: 2px;
}
.headOffice10fContainer {
    position: absolute;
    top: 1561px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.rdCenterC304Container {
    position: absolute;
    top: 1603px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.tel {
    font-weight: 500;
}
.tel82322771700 {
    position: absolute;
    top: 1655px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.mailBizvdigmcom {
    position: absolute;
    top: 1680px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.icon2 {
    position: absolute;
    top: 1474px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.groupItem {
    position: absolute;
    top: 1745px;
    left: 326px;
    box-shadow: 0px 0px 8.27px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    background-color: #fff;
    width: 44px;
    height: 44px;
}
.groupInner {
    position: absolute;
    top: 1757px;
    left: 348px;
    width: 0px;
    height: 20px;
    object-fit: contain;
}
.ellipseDiv {
    position: absolute;
    top: 1715px;
    left: 20px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram328841319209Icon {
    position: absolute;
    top: 1722px;
    left: 25px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.groupChild1 {
    position: absolute;
    top: 1715px;
    left: 54px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram3288413192010Icon {
    position: absolute;
    top: 1720.5px;
    left: 60.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.generativeAiAvatar {
    position: absolute;
    top: 515px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    color: #222222;
}
.aiAvatarNpc {
    position: absolute;
    top: 1280px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    color: #222222;
}
.responsiveAiAvatar {
    position: absolute;
    top: 887px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    color: #222222;
}
.createYour3d {
    margin-block-start: 0;
    margin-block-end: 6px;
}
.createYour3dContainer {
    position: absolute;
    top: 550px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}
.vdigmsAvatarNpcContainer {
    position: absolute;
    top: 1315px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}
.experienceTheFutureContainer {
    position: absolute;
    top: 922px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}
.generativeAiAvatarImage {
    position: absolute;
    top: 285px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}
.responsiveAiAvatarImage1 {
    position: absolute;
    top: 657px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}
.aiAvatarNpcImage {
    position: absolute;
    top: 1050px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}
.icon3 {
    position: absolute;
    top: 130px;
    left: 385px;
    width: 0px;
    height: 1657px;
    object-fit: contain;
    opacity: 0.6;
}
.icon4 {
    position: absolute;
    top: 855px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.responsiveAiAvatarImageParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1489px;
    left: 50%;
    transform: translate(-50%);
}
.aiAvatar {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1489px;
    overflow: hidden;
    /* overflow-x: hidden; */
    text-align: left;
    font-size: 14px;
    color: #222;
    font-family: Roboto;
}

