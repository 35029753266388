.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 734px;
}
.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.aboutUs {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    font-weight: 400;
    color: #666;
}
.organizationChart {
    position: absolute;
    top: 220px;
    left: 161px;
    line-height: 15px;
    font-weight: 400;
    color: #666;
}
.history {
    position: absolute;
    top: 220px;
    left: 96px;
    line-height: 15px;
    font-weight: 400;
    color: #666;
}
.aNewWorld {
    position: absolute;
    top: 285px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    color: #222;
}
.vision1 {
    position: absolute;
    top: 220px;
    left: 300px;
    line-height: 15px;
    color: #222;
    font-weight: 700;
}
.shapingTheFuture {
    margin-block-start: 0;
    margin-block-end: 10px;
}
.experiencesWithGenerative {
    margin: 0;
}
.shapingTheFutureContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    color: #000;
    font-weight: 700;
}
.vdigmInc {
    position: absolute;
    top: 771px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
}
.headOffice {
    font-weight: 500;
    font-family: Roboto;
}
.headOffice10f1012293Bu {
    margin-block-start: 0;
    margin-block-end: 2px;
}
.headOffice10fContainer {
    position: absolute;
    top: 806px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.rdCenterC304Container {
    position: absolute;
    top: 848px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.tel {
    font-weight: 500;
}
.tel82322771700 {
    position: absolute;
    top: 900px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.mailBizvdigmcom {
    position: absolute;
    top: 925px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.icon2 {
    position: absolute;
    top: 719px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.groupItem {
    position: absolute;
    top: 990px;
    left: 326px;
    box-shadow: 0px 0px 8.27px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    background-color: #fff;
    width: 44px;
    height: 44px;
}
.groupInner {
    position: absolute;
    top: 1002px;
    left: 348px;
    width: 0px;
    height: 20px;
    object-fit: contain;
}

/* .inner {
    position: absolute;
    top: 96px;
    left: 96px;
    border-radius: 50%;
    background-color: #8200ff;
    width: 96px;
    height: 96px;
    transform: rotate(-180deg);
    transform-origin: 0 0;
} */

.ellipseDiv {
    position: absolute;
    top: 960px;
    left: 20px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram328841319207Icon {
    position: absolute;
    top: 967px;
    left: 25px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.groupChild1 {
    position: absolute;
    top: 960px;
    left: 54px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram328841319208Icon {
    position: absolute;
    top: 965.5px;
    left: 60.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.child {
    position: absolute;
    top: 98px;
    left: 2px;
    width: 35.4px;
    height: 35.4px;
    object-fit: contain;
}
.item {
    position: absolute;
    top: 98px;
    left: 141px;
    width: 35.4px;
    height: 35.4px;
    object-fit: contain;
}
.inner {
    position: absolute;
    top: 119px;
    left: 68px;
    width: 50px;
    height: 50px;
    object-fit: contain;
}
.child1 {
    position: absolute;
    top: 0px;
    left: 159px;
    border-radius: 50%;
    background: linear-gradient(128.82deg, #8200ff 27.59%, #ff038d);
    width: 133px;
    height: 133px;
    transform:  rotate(90deg);
    transform-origin: 0 0;
}
.child2 {
    position: absolute;
    top: 14px;
    left: 145px;
    border-radius: 50%;
    background-color: #fff;
    width: 105px;
    height: 105px;
    transform:  rotate(90deg);
    transform-origin: 0 0;
}
.vision2 {
    position: absolute;
    top: 59px;
    left: 66px;
    line-height: 15px;
    font-weight: 800;
}
.div {
    position: absolute;
    top: 320px;
    left: 102.5px;
    width: 176.4px;
    height: 169px;
    text-align: center;
    font-size: 18px;
    color: #222;
}
.groupIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 108px;
    height: 108px;
}
.groupChild2 {
    position: absolute;
    top: 0px;
    left: 121px;
    width: 108px;
    height: 108px;
}
.groupChild3 {
    position: absolute;
    top: 0px;
    left: 242px;
    width: 108px;
    height: 108px;
}
.groupIcon1 {
    position: absolute;
    top: -2px;
    left: 0px;
    width: 108px;
    height: 54px;
}
.groupChild21 {
    position: absolute;
    top: 50%;
    left: 121px;
    width: 108px;
    height: 54px;
}
.groupChild31 {
    position: absolute;
    top: -2px;
    left: 242px;
    width: 108px;
    height: 54px;
}
.newTechnologyDevelopmentContainer {
    position: absolute;
    top: 28px;
    left: 18px;
    line-height: 15px;
    font-weight: 500;
}
.sustainableServices {
    position: absolute;
    top: 39px;
    left: 143px;
    line-height: 15px;
    font-weight: 500;
}
.securingGlobalCompetitiveContainer {
    position: absolute;
    top: 24px;
    left: 264px;
    line-height: 15px;
    font-weight: 500;
} 
.groupParent {
    position: absolute;
    top: 509px;
    left: 20px;
    width: 350px;
    height: 108px;
    text-align: center;
    font-size: 12px;
    color: #000;
}
.icon3 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 930px;
    object-fit: contain;
    opacity: 0.6;
}
.icon4 {
    position: absolute;
    top: 680px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 734px;
    left: 50%;
    transform: translate(-50%);
}
.vision {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 734px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #666;
    font-family: Roboto;
}

/* .purpleEllipse {
    position: absolute;
    top: 54px;
    left: 108px;
    border-radius: 0 0 54px 54px;
    background-color: #8200ff;
    width: 108px;
    height: 54px;
    transform: rotate(-180deg);
    transform-origin: 0 0;
}

.pinkEllipse {
    position: absolute;
    top: 54px;
    left: 120px;
    border-radius: 0 0 54px 54px;
    background-color: #ff038d;
    width: 108px;
    height: 54px;
} */