/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .history {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1876px;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #666;
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .shapingTheFuture {
        margin: 0;
    }
    .shapingTheFutureContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
        color: #222;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 1876px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1876px;
        height: 2291px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 456px;
        width: 100%;
        z-index: 2;
    }
    .aboutUs {
        position: absolute;
        top: 0%;
        left: 0%;
        font-weight: 600;
        color: #999;
    }
    .history1 {
        position: absolute;
        top: 0%;
        left: 175px;
        color: #222;
        font-weight: 700;
        color: #222222;
    }
    .organizationChart {
        position: absolute;
        top: 0%;
        left: 333px;
        font-weight: 600;
        color: #999;
    }
    .vision {
        position: absolute;
        top: 0%;
        left: 585px;
        font-weight: 600;
        color: #999;
    }
    .groupItem {
        position: absolute;
        top: 78px;
        left: 0px;
        background-color: #d9d9d9;
        width: 1920px;
        height: 2px;
    }
    .ellipseDiv {
        position: absolute;
        top: -4px;
        left: 235px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .div2 {
        position: absolute;
        top: 627px;
        left: 707px;
        font-size: 28px;
        font-weight: 800;
        color: #222;
    }
    .div3 {
        position: absolute;
        top: 795px;
        left: 707px;
        font-size: 28px;
        font-weight: 800;
        color: #222;
    }
    .div4 {
        position: absolute;
        top: 964px;
        left: 707px;
        font-size: 28px;
        font-weight: 800;
        color: #222;
    }
    .div5 {
        position: absolute;
        top: 1372px;
        left: 707px;
        font-size: 28px;
        font-weight: 800;
        color: #222;
    }
    .div6 {
        position: absolute;
        top: 1176px;
        left: 707px;
        font-size: 28px;
        font-weight: 800;
        color: #222;
    }
    .tihVirtualStreamers {
        position: absolute;
        top: 681px;
        left: 750px;
    }
    .nipaAiVoucher {
        position: absolute;
        top: 724px;
        left: 750px;
    }
    .establishmentOfA {
        position: absolute;
        top: 849px;
        left: 750px;
    }
    .selectionForThe {
        position: absolute;
        top: 1018px;
        left: 750px;
    }
    .mobileGameWorld {
        position: absolute;
        top: 1426px;
        left: 750px;
    }
    .selectionForSba {
        margin-block-start: 0;
        margin-block-end: 6px;
    }
    .selectionForSbaContainer {
        position: absolute;
        top: 1231px;
        left: 750px;
    }
    .nipaAiVoucher1 {
        position: absolute;
        top: 891px;
        left: 750px;
    }
    .venturesquareIncAnd {
        position: absolute;
        top: 1060px;
        left: 750px;
    }
    .lgCnsDevelops {
        position: absolute;
        top: 1469px;
        left: 750px;
    }
    .selectionOfData {
        position: absolute;
        top: 1301px;
        left: 750px;
    }
    .nipaAiVoucher2 {
        position: absolute;
        top: 1103px;
        left: 750px;
    }
    .completionOfAi {
        position: absolute;
        top: 1512px;
        left: 750px;
    }
    .establishACompany {
        position: absolute;
        top: 1555px;
        left: 750px;
    }
    .div7 {
        position: absolute;
        top: 681px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div8 {
        position: absolute;
        top: 724px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div9 {
        position: absolute;
        top: 848px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div10 {
        position: absolute;
        top: 1017px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div11 {
        position: absolute;
        top: 1425px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div12 {
        position: absolute;
        top: 1229px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div13 {
        position: absolute;
        top: 891px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div14 {
        position: absolute;
        top: 1060px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div15 {
        position: absolute;
        top: 1468px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div16 {
        position: absolute;
        top: 1300px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div17 {
        position: absolute;
        top: 1103px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div18 {
        position: absolute;
        top: 1511px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .div19 {
        position: absolute;
        top: 1554px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
    }
    .lineIcon {
        position: absolute;
        top: 579px;
        left: 585.3px;
        width: 4px;
        height: 1045px;
        object-fit: contain;
    }
    .groupChild1 {
        position: absolute;
        top: 643px;
        left: 587px;
        width: 100px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild2 {
        position: absolute;
        top: 811px;
        left: 587px;
        width: 100px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild3 {
        position: absolute;
        top: 980px;
        left: 587px;
        width: 100px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild4 {
        position: absolute;
        top: 1192px;
        left: 587px;
        width: 100px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild5 {
        position: absolute;
        top: 1388px;
        left: 587px;
        width: 100px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild6 {
        position: absolute;
        top: 637px;
        left: 581px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild7 {
        position: absolute;
        top: 805px;
        left: 581px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild8 {
        position: absolute;
        top: 974px;
        left: 581px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild9 {
        position: absolute;
        top: 1186px;
        left: 581px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild10 {
        position: absolute;
        top: 1382px;
        left: 581px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
    
}
@media (max-width: 1450px) {
    .shapingTheFutureContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
        /* letter-spacing: normal; */
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .history {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1501px;
        overflow: hidden;
        text-align: left;
        font-size: 13px;
        color: #666;
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .shapingTheFuture {
        margin: 0;
    }
    .shapingTheFutureContainer {
        position: absolute;
        top: 144px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
        color: #222;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 1501px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1876px;
        height: 2291px;
    }
    .category {
        position: relative;
        left: 19.27%;
        top: 364.8px;
        width: 100%;
        font-family: 15px;
        z-index: 2;
    }
    .aboutUs {
        position: absolute;
        top: 0%;
        left: 0%;
        font-weight: 600;
        color: #999;
    }
    .history1 {
        position: absolute;
        top: 0%;
        left: 140px;
        color: #222;
        font-weight: 700;
        color: #222222;
    }
    .organizationChart {
        position: absolute;
        top: 0%;
        left: 266.4px;
        font-weight: 600;
        color: #999;
    }
    .vision {
        position: absolute;
        top: 0%;
        left: 468px;
        font-weight: 600;
        color: #999;
    }
    .groupItem {
        position: absolute;
        top: 78px;
        left: 0px;
        background-color: #d9d9d9;
        width: 1920px;
        height: 2px;
    }
    .ellipseDiv {
        position: absolute;
        top: -6px;
        left: 183px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .div2 {
        position: absolute;
        /* top: 627px; */
        top: 501.6px;

        /* left: 707px; */
        left: 473.69px;

        font-size: 23px;
        font-weight: 800;
        color: #222;
    }
    .div3 {
        position: absolute;
        /* top: 795px; */
        top: 636px;

        left: 473.69px;
        font-size: 23px;
        font-weight: 800;
        color: #222;
    }
    .div4 {
        position: absolute;
        /* top: 964px; */
        top: 771.2px;

        left: 473.69px;
        font-size: 23px;
        font-weight: 800;
        color: #222;
    }
    .div5 {
        position: absolute;
        /* top: 1372px; */
        top: 1097.6px;

        left: 473.69px;
        font-size: 23px;
        font-weight: 800;
        color: #222;
    }
    .div6 {
        position: absolute;
        top: 940.8px;
        left: 473.69px;
        font-size: 23px;
        font-weight: 800;
        color: #222;
    }
    .tihVirtualStreamers {
        position: absolute;
        /* top: 681px; */
        top: 546.4px;

        /* left: 750px; */
        left: 502.5px;

    }
    .nipaAiVoucher {
        position: absolute;
        top: 580px;
        left: 502.5px;
    }
    .establishmentOfA {
        position: absolute;
        /* top: 849px; */
        top: 679.2px;

        left: 502.5px;
    }
    .selectionForThe {
        position: absolute;
        /* top: 1018px; */
        top: 815.4px;

        left: 502.5px;
    }
    .mobileGameWorld {
        position: absolute;
        /* top: 1426px; */
        top: 1141.8px;

        left: 502.5px;
    }
    .selectionForSba {
        margin-block-start: 0;
        margin-block-end: 6px;
    }
    .selectionForSbaContainer {
        position: absolute;
        /* top: 1231px; */
        top: 984.8px;

        left: 502.5px;
    }
    .nipaAiVoucher1 {
        position: absolute;
        /* top: 891px; */
        top: 712.8px;

        left: 502.5px;
    }
    .venturesquareIncAnd {
        position: absolute;
        /* top: 1060px; */
        top: 850px;

        left: 502.5px;
    }
    .lgCnsDevelops {
        position: absolute;
        /* top: 1469px; */
        top: 1176.2px;

        left: 502.5px;
    }
    .selectionOfData {
        position: absolute;
        top: 1040.8px;
        left: 502.5px;
    }
    .nipaAiVoucher2 {
        position: absolute;
        /* top: 1103px; */
        top: 884.4px;

        left: 502.5px;
    }
    .completionOfAi {
        position: absolute;
        top: 1210.6px;
        left: 502.5px;
    }
    .establishACompany {
        position: absolute;
        /* top: 1555px; */
        top: 1245px;

        left: 502.5px;
    }
    .div7 {
        position: absolute;
        top: 544.8px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div8 {
        position: absolute;
        top: 579.2px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div9 {
        position: absolute;
        top: 678.4px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div10 {
        position: absolute;
        top: 813.6px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div11 {
        position: absolute;
        top: 1140px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div12 {
        position: absolute;
        top: 983.2px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div13 {
        position: absolute;
        top: 712.8px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div14 {
        position: absolute;
        top: 848px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div15 {
        position: absolute;
        top: 1174.4px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div16 {
        position: absolute;
        top: 1040px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div17 {
        position: absolute;
        top: 882.4px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div18 {
        position: absolute;
        top: 1208.8px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .div19 {
        position: absolute;
        top: 1243.2px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
    }
    .lineIcon {
        position: absolute;
        top: 463.2px;
        left: 393.8px;
        width: 4px;
        /* height: 1045px; */
        height: 836px;

        object-fit: contain;
    }
    .groupChild1 {
        position: absolute;
        top: 514.8px;
        /* left: 587px; */
        left: 393.29px;

        width: 100px;
        width: 67px;

        height: 1px;
        border: 1px;
        object-fit: contain;
    }
    .groupChild2 {
        position: absolute;
        top: 761px;
        top: 648.8px;

        left: 393.29px;
        width: 67px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild3 {
        position: absolute;
        top: 929px;
        top: 783.2px;

        left: 393.29px;
        width: 67px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild4 {
        position: absolute;
        top: 1140px;
        top: 954px;

        left: 393.29px;
        width: 67px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild5 {
        position: absolute;
        top: 1308px;
        top: 1111.4px;

        left: 393.29px;
        width: 67px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild6 {
        position: absolute;
        top: 509.6px;
        left: 389.27px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild7 {
        position: absolute;
        top: 644px;
        left: 389.27px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild8 {
        position: absolute;
        top: 779.2px;
        left: 389.27px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild9 {
        position: absolute;
        top: 948.8px;
        left: 389.27px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild10 {
        position: absolute;
        top: 1105.6px;
        left: 389.27px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}

.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}

.groupInner {
    position: absolute;
    top: 0px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.rectangleDiv {
    position: absolute;
    top: 94px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.vdigmInc {
    position: absolute;
    top: 204px;
    left: 130px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.headOffice {
    position: absolute;
    top: 277px;
    left: 130px;
}
.f1012293 {
    position: absolute;
    top: 277px;
    left: 240px;
    font-weight: 500;
}
.c304SeoulAi {
    position: absolute;
    top: 316px;
    left: 240px;
    font-weight: 500;
}
.rdCenter {
    position: absolute;
    top: 316px;
    left: 130px;
}
.tel82322771700 {
    position: absolute;
    top: 355px;
    left: 130px;
    font-family: NanumSquare;
}
.mailBizvdigmcom {
    position: absolute;
    top: 393px;
    left: 130px;
    font-family: NanumSquare;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div {
    position: absolute;
    top: 204px;
    left: 1690px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div1 {
    position: absolute;
    top: 204px;
    left: 1750px;
    width: 40px;
    height: 40px;
}
.groupParent {
    position: absolute;
    top: 1782px;
    left: 0px;
    width: 1920px;
    height: 411px;
    font-size: 16px;
    color: #999;
}



.aboutUs1 {
    position: absolute;
    top: 0px;
    left: 0px;
}
.solution {
    position: absolute;
    top: 0px;
    left: 274px;
    font-weight: 500;
}
.tih {
    position: absolute;
    top: 0px;
    left: 541px;
    font-weight: 500;
}
.career {
    position: absolute;
    top: 0px;
    left: 770px;
    font-weight: 500;
}
.contact {
    position: absolute;
    top: 0px;
    left: 1024px;
    font-weight: 500;
}
.aboutUsParent {
    position: absolute;
    top: 28px;
    left: 416px;
    width: 1088px;
    height: 21px;
    color: #222;
}
.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #3c3c3c;
}
.parent {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
}

.aboutUs:hover, .organizationChart:hover, .history1:hover, .vision:hover {
    cursor: pointer;
}