/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1795px;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #666;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .shapingTheFuture {
        margin: 0;
    }
    .aiContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
        color: #222;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 1795px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 1795px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 456px;
        width: 100%;
        z-index: 2;
    }
    .b5 {
        position: absolute;
        top: 0px;
        left: 0px;
        color: #999;
    }
    .div1 {
        position: absolute;
        top: 0px;
        left: 166px;
        font-weight: 800;
        color: #222;
    }
    .b {
        position: absolute;
        top: 0px;
        left: 299px;
        color: #999;
    }
    .b1 {
        position: absolute;
        top: 0px;
        left: 449px;
        color: #999;
    }
    .groupItem {
        position: absolute;
        top: 78px;
        left: 0px;
        background-color: #d9d9d9;
        width: 1920px;
        height: 2px;
    }
    .ellipseDiv {
        position: absolute;
        top: -8px;
        left: 199px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .div6 {
        position: absolute;
        top: 620px;
        left: 707px;
        font-size: 28px;
        font-weight: 800;
        color: #222;
    }
    .div7 {
        position: absolute;
        top: 745px;
        left: 707px;
        font-size: 28px;
        font-weight: 800;
        color: #222;
    }
    .div8 {
        position: absolute;
        top: 913px;
        left: 707px;
        font-size: 28px;
        font-weight: 800;
        color: #222;
    }
    .div9 {
        position: absolute;
        top: 1292px;
        left: 707px;
        font-size: 28px;
        font-weight: 800;
        color: #222;
    }
    .div10 {
        position: absolute;
        top: 1124px;
        left: 707px;
        font-size: 28px;
        font-weight: 800;
        color: #222;
    }
    .tih1 {
        position: absolute;
        /* top: 674px; */
        top: 675px;
    
        left: 750px;
    }
    .b6 {
        position: absolute;
        /* top: 799px; */
        top: 800px;
    
        left: 750px;
    }
    .tih222 {
        position: absolute;
        /* top: 674px; */
        top: 715px;
    
        left: 750px;
    }
    .div777 {
        position: absolute;
        top: 712px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
        /* 찐 */
    }
    .tips {
        position: absolute;
        /* top: 967px; */
        top: 968px;
    
        left: 750px;
    }
    .worldOf20148 {
        position: absolute;
        /* top: 1346px; */
        top: 1347px;
    
        left: 750px;
    }
    .sba {
        position: absolute;
        top: 1178px;
        top: 1179px;
    
        left: 750px;
    }
    .nipaAi {
        position: absolute;
        /* top: 842px; */
        top: 843px;
    
        left: 750px;
    }
    .koc {
        position: absolute;
        /* top: 1010px; */
        top: 1011px;
    
        left: 750px;
    }
    .lgCnsAi {
        position: absolute;
        /* top: 1389px; */
        top: 1390px;
    
        left: 750px;
    }
    .nipa {
        position: absolute;
        /* top: 1221px; */
        top: 1222px;
    
        left: 750px;
    }
    .nipaAi1 {
        position: absolute;
        /* top: 1053px; */
        top: 1057px;
    
        left: 750px;
    }
    .aiAvatarTutor {
        position: absolute;
        /* top: 1432px; */
        top: 1433px;
    
        left: 750px;
    }
    .b7 {
        position: absolute;
        /* top: 1475px; */
        top: 1476px;
    
        left: 750px;
    }
    .div11 {
        position: absolute;
        top: 672px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div12 {
        position: absolute;
        top: 797px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div13 {
        position: absolute;
        top: 965px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div14 {
        position: absolute;
        top: 1344px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div15 {
        position: absolute;
        top: 1176px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div16 {
        position: absolute;
        top: 840px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div17 {
        position: absolute;
        top: 1008px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div18 {
        position: absolute;
        top: 1387px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div19 {
        position: absolute;
        top: 1219px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div20 {
        position: absolute;
        top: 1051px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div21 {
        position: absolute;
        top: 1430px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div22 {
        position: absolute;
        top: 1473px;
        left: 707px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto;
    }
    .lineIcon {
        position: absolute;
        top: 572px;
        left: 585px;
        width: 4px;
        height: 971px;
        object-fit: contain;
    }
    .groupChild1 {
        position: absolute;
        top: 636px;
        left: 587px;
        width: 100px;
        height: 1px;
        border: 1px;
        object-fit: contain;
    }
    .groupChild2 {
        position: absolute;
        top: 761px;
        left: 587px;
        width: 100px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild3 {
        position: absolute;
        top: 929px;
        left: 587px;
        width: 100px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild4 {
        position: absolute;
        top: 1140px;
        left: 587px;
        width: 100px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild5 {
        position: absolute;
        top: 1308px;
        left: 587px;
        width: 100px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild6 {
        position: absolute;
        top: 630px;
        left: 581px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild7 {
        position: absolute;
        top: 755px;
        left: 581px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild8 {
        position: absolute;
        top: 923px;
        left: 581px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild9 {
        position: absolute;
        top: 1134px;
        left: 581px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
    .groupChild10 {
        position: absolute;
        top: 1302px;
        left: 581px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 12px;
        height: 12px;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
    
}
@media (max-width: 1450px) {
    .shapingTheFutureContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
        /* letter-spacing: normal; */
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1436px;
        overflow: hidden;
        text-align: left;
        font-size: 15px;
        color: #666;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .shapingTheFuture {
        margin: 0;
    }
    .aiContainer {
        position: absolute;
        top: 144px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
        color: #222;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 1436px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 1795px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 364.8px;
        width: 100%;
        font-family: 15px;
        z-index: 2;
    }
    .b5 {
        position: absolute;
        top: 0px;
        left: 0px;
        color: #999;
    }
    .div1 {
        position: absolute;
        top: 0px;
        left: 132.8px;
        font-weight: 800;
        color: #222;
    }
    .b {
        position: absolute;
        top: 0px;
        left: 239.2px;
        color: #999;
    }
    .b1 {
        position: absolute;
        top: 0px;
        left: 359.2px;
        color: #999;
    }
    .groupItem {
        position: absolute;
        top: 78px;
        left: 0px;
        background-color: #d9d9d9;
        width: 1920px;
        height: 2px;
    }
    .ellipseDiv {
        position: absolute;
        top: -6px;
        left: 162px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .div6 {
        position: absolute;
        /* top: 620px; */
        top: 496px;

        left: 473.69px;
        font-size: 23px;
        font-weight: 800;
        color: #222;
    }
    .div7 {
        position: absolute;
        /* top: 745px; */
        top: 596px;

        left: 473.69px;
        font-size: 23px;
        font-weight: 800;
        color: #222;
    }
    .div8 {
        position: absolute;
        /* top: 913px; */
        top: 730.4px;

        left: 473.69px;
        font-size: 23px;
        font-weight: 800;
        color: #222;
    }
    .div9 {
        position: absolute;
        /* top: 1292px; */
        top: 1033.6px;

        left: 473.69px;
        font-size: 23px;
        font-weight: 800;
        color: #222;
    }
    .div10 {
        position: absolute;
        /* top: 1124px; */
        top: 899.2px;

        left: 473.69px;
        font-size: 23px;
        font-weight: 800;
        color: #222;
    }
    .tih1 {
        position: absolute;
        /* top: 674px; */
        /* top: 678px; */
        top: 539px;
        /* left: 750px; */
        left: 502.5px;

    }
    .b6 {
        position: absolute;
        /* top: 799px; */
        /* top: 803px; */
        top: 640px;
    
        left: 502.5px;
    }
    .tih222 {
        position: absolute;
        /* top: 674px; */
        /* top: 678px; */
        top: 569px;
        /* left: 750px; */
        left: 502.5px;
    }
    .div777 {
        position: absolute;
        /* top: 672px; */
        top: 567.6px;

        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .tips {
        position: absolute;
        /* top: 967px; */
        /* top: 971px; */
        top: 775px;
    
        left: 502.5px;
    }
    .worldOf20148 {
        position: absolute;
        /* top: 1346px; */
        /* top: 1350px; */
        top: 1075.5px;
    
        left: 502.5px;
    }
    .sba {
        position: absolute;
        /* top: 1178px; */
        /* top: 1182px; */
        top: 941px;
    
        left: 502.5px;
    }
    .nipaAi {
        position: absolute;
        /* top: 842px; */
        /* top: 846px; */
        top: 672px;
    
        left: 502.5px;
    }
    .koc {
        position: absolute;
        /* top: 1010px; */
        /* top: 1014px; */
        top: 807px;
    
        left: 502.5px;
    }
    .lgCnsAi {
        position: absolute;
        /* top: 1389px; */
        /* top: 1393px; */
        top: 1111.5px;
    
        left: 502.5px;
    }
    .nipa {
        position: absolute;
        /* top: 1221px; */
        /* top: 1225px; */
        top: 976px;
    
        left: 502.5px;
    }
    .nipaAi1 {
        position: absolute;
        /* top: 1053px; */
        /* top: 1057px; */
        top: 841px;
    
        left: 502.5px;
    }
    .aiAvatarTutor {
        position: absolute;
        /* top: 1432px; */
        /* top: 1436px; */
        top: 1145.5px;
    
        left: 502.5px;
    }
    .b7 {
        position: absolute;
        /* top: 1475px; */
        /* top: 1479px; */
        top: 1179px;
    
        left: 502.5px;
    }
    .div11 {
        position: absolute;
        /* top: 672px; */
        top: 537.6px;

        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div12 {
        position: absolute;
        /* top: 797px; */
        top: 637.6px;

        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div13 {
        position: absolute;
        /* top: 965px; */
        top: 772px;

        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div14 {
        position: absolute;
        /* top: 1344px; */
        top: 1075.2px;

        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div15 {
        position: absolute;
        /* top: 1176px; */
        top: 940.8px;

        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div16 {
        position: absolute;
        /* top: 840px; */
        top: 672px;

        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div17 {
        position: absolute;
        /* top: 1008px; */
        top: 806.4px;

        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div18 {
        position: absolute;
        top: 1109.6px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div19 {
        position: absolute;
        top: 975.2px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div20 {
        position: absolute;
        top: 840.8px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div21 {
        position: absolute;
        top: 1144px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .div22 {
        position: absolute;
        top: 1178.4px;
        left: 473.69px;
        font-size: 16px;
        font-weight: 600;
        font-family: Roboto;
    }
    .lineIcon {
        position: absolute;
        /* top: 572px; */
        top: 457.6px;

        /* left: 585px; */
        left: 391.95px;

        width: 4px;
        /* height: 971px; */
        height: 776.8px;

        object-fit: contain;
    }
    .groupChild1 {
        position: absolute;
        top: 508.8px;
        /* left: 587px; */
        left: 393.29px;

        width: 100px;
        width: 67px;

        height: 1px;
        border: 1px;
        object-fit: contain;
    }
    .groupChild2 {
        position: absolute;
        top: 761px;
        top: 608.8px;

        left: 393.29px;
        width: 67px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild3 {
        position: absolute;
        top: 929px;
        top: 743.2px;

        left: 393.29px;
        width: 67px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild4 {
        position: absolute;
        top: 1140px;
        top: 912px;

        left: 393.29px;
        width: 67px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild5 {
        position: absolute;
        top: 1308px;
        top: 1046.4px;

        left: 393.29px;
        width: 67px;
        height: 1px;
        object-fit: contain;
    }
    .groupChild6 {
        position: absolute;
        /* top: 630px; */
        top: 504px;

        /* left: 581px; */
        left: 389.27px;

        border-radius: 50%;
        background-color: #8200ff;
        width: 10px;
        height: 10px;
    }
    .groupChild7 {
        position: absolute;
        /* top: 755px; */
        top: 604px;

        left: 389.27px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 10px;
        height: 10px;
    }
    .groupChild8 {
        position: absolute;
        /* top: 923px; */
        top: 738.4px;

        left: 389.27px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 10px;
        height: 10px;
    }
    .groupChild9 {
        position: absolute;
        /* top: 1134px; */
        top: 907.2px;

        left: 389.27px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 10px;
        height: 10px;
    }
    .groupChild10 {
        position: absolute;
        /* top: 1302px; */
        top: 1041.6px;

        left: 389.27px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 10px;
        height: 10px;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) { 
    /*스타일입력*/
}


.div2 {
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 800;
}
.b2 {
    position: absolute;
    top: 0px;
    left: 266px;
}
.tih {
    position: absolute;
    top: 0px;
    left: 516px;
    font-weight: 500;
    font-family: Roboto;
}
.b3 {
    position: absolute;
    top: 0px;
    left: 745px;
}
.contact {
    position: absolute;
    top: 0px;
    left: 978px;
    font-weight: 500;
    font-family: Roboto;
}
.parent {
    position: absolute;
    top: 28px;
    left: 439px;
    width: 1042px;
    height: 21px;
    color: #222;
}
.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}
.ai {
    margin: 0;
}


.groupInner {
    position: absolute;
    top: 0px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.rectangleDiv {
    position: absolute;
    top: 94px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.div3 {
    position: absolute;
    top: 204px;
    left: 130px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.b4 {
    position: absolute;
    top: 277px;
    left: 130px;
}
.rdCenter {
    position: absolute;
    top: 315px;
    left: 130px;
}
.tel82322771700 {
    position: absolute;
    top: 353px;
    left: 130px;
}
.mailBizvdigmcom {
    position: absolute;
    top: 391px;
    left: 130px;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div4 {
    position: absolute;
    top: 204px;
    left: 1690px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div5 {
    position: absolute;
    top: 204px;
    left: 1750px;
    width: 40px;
    height: 40px;
}
.groupParent {
    position: absolute;
    top: 1701px;
    left: 0px;
    width: 1920px;
    height: 409px;
    font-size: 16px;
    color: #999;
}



.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}
.group {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    color: #3c3c3c;
    font-family: Roboto;
}

.b5:hover, .b:hover, .div1:hover, .b1:hover {
    cursor: pointer;
}