/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2247px;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .div4 {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
    }
    .p {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 2247px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 2247px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 456px;
        width: 100%;
        z-index: 2;
        font-size: 18px;
    }
    .b6 {
        position: absolute;
        top: 0px;
        left: 0px;
        color: #999;
    }
    .b2 {
        position: absolute;
        top: 0px;
        left: 150px;
        color: #999;
    }
    .div2 {
        position: absolute;
        top: 0px;
        left: 316px;
        font-weight: 800;
    }
    .ellipseDiv {
        position: absolute;
        top: -8px;
        left: 382px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .div1 {
        position: absolute;
        top: 1730px;
        left: 370px;
        font-size: 28px;
        font-weight: 800;
    }
    .bizvdigmcom1 {
        font-weight: 800;
        font-family: Roboto;
        color: #222;
    }
    .bizvdigmcom {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    .bizvdigmcomContainer {
        position: absolute;
        top: 1812px;
        left: 370px;
        color: #666;
    }
    .b {
        position: absolute;
        top: 576px;
        left: 370px;
        font-size: 28px;
        font-family: NanumSquare;
    }
    .b1 {
        position: absolute;
        top: 1084px;
        left: 370px;
        font-size: 28px;
        font-family: NanumSquare;
    }
    
    .b3 {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .b4 {
        position: absolute;
        top: 0px;
        left: 266px;
    }
    .tih {
        position: absolute;
        top: 0px;
        left: 516px;
        font-weight: 500;
        font-family: Roboto;
    }
    .div3 {
        position: absolute;
        top: 0px;
        left: 745px;
        font-weight: 800;
    }
    .contact {
        position: absolute;
        top: 0px;
        left: 978px;
        font-weight: 500;
        font-family: Roboto;
    }
    .parent {
        position: absolute;
        top: 28px;
        left: 439px;
        width: 1042px;
        height: 21px;
    }
    .icon {
        position: absolute;
        top: 25px;
        left: 30px;
        width: 108px;
        height: 28px;
        object-fit: cover;
    }
    
    .groupItem {
        position: absolute;
        top: 78px;
        left: 0px;
        background-color: #d9d9d9;
        width: 1920px;
        height: 2px;
    }
    .groupInner {
        position: absolute;
        top: 0px;
        left: 1746px;
        width: 44px;
        height: 44px;
    }
    .rectangleDiv {
        position: absolute;
        top: 94px;
        left: 0px;
        background-color: #e5e5e5;
        width: 1920px;
        height: 10px;
    }
    .div5 {
        position: absolute;
        top: 204px;
        left: 130px;
        font-size: 20px;
        font-weight: 800;
        color: #666;
    }
    .b5 {
        position: absolute;
        top: 277px;
        left: 130px;
    }
    .rdCenter {
        position: absolute;
        top: 315px;
        left: 130px;
    }
    .tel82322771700 {
        position: absolute;
        top: 353px;
        left: 130px;
    }
    .mailBizvdigmcom {
        position: absolute;
        top: 391px;
        left: 130px;
    }
    .child {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #d73116;
        width: 40px;
        height: 40px;
    }
    .instagram328841319204Icon {
        position: absolute;
        top: 11.67px;
        left: 8.33px;
        width: 23.3px;
        height: 16.7px;
        object-fit: cover;
    }
    .div6 {
        position: absolute;
        top: 204px;
        left: 1690px;
        width: 40px;
        height: 40px;
    }
    .item {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #025db3;
        width: 40px;
        height: 40px;
    }
    .instagram328841319203Icon {
        position: absolute;
        top: 9.17px;
        left: 10.83px;
        width: 20px;
        height: 20px;
        object-fit: cover;
    }
    .div7 {
        position: absolute;
        top: 204px;
        left: 1750px;
        width: 40px;
        height: 40px;
    }
    .groupParent {
        position: absolute;
        top: 2153px;
        left: 0px;
        width: 1920px;
        height: 409px;
        font-size: 16px;
        color: #999;
    }
    
    .groupChild1 {
        position: absolute;
        top: 648px;
        left: 569px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 155px;
        height: 155px;
    }
    .groupChild2 {
        position: absolute;
        top: 648px;
        left: 985px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 155px;
        height: 155px;
    }
    .groupChild3 {
        position: absolute;
        top: 678px;
        left: 781px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 155px;
        height: 155px;
    }
    .groupChild4 {
        position: absolute;
        top: 678px;
        left: 1194.05px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 155px;
        height: 155px;
    }
    .vectorIcon {
        position: absolute;
        top: 645px;
        left: 537px;
        width: 845px;
        height: 190px;
    }
    .div8 {
        position: absolute;
        top: 61.41px;
        left: 55.8px;
        line-height: 36.5px;
        font-weight: 800;
    }
    .div9 {
        position: absolute;
        top: 80.88px;
        left: 266.52px;
        line-height: 36.5px;
        font-weight: 800;
    }
    .div10 {
        position: absolute;
        top: 90.61px;
        left: 685.09px;
        line-height: 36.5px;
        font-weight: 800;
    }
    .div11 {
        position: absolute;
        top: 51.67px;
        left: 475.8px;
        line-height: 36.5px;
        font-weight: 800;
    }
    .div12 {
        position: absolute;
        top: 88.17px;
        left: 492.84px;
        font-size: 18px;
        line-height: 36.5px;
    }
    .div13 {
        position: absolute;
        top: 117.88px;
        left: 284.55px;
        font-size: 18px;
        line-height: 36.5px;
    }
    .ellipseParent {
        position: absolute;
        top: 644px;
        left: 537px;
        width: 845px;
        height: 190px;
        text-align: center;
        font-size: 28px;
        color: #fff;
    }
    .groupChild5 {
        position: absolute;
        top: 0px;
        left: 550px;
        box-shadow: 0px 0px 8.21px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: #fff;
        width: 500px;
        height: 80px;
    }
    .imageIcon {
        position: absolute;
        top: 28px;
        left: 737px;
        width: 125.3px;
        height: 23px;
        object-fit: cover;
    }
    .groupChild6 {
        position: absolute;
        top: 0px;
        left: 0px;
        box-shadow: 0px 0px 8.21px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: #fff;
        width: 500px;
        height: 80px;
    }
    .typeLogoIcon {
        position: absolute;
        top: 22px;
        left: 187px;
        width: 119.5px;
        height: 28.8px;
        object-fit: cover;
    }
    .rectangleGroup {
        position: absolute;
        top: 1267px;
        left: 435px;
        width: 1050px;
        height: 80px;
    }
    .b7 {
        position: absolute;
        top: 1167px;
        left: 370px;
        color: #666;
    }
    .maskGroupIcon {
        position: absolute;
        top: 1597px;
        left: 890px;
        width: 660px;
        height: 400px;
        object-fit: cover;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1796px;
        overflow: hidden;
        text-align: left;
        font-size: 15px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .div4 {
        position: absolute;
        top: 144px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
    }
    .p {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 1796px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1280px;
        height: 1796px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 364.8px;
        width: 100%;
        z-index: 2;
        font-size: 15px;
    }
    .b6 {
        position: absolute;
        top: 0px;
        left: 0px;
        color: #999;
    }
    .b2 {
        position: absolute;
        top: 0px;
        left: 124.8px;
        color: #999;
    }
    .div2 {
        position: absolute;
        top: 0px;
        left: 258.4px;
        font-weight: 800;
    }
    .ellipseDiv {
        position: absolute;
        top: -6px;
        left: 312px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .div1 {
        position: absolute;
        top: 1730px;
        top: 1364px;

        left: 370px;
        left: 247.9px;

        font-size: 23px;
        font-weight: 800;
    }
    .bizvdigmcom1 {
        font-weight: 800;
        font-family: Roboto;
        color: #222;
    }
    .bizvdigmcom {
        margin-block-start: 0;
        margin-block-end: 8px;
    }
    .bizvdigmcomContainer {
        position: absolute;
        top: 1812px;
        top: 1429.6px;

        left: 370px;
        left: 247.9px;
        color: #666;
    }
    .b {
        position: absolute;
        top: 576px;
        top: 460.8px;

        left: 370px;
        left: 247.9px;

        font-size: 23px;
        font-family: Roboto;
    }
    .b1 {
        position: absolute;
        top: 1084px;
        top: 867.2px;

        left: 370px;
        left: 247.9px;

        font-size: 23px;
        font-family: Roboto;
    }
    
    /* .b3 {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .b4 {
        position: absolute;
        top: 0px;
        left: 266px;
    }
    .tih {
        position: absolute;
        top: 0px;
        left: 516px;
        font-weight: 500;
        font-family: Roboto;
    }
    .div3 {
        position: absolute;
        top: 0px;
        left: 745px;
        font-weight: 800;
    }
    .contact {
        position: absolute;
        top: 0px;
        left: 978px;
        font-weight: 500;
        font-family: Roboto;
    }
    .parent {
        position: absolute;
        top: 28px;
        left: 439px;
        width: 1042px;
        height: 21px;
    }
    .icon {
        position: absolute;
        top: 25px;
        left: 30px;
        width: 108px;
        height: 28px;
        object-fit: cover;
    }
    
    .groupItem {
        position: absolute;
        top: 78px;
        left: 0px;
        background-color: #d9d9d9;
        width: 1920px;
        height: 2px;
    }
    .groupInner {
        position: absolute;
        top: 0px;
        left: 1746px;
        width: 44px;
        height: 44px;
    }
    .rectangleDiv {
        position: absolute;
        top: 94px;
        left: 0px;
        background-color: #e5e5e5;
        width: 1920px;
        height: 10px;
    }
    .div5 {
        position: absolute;
        top: 204px;
        left: 130px;
        font-size: 20px;
        font-weight: 800;
        color: #666;
    }
    .b5 {
        position: absolute;
        top: 277px;
        left: 130px;
    }
    .rdCenter {
        position: absolute;
        top: 315px;
        left: 130px;
    }
    .tel82322771700 {
        position: absolute;
        top: 353px;
        left: 130px;
    }
    .mailBizvdigmcom {
        position: absolute;
        top: 391px;
        left: 130px;
    }
    .child {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #d73116;
        width: 40px;
        height: 40px;
    }
    .instagram328841319204Icon {
        position: absolute;
        top: 11.67px;
        left: 8.33px;
        width: 23.3px;
        height: 16.7px;
        object-fit: cover;
    }
    .div6 {
        position: absolute;
        top: 204px;
        left: 1690px;
        width: 40px;
        height: 40px;
    }
    .item {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #025db3;
        width: 40px;
        height: 40px;
    }
    .instagram328841319203Icon {
        position: absolute;
        top: 9.17px;
        left: 10.83px;
        width: 20px;
        height: 20px;
        object-fit: cover;
    }
    .div7 {
        position: absolute;
        top: 204px;
        left: 1750px;
        width: 40px;
        height: 40px;
    }
    .groupParent {
        position: absolute;
        top: 2153px;
        left: 0px;
        width: 1920px;
        height: 409px;
        font-size: 16px;
        color: #999;
    } */
    
    .groupChild1 {
        position: absolute;
        top: 648px;
        top: 518.4px;

        left: 569px;
        left: 381.23px;

        border-radius: 50%;
        background-color: #ff038d;
        width: 155px;
        width: 124px;

        height: 155px;
        height: 124px;

    }
    .groupChild2 {
        position: absolute;
        top: 518.4px;
        left: 985px;
        left: 659.95px;

        border-radius: 50%;
        background-color: #ff038d;
        width: 124px;
        height: 124px;
    }
    .groupChild3 {
        position: absolute;
        top: 678px;
        top: 542.4px;

        left: 781px;
        left: 523.27px;

        border-radius: 50%;
        background-color: #8200ff;
        width: 124px;
        height: 124px;
    }
    .groupChild4 {
        position: absolute;
        top: 542.4px;
        left: 1194.05px;
        left: 800.01px;

        border-radius: 50%;
        background-color: #8200ff;
        width: 124px;
        height: 124px;
    }
    .vectorIcon {
        position: absolute;
        top: 645px;
        top: 516px;

        left: 537px;
        left: 369.79px;

        width: 845px;
        width: 566.15px;

        height: 190px;
        height: 152px;

    }
    .div8 {
        position: absolute;
        top: 61.41px;
        top: 49.13px;

        left: 55.8px;
        left: 37.39px;

        line-height: 29.2px;
        font-weight: 800;
    }
    .div9 {
        position: absolute;
        top: 80.88px;
        top: 64.7px;

        left: 266.52px;
        left: 180px;

        line-height: 29.2px;
        font-weight: 800;
    }
    .div10 {
        position: absolute;
        top: 90.61px;
        top: 72.49px;

        left: 685.09px;
        left: 459.01px;

        line-height: 29.2px;
        font-weight: 800;
    }
    .div11 {
        position: absolute;
        top: 51.67px;
        top: 41.34px;

        left: 475.8px;
        left: 318.79px;

        line-height: 29.2px;
        font-weight: 800;
    }
    .div12 {
        position: absolute;
        top: 88.17px;
        top: 70.54px;

        left: 492.84px;
        left: 330.2px;

        font-size: 15px;
        line-height: 29.2px;
    }
    .div13 {
        position: absolute;
        top: 117.88px;
        top: 94.3px;

        left: 284.55px;
        left: 190.65px;

        font-size: 15px;
        line-height: 29.2px;
    }
    .ellipseParent {
        position: absolute;
        top: 644px;
        top: 515.2px;

        left: 537px;
        left: 362px;

        width: 845px;
        height: 190px;
        text-align: center;
        font-size: 23px;
        color: #fff;
    }
    .submitAResumeContainer {
        position: absolute;
        top: 689px;
        top: 551.2px;

        left: 588px;
        left: 393.96px;

        line-height: 29.2px;
        color: #fff;
        text-align: center;
        font-size: 23px;
    }
    .onlineInterview {
        position: absolute;
        top: 718px;
        top: 574.4px;

        left: 797px;
        left: 536.99px;

        line-height: 29.2px;
        color: #fff;
        text-align: center;
        font-size: 23px;
    }
    .finalPass {
        position: absolute;
        top: 737px;
        top: 589.6px;

        left: 1208.09px;
        left: 809.42px;

        line-height: 29.2px;
        color: #fff;
        text-align: center;
        font-size: 23px;
    }
    .interview1 {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 29.2px;
    }
    .negotiation {
        position: absolute;
        top: 36.5px;
        left: 11.04px;
        font-size: 15px;
        line-height: 29.2px;
    }
    .interviewParent {
        position: absolute;
        top: 689px;
        top: 551.2px;

        left: 1004px;
        left: 672.68px;

        width: 117px;
        height: 73.5px;
        text-align: center;
        color: #fff;
        font-size: 23px;
    }
    .groupChild5 {
        position: absolute;
        top: 0px;
        left: 550px;
        left: 368.5px;

        box-shadow: 0px 0px 8.21px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: #fff;
        width: 500px;
        width: 335px;

        height: 80px;
        height: 53.6px;

    }
    .imageIcon {
        position: absolute;
        top: 28px;
        top: 22.4px;

        left: 737px;
        left: 493.79px;

        width: 125.3px;
        width: 83.95px;

        height: 23px;
        height: 15.41px;

        object-fit: cover;
    }
    .groupChild6 {
        position: absolute;
        top: 0px;
        left: 0px;
        box-shadow: 0px 0px 8.21px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: #fff;
        width: 335px;
        height: 53.6px;
    }
    .typeLogoIcon {
        position: absolute;
        top: 22px;
        top: 17.6px;

        left: 187px;
        left: 125.29px;

        width: 119.5px;
        width: 80.07px;

        height: 28.8px;
        height: 19.3px;

        object-fit: cover;
    }
    .rectangleGroup {
        position: absolute;
        top: 1266px;
        top: 1012.8px;

        left: 435px;
        left: 291.45px;

        width: 1050px;
        width: 703.5px;

        height: 80px;
        height: 53.6px;

    }
    .b7 {
        position: absolute;
        top: 1167px;
        top: 933.6px;

        left: 370px;
        left: 247.9px;

        color: #666;
    }
    .maskGroupIcon {
        position: absolute;
        top: 1596px;
        top: 1276.8px;

        left: 890px;
        left: 596.3px;

        width: 660px;
        width: 442.2px;

        height: 400px;
        height: 268px;

        object-fit: cover;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}


.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}
.group {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    color: #3c3c3c;
    font-family: Roboto;
}

.b6:hover, .b2:hover, .div2:hover {
    cursor: pointer;
}