.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1268px;
}
.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.people {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    color: #666;
    cursor: pointer;
}
.recruitmentProcess1 {
    position: absolute;
    top: 220px;
    left: 154px;
    line-height: 15px;
    font-weight: 600;
    cursor: pointer;
}
.benefits {
    position: absolute;
    top: 220px;
    left: 83px;
    line-height: 15px;
    color: #666;
    cursor: pointer;
}
.thisIsThe {
    margin-block-start: 0;
    margin-block-end: 10px;
}
.withVdigm {
    margin: 0;
}
.thisIsTheContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    color: #000;
}
.thankYouFor {
    margin-block-start: 0;
    margin-block-end: 6px;
}
.thankYouForContainer {
    position: absolute;
    top: 532px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}
.bizvdigmcom {
    font-family: Roboto;
}
.forAnyInquiriesContainer {
    position: absolute;
    top: 864px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}
.recruitmentProcess2 {
    position: absolute;
    top: 285px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
}
.submitYourResumes {
    position: absolute;
    top: 497px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
}
.feelFreeTo {
    position: absolute;
    top: 829px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
}
.groupItem {
    position: absolute;
    top: 0px;
    left: 12px;
    border-radius: 50%;
    background-color: #ff038d;
    width: 66px;
    height: 66px;
}
.groupInner {
    position: absolute;
    top: 0px;
    left: 183px;
    border-radius: 50%;
    background-color: #ff038d;
    width: 66px;
    height: 66px;
}
.ellipseDiv {
    position: absolute;
    top: 12px;
    left: 98px;
    border-radius: 50%;
    background-color: #8200ff;
    width: 66px;
    height: 66px;
}
.groupChild1 {
    position: absolute;
    top: 12px;
    left: 269px;
    border-radius: 50%;
    background-color: #8200ff;
    width: 66px;
    height: 66px;
}
.vectorIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 346px;
    height: 76px;
}
.submitAResume {
    position: absolute;
    top: 18px;
    left: 16px;
    line-height: 15px;
    font-weight: 600;
}
.onlineInterview {
    position: absolute;
    top: 28px;
    left: 102px;
    line-height: 15px;
    font-weight: 600;
}
.finalPass {
    position: absolute;
    top: 36px;
    left: 271px;
    letter-spacing: -0.02em;
    line-height: 15px;
    font-weight: 600;
}
.interview1 {
    position: absolute;
    top: 20px;
    left: 187px;
    line-height: 15px;
    font-weight: 600;
}
.negotiation {
    position: absolute;
    top: 33px;
    left: 190px;
    font-size: 10px;
    line-height: 15px;
}
.ellipseParent {
    position: absolute;
    top: 319px;
    left: 22px;
    width: 346px;
    height: 78px;
    text-align: center;
    color: #fff;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: 0px 0px 5.7px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: #fff;
    width: 350px;
    height: 50px;
}
.imageIcon {
    position: absolute;
    top: 17px;
    left: 133px;
    width: 84px;
    height: 16px;
    object-fit: cover;
}
.div {
    position: absolute;
    top: 679px;
    left: 20px;
    width: 350px;
    height: 50px;
}
.typeLogoIcon {
    position: absolute;
    top: 15px;
    left: 133.5px;
    width: 83px;
    height: 20px;
    object-fit: cover;
}
.div1 {
    position: absolute;
    top: 609px;
    left: 20px;
    width: 350px;
    height: 50px;
}
.icon2 {
    position: absolute;
    top: 941px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}
.vdigmInc {
    position: absolute;
    top: 1305px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}
.headOffice {
    font-weight: 500;
    font-family: Roboto;
}
.headOffice10f1012293Bu {
    margin-block-start: 0;
    margin-block-end: 2px;
}
.headOffice10fContainer {
    position: absolute;
    top: 1340px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.rdCenterC304Container {
    position: absolute;
    top: 1382px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.tel {
    font-weight: 500;
}
.tel82322771700 {
    position: absolute;
    top: 1434px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.mailBizvdigmcom {
    position: absolute;
    top: 1459px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.icon3 {
    position: absolute;
    top: 1253px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.groupChild2 {
    position: absolute;
    top: 1524px;
    left: 326px;
    box-shadow: 0px 0px 8.27px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    background-color: #fff;
    width: 44px;
    height: 44px;
}
.lineIcon {
    position: absolute;
    top: 1536px;
    left: 348px;
    width: 0px;
    height: 20px;
    object-fit: contain;
}
.groupChild3 {
    position: absolute;
    top: 1494px;
    left: 20px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram3288413192019Icon {
    position: absolute;
    top: 1501px;
    left: 25px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.groupChild4 {
    position: absolute;
    top: 1494px;
    left: 54px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram3288413192020Icon {
    position: absolute;
    top: 1499.5px;
    left: 60.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.icon4 {
    position: absolute;
    top: 98px;
    left: 385px;
    width: 0px;
    height: 1468px;
    object-fit: contain;
    opacity: 0.6;
}
.icon5 {
    position: absolute;
    top: 690px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px;   */
    width: 390px;
    height: 1268px;
    left: 50%;
    transform: translate(-50%);
}
.recruitmentProcess {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1268px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #222;
    font-family: Roboto;
}

