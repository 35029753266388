/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .benefits {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 3007px;
        overflow: hidden;
        text-align: center;
        font-size: 18px;
        color: #222;
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .vdigmCaresAboutContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
        text-align: left;
    }
    .vdigmCaresAbout {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 3007px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 3007px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 456px;
        width: 100%;
        z-index: 2;
        font-size: 18px;
    }
    .people {
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 600;
        color: #999;
        text-align: left;
    }
    .benefits1 {
        position: absolute;
        top: 0px;
        left: 156px;
        text-align: left;
        font-weight: 600;
    }
    .recruitmentProcess {
        position: absolute;
        top: 0px;
        left: 323px;
        font-weight: 600;
        color: #999;
        text-align: left;
    }
    .ellipseDiv {
        position: absolute;
        top: -4px;
        left: 224px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .groupChild1 {
        position: absolute;
        top: 577px;
        left: 370px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild2 {
        position: absolute;
        top: 1147px;
        left: 370px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild3 {
        position: absolute;
        top: 1717px;
        left: 370px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild4 {
        position: absolute;
        top: 2287px;
        left: 370px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild5 {
        position: absolute;
        top: 577px;
        left: 1224px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild6 {
        position: absolute;
        top: 1147px;
        left: 1224px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild7 {
        position: absolute;
        top: 1717px;
        left: 1224px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild8 {
        position: absolute;
        top: 577px;
        left: 797px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild9 {
        position: absolute;
        top: 1147px;
        left: 797px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .groupChild10 {
        position: absolute;
        top: 1717px;
        left: 797px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 326px;
        height: 470px;
    }
    .ofCourse {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    .ofCourseWeContainer {
        position: absolute;
        top: 903px;
        left: 470px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
    }
    .lunchIsOn {
        position: absolute;
        top: 1494px;
        left: 439px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
    }
    .holidayCheerByContainer {
        position: absolute;
        top: 2043px;
        left: 422px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
    }
    .embraceYourIndividualityContainer {
        position: absolute;
        top: 2613px;
        left: 422px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
    }
    .yourHealthOurContainer {
        position: absolute;
        top: 903px;
        left: 885px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
    }
    .dontCareAboutContainer {
        position: absolute;
        top: 1473px;
        left: 880px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
    }
    .weShareYourContainer {
        position: absolute;
        top: 2043px;
        left: 861px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
    }
    .enjoyDrinksAndContainer {
        position: absolute;
        top: 903px;
        left: 1307px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
    }
    .celebrateLaborDayContainer {
        position: absolute;
        top: 2043px;
        left: 1326px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
    }
    .majorInsyrances {
        position: absolute;
        top: 987px;
        left: 457px;
        line-height: 20px;
        color: #666;
    }
    .weOfferLunch {
        position: absolute;
        top: 1557px;
        left: 475px;
        line-height: 20px;
        color: #666;
    }
    .holidayGift {
        position: absolute;
        top: 2127px;
        left: 487px;
        line-height: 20px;
        color: #666;
    }
    .wearWhateverYou {
        position: absolute;
        top: 2697px;
        left: 435px;
        line-height: 20px;
        color: #666;
    }
    .medicalCheckup {
        position: absolute;
        top: 987px;
        left: 891px;
        line-height: 20px;
        color: #666;
    }
    .takeAHalf {
        position: absolute;
        top: 1557px;
        left: 880px;
        line-height: 20px;
        color: #666;
    }
    .bereavementLeave {
        position: absolute;
        top: 2127px;
        left: 884px;
        line-height: 20px;
        color: #666;
    }
    .freeDrinks {
        position: absolute;
        top: 987px;
        left: 1304px;
        line-height: 20px;
        color: #666;
    }
    .duringWorkHours {
        position: absolute;
        top: 1557px;
        left: 1314px;
        line-height: 20px;
        color: #666;
    }
    .laborDayOff {
        position: absolute;
        top: 2127px;
        left: 1334px;
        line-height: 20px;
        color: #666;
    }
    .maskGroupIcon {
        position: absolute;
        top: 617px;
        left: 410px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon1 {
        position: absolute;
        top: 1187px;
        left: 1264px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon2 {
        position: absolute;
        top: 1187px;
        left: 837px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon3 {
        position: absolute;
        top: 617px;
        left: 837px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon4 {
        position: absolute;
        top: 617px;
        left: 1264px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon5 {
        position: absolute;
        top: 1187px;
        left: 410px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon6 {
        position: absolute;
        top: 1757px;
        left: 410px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon7 {
        position: absolute;
        top: 1757px;
        left: 837px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon8 {
        position: absolute;
        top: 2327px;
        left: 410px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .maskGroupIcon9 {
        position: absolute;
        top: 1757px;
        left: 1264px;
        width: 246px;
        height: 246px;
        object-fit: cover;
    }
    .anUnforcedDiningContainer {
        position: absolute;
        top: 1473px;
        left: 1301px;
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .benefits {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2405px;
        overflow: hidden;
        text-align: center;
        font-size: 15px;
        color: #222;
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .vdigmCaresAboutContainer {
        position: absolute;
        top: 144px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
        text-align: left;
    }
    .vdigmCaresAbout {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 2405px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1280px;
        height: 2405px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 364.8px;
        width: 100%;
        z-index: 2;
        font-size: 15px;
    }
    .people {
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 600;
        color: #999;
        text-align: left;
    }
    .benefits1 {
        position: absolute;
        top: 0px;
        left: 124.8px;
        text-align: left;
        font-weight: 600;
    }
    .recruitmentProcess {
        position: absolute;
        top: 0px;
        left: 258.4px;
        font-weight: 600;
        color: #999;
        text-align: left;
    }
    .ellipseDiv {
        position: absolute;
        top: -4px;
        left: 180px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .groupChild1 {
        position: absolute;
        top: 461.6px;
        left: 247.9px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild2 {
        position: absolute;
        top: 917.6px;
        left: 247.9px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild3 {
        position: absolute;
        top: 1373.6px;
        left: 247.9px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild4 {
        position: absolute;
        top: 1829.6px;
        left: 247.9px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild5 {
        position: absolute;
        top: 461.6px;
        left: 820.08px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild6 {
        position: absolute;
        top: 917.6px;
        left: 820.08px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild7 {
        position: absolute;
        top: 1373.6px;
        left: 820.08px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild8 {
        position: absolute;
        top: 461.6px;
        left: 533.99px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild9 {
        position: absolute;
        top: 917.6px;
        left: 533.99px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .groupChild10 {
        position: absolute;
        top: 1373.6px;
        left: 533.99px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff;
        width: 218.42px;
        height: 314.9px;
    }
    .ofCourse {
        margin-block-start: 0;
        margin-block-end: 8px;
    }
    .ofCourseWeContainer {
        position: absolute;
        /* top: 903px; */
        top: 672.4px;

        /* left: 470px; */
        left: 304.9px;

        font-size: 23px;
        line-height: 25px;
        font-weight: 600;
    }
    .lunchIsOn {
        position: absolute;
        /* top: 1494px; */
        top: 1145.2px;

        /* left: 439px; */
        left: 284.13px;

        font-size: 23px;
        line-height: 25px;
        font-weight: 600;
    }
    .holidayCheerByContainer {
        position: absolute;
        /* top: 2043px; */
        top: 1584.4px;

        /* left: 422px; */
        left: 272.74px;

        font-size: 23px;
        line-height: 25px;
        font-weight: 600;
    }
    .embraceYourIndividualityContainer {
        position: absolute;
        /* top: 2613px; */
        top: 2040.4px;

        /* left: 422px; */
        left: 272.74px;

        font-size: 23px;
        line-height: 25px;
        font-weight: 600;
    }
    .yourHealthOurContainer {
        position: absolute;
        /* top: 903px; */
        top: 672.4px;

        /* left: 885px; */
        left: 582.95px;

        font-size: 23px;
        line-height: 25px;
        font-weight: 600;
    }
    .dontCareAboutContainer {
        position: absolute;
        /* top: 1473px; */
        top: 1128.4px;

        /* left: 880px; */
        left: 579.6px;

        font-size: 23px;
        line-height: 25px;
        font-weight: 600;
    }
    .weShareYourContainer {
        position: absolute;
        /* top: 2043px; */
        top: 1584.4px;

        /* left: 861px; */
        left: 566.87px;

        font-size: 23px;
        line-height: 25px;
        font-weight: 600;
    }
    .enjoyDrinksAndContainer {
        position: absolute;
        top: 672.4px;
        /* left: 1307px; */
        left: 865.69px;

        font-size: 23px;
        line-height: 25px;
        font-weight: 600;
    }
    .celebrateLaborDayContainer {
        position: absolute;
        top: 1584.4px;
        /* left: 1326px; */
        left: 878.42px;

        font-size: 23px;
        line-height: 25px;
        font-weight: 600;
    }
    .majorInsyrances {
        position: absolute;
        /* top: 987px; */
        top: 739.6px;

        /* left: 457px; */
        left: 296.19px;

        line-height: 16px;
        color: #666;
    }
    .weOfferLunch {
        position: absolute;
        /* top: 1557px; */
        top: 1195.6px;

        /* left: 475px; */
        left: 308.25px;

        line-height: 16px;
        color: #666;
    }
    .holidayGift {
        position: absolute;
        /* top: 2127px; */
        top: 1651.6px;

        /* left: 487px; */
        left: 316.29px;

        line-height: 16px;
        color: #666;
    }
    .wearWhateverYou {
        position: absolute;
        /* top: 2697px; */
        top: 2107.6px;

        /* left: 435px; */
        left: 281.45px;

        line-height: 16px;
        color: #666;
    }
    .medicalCheckup {
        position: absolute;
        /* top: 987px; */
        top: 739.6px;

        /* left: 891px; */
        left: 586.97px;

        line-height: 16px;
        color: #666;
    }
    .takeAHalf {
        position: absolute;
        /* top: 1557px; */
        top: 1195.6px;

        /* left: 880px; */
        left: 579.6px;

        line-height: 16px;
        color: #666;
    }
    .bereavementLeave {
        position: absolute;
        /* top: 2127px; */
        top: 1651.6px;

        /* left: 884px; */
        left: 582.28px;

        line-height: 16px;
        color: #666;
    }
    .freeDrinks {
        position: absolute;
        /* top: 987px; */
        top: 739.6px;

        /* left: 1304px; */
        left: 863.68px;

        line-height: 16px;
        color: #666;
    }
    .duringWorkHours {
        position: absolute;
        /* top: 1557px; */
        top: 1195.6px;

        /* left: 1314px; */
        left: 870.38px;

        line-height: 16px;
        color: #666;
    }
    .laborDayOff {
        position: absolute;
        top: 1651.6px;
        /* left: 1334px; */
        left: 883.78px;

        line-height: 16px;
        color: #666;
    }
    .maskGroupIcon {
        position: absolute;
        /* top: 617px; */
        top: 493.6px;

        /* left: 410px; */
        left: 274.7px;

        /* width: 246px; */
        width: 164.82px;

        /* height: 246px; */
        height: 164.82px;

        object-fit: cover;
    }
    .maskGroupIcon1 {
        position: absolute;
        /* top: 1187px; */
        top: 949.6px;

        /* left: 1264px; */
        left: 846.88px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon2 {
        position: absolute;
        top: 949.6px;
        /* left: 837px; */
        left: 560.79px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon3 {
        position: absolute;
        /* top: 617px; */
        top: 493.6px;

        left: 560.79px;
        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon4 {
        position: absolute;
        top: 493.6px;
        /* left: 1264px; */
        left: 846.88px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon5 {
        position: absolute;
        /* top: 1187px; */
        top: 949.6px;

        /* left: 410px; */
        left: 274.7px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon6 {
        position: absolute;
        top: 1405.6px;
        left: 274.7px;
        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon7 {
        position: absolute;
        top: 1405.6px;
        /* left: 837px; */
        left: 560.79px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon8 {
        position: absolute;
        /* top: 2327px; */
        top: 1861.6px;

        /* left: 410px; */
        left: 274.7px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .maskGroupIcon9 {
        position: absolute;
        /* top: 1757px; */
        top: 1405.6px;

        /* left: 1264px; */
        left: 846.88px;

        width: 164.82px;
        height: 164.82px;
        object-fit: cover;
    }
    .anUnforcedDiningContainer {
        position: absolute;
        /* top: 1473px; */
        top: 1128.4px;

        /* left: 1301px; */
        left: 861.67px;

        font-size: 23px;
        line-height: 25px;
        font-weight: 600;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}

.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}

.groupItem {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}
.groupInner {
    position: absolute;
    top: 0px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.rectangleDiv {
    position: absolute;
    top: 94px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.vdigmInc {
    position: absolute;
    top: 204px;
    left: 130px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.headOffice {
    position: absolute;
    top: 277px;
    left: 130px;
}
.f1012293 {
    position: absolute;
    top: 277px;
    left: 240px;
    font-weight: 500;
}
.c304SeoulAi {
    position: absolute;
    top: 316px;
    left: 240px;
    font-weight: 500;
}
.rdCenter {
    position: absolute;
    top: 316px;
    left: 130px;
}
.tel82322771700 {
    position: absolute;
    top: 355px;
    left: 130px;
    font-family: NanumSquare;
}
.mailBizvdigmcom {
    position: absolute;
    top: 393px;
    left: 130px;
    font-family: NanumSquare;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div {
    position: absolute;
    top: 204px;
    left: 1690px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div1 {
    position: absolute;
    top: 204px;
    left: 1750px;
    width: 40px;
    height: 40px;
}
.groupParent {
    position: absolute;
    top: 2913px;
    left: 0px;
    width: 1920px;
    height: 411px;
    text-align: left;
    font-size: 16px;
    color: #999;
}

.aboutUs {
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 500;
}
.solution {
    position: absolute;
    top: 0px;
    left: 274px;
    font-weight: 500;
}
.tih {
    position: absolute;
    top: 0px;
    left: 541px;
    font-weight: 500;
}
.career {
    position: absolute;
    top: 0px;
    left: 770px;
}
.contact {
    position: absolute;
    top: 0px;
    left: 1024px;
    font-weight: 500;
}
.aboutUsParent {
    position: absolute;
    top: 28px;
    left: 416px;
    width: 1088px;
    height: 21px;
    text-align: left;
}

.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #3c3c3c;
}
.parent {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    text-align: left;
    color: #666;
}

.people:hover, .benefits1:hover, .recruitmentProcess:hover {
    cursor: pointer;
}