/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2120px;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 2120px;
        left: 50%;
        transform: translate(-50%);
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 2;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 2120px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        /* left: 246.66px; */

        /* left: 370px; */

        top: 456px;
        /* width: 585px; */
    
        width: 100%;
        /* transform: translate(-50%); */
        z-index: 2;
    }
    .ellipseDiv {
        position: absolute;
        top: -8px;
        left: 66px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .div5 {
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 800;
        color: #222222;
    }
    .b {
        position: absolute;
        top: 0px;
        left: 166px;
        color: #999;
        font-weight: 700;
    }
    .b1 {
        position: absolute;
        top: 0px;
        left: 299px;
        color: #999;
        font-weight: 700;
    }
    .b2 {
        position: absolute;
        top: 0px;
        left: 449px;
        color: #999;
        font-weight: 700;
    }
    .logoAbout{
        position: relative;
        top: 576px;
        left: 19.27%;
        width: 100%;
        /* transform: translate(-19.27%); */
    }
    .bi {
        position: relative;
        top: 0px;
        left: 0px;
        font-size: 28px;
        font-weight: 800;
    }
    .sloganGroup {
        position: relative;
        left: 19.27%;
        /* left: 370px; */
        width: 100%;
        top: 1401px;
    }
    .slogan {
        position: absolute;
        /* top: 1398px; */
        top: 0px;

        left: 0px;
        font-size: 28px;
        font-weight: 800;
        font-family: Roboto;
    }
    .aiContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-family: NanumSquare;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
        color: #222222;
    }
    .visualAiContainer {
        position: absolute;
        top: 82px;
        left: 0px;
        font-weight: 700;
        color: #222222;
    }
    .visualAi {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    .paradigmShift {
        margin: 0;
    }
    .groupIcon {
        position: absolute;
        top: 758px;
        left: 370px;
        width: 1180px;
        height: 390px;
        opacity: 1;
    }
    .icon1 {
        position: absolute;
        top: 878px;
        left: 490px;
        width: 516px;
        height: 140px;
        object-fit: cover;
    }
    .icon2 {
        position: absolute;
        top: 82px;
        /* top: 907px; */
    
        left: 0px;
        width: 1180px;
        height: 390px;
        opacity: 1;
    }
    .groupChild1 {
        position: absolute;
        top: 185px;
        left: 0px;
        width: 1180px;
        height: 390px;
        opacity: 1;
    }
    .rectangleGroup {
        position: absolute;
        /* top: 1593px; */
        top: 201px;

        /* left: 520px; */
        left: 0px;

        width: 1209px;
        height: 152px;
        font-size: 60px;
    }
    .groupChild2 {
        position: absolute;
        top: 21px;
        left: 166px;
        background-color: #fff502;
        width: 549px;
        height: 57px;
        opacity: 0.5;
    }
    .groupChild3 {
        position: absolute;
        top: 117px;
        left: 305px;
        background-color: #fff502;
        width: 729px;
        height: 57px;
        opacity: 0.5;
    }
    .div1 {
        position: absolute;
        top: 0px;
        left: 150px;
        font-weight: 800;
    }
    .ai1 {
        position: absolute;
        top: 94px;
        left: 291px;
        font-weight: 800;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
    
}
@media (max-width: 1450px) {
    
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1696px;
        overflow: hidden;
        text-align: left;
        font-size: 15px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 1696px;
        left: 50%;
        transform: translate(-50%);
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 2;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 1696px;
    }
    .category {
        position: relative;
        left: 19.27%;
        /* left: 246.66px; */

        /* left: 370px; */

        top: 364.8px;
        /* width: 585px; */
    
        width: 100%;
        /* transform: translate(-50%); */
        z-index: 2;
        font-family: 15px;
    }
    .ellipseDiv {
        position: absolute;
        top: -6px;
        /* left: 445px; */
        left: 56px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .div5 {
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 800;
        color: #222222;
    }
    .b {
        position: absolute;
        top: 0px;
        left: 132.8px;
        color: #999;
        font-weight: 700;
    }
    .b1 {
        position: absolute;
        top: 0px;
        left: 239.2px;
        color: #999;
        font-weight: 700;
    }
    .b2 {
        position: absolute;
        top: 0px;
        left: 359.2px;
        color: #999;
        font-weight: 700;
    }
    .logoAbout{
        position: relative;
        top: 460.8px;
        left: 19.27%;
        width: 100%;
        /* transform: translate(-19.27%); */
    }
    .bi {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 23px;
        font-weight: 800;
    }
    .sloganGroup {
        position: relative;
        left: 19.27%;
        /* left: 370px; */
        width: 100%;
        top: 1120.8px;
    }
    .slogan {
        position: absolute;
        /* top: 1398px; */
        top: 0px;

        left: 0px;
        font-size: 25px;
        font-weight: 800;
        font-family: Roboto;
    }
    .aiContainer {
        position: absolute;
        top: 144px;
        left: 3%;
        font-family: NanumSquare;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
        color: #222222;
    }
    .visualAiContainer {
        position: absolute;
        top: 66.4px;
        left: 0px;
        font-weight: 700;
        color: #222222;
    }
    .visualAi {
        margin-block-start: 0;
        margin-block-end: 8px;
    }
    .paradigmShift {
        margin: 0;
    }
    .groupIcon {
        position: absolute;
        top: 82px;
        left: 0px;
        width: 944px;
        height: 312px;
        opacity: 1;
    }
    .icon1 {
        position: absolute;
        top: 417.2px;
        left: 120px;
        width: 412.8px;
        height: 112px;
        object-fit: cover;
    }
    .icon2 {
        position: absolute;
        top: 65.6px;
        /* top: 907px; */
    
        left: 0px;
        width: 790.6px;
        height: 261.3px;
        opacity: 1;
    }
    .groupChild1 {
        position: absolute;
        /* top: 761px; */
        top: 148px;
    
        left: 0px;
        width: 790.6px;
        height: 261.3px;
    }
    .rectangleGroup {
        position: absolute;
        /* top: 1593px; */
        top: 140px;

        left: 80px;
        width: 879px;
        height: 174px;
        font-size: 40px;
    }
    .groupChild2 {
        position: absolute;
        top: 11px;
        left: 11px;
        background-color: #fff502;
        width: 359px;
        height: 46px;
        opacity: 0.5;
    }
    .groupChild3 {
        position: absolute;
        top: 87px;
        left: 150px;
        background-color: #fff502;
        width: 483px;
        height: 46px;
        opacity: 0.5;
    }
    .div1 {
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 800;
    }
    .ai1 {
        position: absolute;
        top: 74px;
        left: 141px;
        font-weight: 800;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}

.b3 {
    position: absolute;
    top: 0px;
    left: 266px;
}
.tih {
    position: absolute;
    top: 0px;
    left: 516px;
    font-weight: 500;
    font-family: Roboto;
}
.b4 {
    position: absolute;
    top: 0px;
    left: 745px;
}
.contact {
    position: absolute;
    top: 0px;
    left: 978px;
    font-weight: 500;
    font-family: Roboto;
}
.parent {
    position: absolute;
    top: 28px;
    left: 439px;
    width: 1042px;
    height: 21px;
}
.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}

.groupItem {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}
.groupInner {
    position: absolute;
    top: 0px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.rectangleDiv {
    position: absolute;
    top: 94px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.div2 {
    position: absolute;
    top: 204px;
    left: 130px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.b5 {
    position: absolute;
    top: 277px;
    left: 130px;
}
.rdCenter {
    position: absolute;
    top: 315px;
    left: 130px;
}
.tel82322771700 {
    position: absolute;
    top: 353px;
    left: 130px;
}
.mailBizvdigmcom {
    position: absolute;
    top: 391px;
    left: 130px;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div3 {
    position: absolute;
    top: 204px;
    left: 1690px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div4 {
    position: absolute;
    top: 204px;
    left: 1750px;
    width: 40px;
    height: 40px;
}
.groupParent {
    position: absolute;
    top: 2026px;
    left: 0px;
    width: 1920px;
    height: 409px;
    font-size: 16px;
    color: #999;
}

.groupChild0 {
    position: absolute;
    top: 760px;
    left: 370px;
    width: 1180px;
    height: 390px;
    /* opacity: 0.2; */
}



.icon3 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}
.group {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    color: #3c3c3c;
    font-family: Roboto;
}


.div5:hover, .b1:hover, .b:hover, .b2:hover {
    cursor: pointer;
}