.footer {
    position: relative;
    left: 0px;
    margin-top: auto;
    background-color: #fff;
    width: 100%;
    position: block;
    z-index: 7;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #666;
    height: 350px;
    transform: translateY(0%);
    display: flex;
    font-family: Roboto;
}

/* .home {
    width: 100%;
    position: absolute;
    background-color: #fff;
    height: 1708px;

    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #666;
    font-family: NanumSquare;
 
} */

.rectangleParent {
    position: absolute;
    top: -1px;
    /* left: 0px; */
    width: 390px;
    height: 350px;
    left: 50%;
    transform: translate(-50%);
    /* z-index: 7 ; */
    
}

.div3 {
    position: absolute;
    /* top: 1419px; */
    top: 20%;
    left: 5.13%;
    line-height: 15px;
    font-weight: 700;
    font-weight: 700;

}

.b5 {
    position: absolute;
    /* top: 1454px; */
    top: 30%;
    left: 5.13%;
    line-height: 15px;
    font-weight: 500;
    /* font-family: ; */
    color: #999;
}

.rdCenter {
    position: absolute;
    /* top: 1479px; */
    top: 37.14%;
    left: 5.13%;
    line-height: 15px;
    color: #999;
    font-weight: 500;

}

.c304 {
    position: absolute;
    /* top: 1499px; */
    top: 42.86%;
    /* left: 103px; */
    left: 26.41%;
    line-height: 15px;
    color: #999;
    font-weight: 500;
}

.tel82322771700 {
    position: absolute;
    /* top: 1524px; */
    top: 50%;
    left: 5.13%;
    line-height: 15px;
    font-weight: 500;
    color: #999;
}

.mailBizvdigmcom {
    position: absolute;
    /* top: 1549px; */
    top: 57.14%;
    left: 5.13%;
    line-height: 15px;
    font-weight: 500;
    color: #999;
}

.groupItem {
    position: absolute;
    /* top: 1367px; */
    top: 5.14%;
    /* left: 2px; */
    left: 0.5%;
    /* max-height: 100%; */
    height: 5px;

    /* width: 386px; */
    width: 100%;

}

.icon6 {
    position: sticky;
    /* top: 1614px; */
    top: 75.71%;  
    /* left: 326px; */
    left: 83.59%;
    width: 44px;
    height: 44px;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div4 {
    position: absolute;
    /* top: 1584px; */
    top: 67.14%;
    left: 5.13%;
    width: 24px;
    height: 24px;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div5 {
    position: absolute;
    top: 67.14%;
    left: 13.85%;
    width: 24px;
    height: 24px;
}
  
 





