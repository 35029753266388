.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1277px;
}
.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.tihtomorrowInHere {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
}
.businessStrategyFor {
    position: absolute;
    top: 791px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
}
.virtualStreamerAnd {
    margin-block-start: 0;
    margin-block-end: 10px;
}
.tomorrowInHere {
    margin: 0;
}
.virtualStreamerAndContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    color: #000;
}
.icon2 {
    position: absolute;
    top: 90px;
    left: 385px;
    width: 0px;
    height: 1485px;
    object-fit: contain;
    opacity: 0.6;
}
.vdigmInc {
    position: absolute;
    top: 1314px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}
.headOffice {
    font-weight: 500;
    font-family: Roboto;
}
.headOffice10f1012293Bu {
    margin-block-start: 0;
    margin-block-end: 2px;
}
.headOffice10fContainer {
    position: absolute;
    top: 1349px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.rdCenterC304Container {
    position: absolute;
    top: 1391px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.tel {
    font-weight: 500;
}
.tel82322771700 {
    position: absolute;
    top: 1443px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.mailBizvdigmcom {
    position: absolute;
    top: 1468px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.icon3 {
    position: absolute;
    top: 1262px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.groupItem {
    position: absolute;
    top: 1533px;
    left: 326px;
    box-shadow: 0px 0px 8.27px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    background-color: #fff;
    width: 44px;
    height: 44px;
}
.groupInner {
    position: absolute;
    top: 1545px;
    left: 348px;
    width: 0px;
    height: 20px;
    object-fit: contain;
}
.ellipseDiv {
    position: absolute;
    top: 1503px;
    left: 20px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram328841319209Icon {
    position: absolute;
    top: 1510px;
    left: 25px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.groupChild1 {
    position: absolute;
    top: 1503px;
    left: 54px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram3288413192010Icon {
    position: absolute;
    top: 1508.5px;
    left: 60.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.tomorrowInHere1 {
    position: absolute;
    top: 515px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
}
.joinUsTomorrow {
    margin-block-start: 0;
    margin-block-end: 6px;
}
.joinUsTomorrowContainer {
    position: absolute;
    top: 550px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}
.tihImageIcon {
    position: absolute;
    top: 285px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}
.website {
    position: absolute;
    top: 515px;
    left: 327px;
    font-size: 12px;
    line-height: 15px;
    color: #666;
    text-align: right;
}
.icon4 {
    position: absolute;
    top: 849px;
    left: 23px;
    width: 346px;
    height: 223px;
}
.icon5 {
    position: absolute;
    top: 1034px;
    left: 71px;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    object-fit: cover;
}
.icon6 {
    position: absolute;
    top: 930px;
    left: 276px;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    object-fit: cover;
}
.icon7 {
    position: absolute;
    top: 826px;
    left: 70px;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    object-fit: cover;
}
.tihServiceTo {
    position: absolute;
    top: 861px;
    left: 127px;
    line-height: 15px;
    font-weight: 500;
}
.tihAiNpcContainer {
    position: absolute;
    top: 1069px;
    left: 127px;
    letter-spacing: -0.01em;
    line-height: 15px;
    font-weight: 500;
}
.aiVirtualStreamerContainer {
    position: absolute;
    top: 965px;
    left: 117px;
    line-height: 15px;
    font-weight: 500;
    text-align: right;
}
.expandingToNorthContainer {
    position: absolute;
    top: 886px;
    left: 127px;
    font-size: 12px;
    line-height: 15px;
    color: #3d3d3d;
}
.fashionEducationAndContainer {
    position: absolute;
    top: 1111px;
    left: 127px;
    font-size: 12px;
    line-height: 15px;
    color: #3d3d3d;
}
.aGroupOfContainer {
    position: absolute;
    top: 1000px;
    left: 33px;
    font-size: 12px;
    line-height: 15px;
    color: #3d3d3d;
    text-align: right;
}
.icon8 {
    position: absolute;
    top: 816px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1277px;
    left: 50%;
    transform: translate(-50%);
}
.tih {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1277px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #222;
    font-family: Roboto;
}
.deleteLinkStyle {
    text-decoration: none;
    color: inherit;
}
.deleteLinkStyle:hover {
    text-decoration: none;
    color: inherit;
}

