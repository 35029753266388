.child {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1078px;
}

.icon {
    position: absolute;
    top: 49px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 219px;
    left: 93px;
    width: 202px;
    height: 52px;
    object-fit: cover;
}

.icon2 {
    position: absolute;
    top: 51px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.businessContact {
    position: absolute;
    top: 137px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-family: Roboto;
    color: #222;
}

.div1 {
    position: absolute;
    top: 1139px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}

.b {
    position: absolute;
    top: 1174px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.rdCenter {
    position: absolute;
    top: 1199px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.c304 {
    position: absolute;
    top: 1219px;
    left: 103px;
    line-height: 15px;
    color: #999;
}

.tel82322771700 {
    position: absolute;
    top: 1244px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.mailBizvdigmcom {
    position: absolute;
    top: 1269px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.icon3 {
    position: absolute;
    top: 1087px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}

.icon4 {
    position: absolute;
    top: 1334px;
    left: 326px;
    width: 44px;
    height: 44px;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div2 {
    position: absolute;
    top: 1304px;
    left: 20px;
    width: 24px;
    height: 24px;
}

.inner {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div3 {
    position: absolute;
    top: 1304px;
    left: 54px;
    width: 24px;
    height: 24px;
}

.tel {
    position: absolute;
    top: 357px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}

.eMail {
    position: absolute;
    top: 392px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}

.adress {
    position: absolute;
    top: 427px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}

.rdCenterAdress {
    position: absolute;
    top: 487px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}

.rectangleDiv {
    position: absolute;
    top: 635px;
    left: 20px;
    background-color: #d9d9d9;
    width: 350px;
    height: 350px;
}

.b1 {
    position: absolute;
    top: 600px;
    left: 20px;
    line-height: 15px;
}

.b2 {
    position: absolute;
    top: 630px;
    left: 20px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
    z-index: 5;
}

.b2Bold {
    position: absolute;
    top: 630px;
    left: 20px;
    line-height: 15px;
    color: #222;
    font-weight: 600;
    z-index: 5;

}

.b3 {
    position: absolute;
    top: 630px;
    left: 67px;
    line-height: 15px;
    color: #666;
    font-weight: 600;
    z-index: 5;

}

.b3Bold {
    position: absolute;
    top: 630px;
    left: 67px;
    line-height: 15px;
    color: #222;
    font-weight: 600;
    z-index: 5;

}

.div4 {
    position: absolute;
    top: 357px;
    left: 63px;
    line-height: 15px;
}

.bizvdigmcom {
    position: absolute;
    top: 392px;
    left: 84px;
    line-height: 15px;
}

.div5 {
    position: absolute;
    top: 452px;
    left: 20px;
    line-height: 15px;
}

.ai {
    margin-block-start: 0;
    margin-block-end: 8px;
}

.c3041 {
    margin: 0;
}

.aiContainer {
    position: absolute;
    top: 512px;
    left: 20px;
    line-height: 15px;
}

.vdigmInc {
    position: absolute;
    top: 321px;
    left: 20px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    color: #222;
}

.icon5 {
    position: absolute;
    top: 83px;
    left: 385px;
    width: 0px;
    height: 1293px;
    object-fit: contain;
    opacity: 0.6;
}

.icon6 {
    position: absolute;
    top: 567px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.div {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1078px;
    left: 50%;
    transform: translate(-50%);
}

.contact {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1078px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #3c3c3c;
    font-family: NanumSquare;
}

.div2 {
    position: absolute;
    top: 665px;
    left: 20px;
    width: 24px;
    height: 24px;
    /* z-index: 5; */
}