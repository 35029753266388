.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 923px;
}

.div1 {
    position: absolute;
    top: 304px;
    left: 46px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #222222;
}

.div2 {
    position: absolute;
    top: 414px;
    left: 46px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #222;
}

.div3 {
    position: absolute;
    top: 610px;
    left: 46px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #222;
}

.div4 {
    position: absolute;
    top: 745px;
    left: 46px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #222;
}

.div5 {
    position: absolute;
    top: 499px;
    left: 46px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #222;
}

.div6 {
    position: absolute;
    top: 439px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div7 {
    position: absolute;
    top: 464px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div8 {
    position: absolute;
    top: 329px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div9 {
    position: absolute;
    top: 354px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div10 {
    position: absolute;
    top: 524px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div11 {
    position: absolute;
    top: 635px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div12 {
    position: absolute;
    top: 770px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div13 {
    position: absolute;
    top: 795px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div14 {
    position: absolute;
    top: 820px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div15 {
    position: absolute;
    top: 845px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div16 {
    position: absolute;
    top: 685px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div17 {
    position: absolute;
    top: 549px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.div18 {
    position: absolute;
    top: 574px;
    left: 46px;
    line-height: 15px;
    font-weight: 500;
    color: #3C3C3C;
    font-family: Roboto;
}

.b {
    position: absolute;
    top: 439px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.nipaAi {
    position: absolute;
    top: 464px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.sba {
    margin-block-start: 0;
    margin-block-end: 10px;
}

.p {
    margin: 0;
}

.sbaContainer {
    position: absolute;
    top: 635px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.worldOf2048 {
    position: absolute;
    top: 770px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.lgCnsAi {
    position: absolute;
    top: 795px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.aiAvatarTutor {
    position: absolute;
    top: 820px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.b1 {
    position: absolute;
    top: 845px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.nipaContainer {
    position: absolute;
    top: 685px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.tih {
    position: absolute;
    top: 329px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.nipaAiContainer {
    position: absolute;
    top: 354px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.tips {
    position: absolute;
    top: 524px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.koc {
    position: absolute;
    top: 549px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.nipaAi2 {
    position: absolute;
    top: 574px;
    left: 74px;
    line-height: 15px;
    color: #3d3d3d;
}

.groupItem {
    position: absolute;
    top: 283px;
    left: 27px;
    width: 2px;
    height: 596px;
    object-fit: contain;
}

.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.div19 {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
}

.div20 {
    position: absolute;
    top: 220px;
    left: 137px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
}

.div21 {
    position: absolute;
    top: 220px;
    left: 91px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
}

.div22 {
    position: absolute;
    top: 220px;
    left: 196px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
}

.aiContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
    color: #222;
}

.div23 {
    position: absolute;
    top: 316px;
    left: 32px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    transform-origin: 0 0;
}

.div24 {
    position: absolute;
    top: 426px;
    left: 32px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    transform-origin: 0 0;
}

.div25 {
    position: absolute;
    top: 511px;
    left: 32px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    transform-origin: 0 0;
}

.div26 {
    position: absolute;
    top: 622px;
    left: 32px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    transform-origin: 0 0;
}

.div27 {
    position: absolute;
    top: 757px;
    left: 32px;
    border-radius: 50%;
    background-color: #8200ff;
    border: 2px solid #8200ff;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    transform-origin: 0 0;
}

.icon2 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 1169px;
    object-fit: contain;
    opacity: 0.6;
}

.div28 {
    position: absolute;
    top: 52px;
    left: 18px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}

.b2 {
    position: absolute;
    top: 87px;
    left: 18px;
    line-height: 15px;
}

.rdCenter {
    position: absolute;
    top: 112px;
    left: 18px;
    line-height: 15px;
}

.c304 {
    position: absolute;
    top: 132px;
    left: 101px;
    line-height: 15px;
}

.tel82322771700 {
    position: absolute;
    top: 157px;
    left: 18px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}

.mailBizvdigmcom {
    position: absolute;
    top: 182px;
    left: 18px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
}

.icon3 {
    position: absolute;
    top: 0px;
    left: 0px;
    max-height: 100%;
    width: 386px;
}

.icon4 {
    position: absolute;
    top: 247px;
    left: 324px;
    width: 44px;
    height: 44px;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div29 {
    position: absolute;
    top: 217px;
    left: 18px;
    width: 24px;
    height: 24px;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div30 {
    position: absolute;
    top: 217px;
    left: 52px;
    width: 24px;
    height: 24px;
}

.parent {
    position: absolute;
    top: 982px;
    left: 2px;
    width: 386px;
    height: 291px;
    color: #999;
}

.icon5 {
    position: absolute;
    top: 810px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 923px;
    left: 50%;
    transform: translate(-50%);
}

.div {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 923px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    font-family: NanumSquare;
    font-weight: 700;
    color: #3D3D3D;
}