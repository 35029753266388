/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1628px;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .aiContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
    }
    .ai {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 1628px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 1628px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 456px;
        width: 100%;
        z-index: 2;
    }
    .b5 {
        position: absolute;
        top: 0px;
        left: 0px;
        color: #999;
    }
    .b {
        position: absolute;
        top: 0px;
        left: 166px;
        color: #999;
    }
    .b1 {
        position: absolute;
        top: 0px;
        left: 299px;
        color: #999;
    }
    .div1 {
        position: absolute;
        top: 0px;
        left: 449px;
        font-weight: 800;
    }
    .ellipseDiv {
        position: absolute;
        top: -8px;
        left: 482px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .groupChild1 {
        position: absolute;
        /* top: 1378.03px; */
        top: 1247.03px;
    
        left: 632.03px;
        border-radius: 0 0 131px 131px;
        background-color: #8200ff;
        width: 262px;
        height: 131px;
        transform:  rotate(180deg);
        transform-origin: 0 0;
    }
    .groupChild2 {
        position: absolute;
        top: 1143.29px;
        left: 604.73px;
        box-shadow: 0px 5.458890438079834px 17.2px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        background-color: #fff;
        width: 207.4px;
        height: 207.4px;
        transform:  rotate(90deg);
        transform-origin: 0 0;
    }
    .b6 {
        position: absolute;
        top: 1227px;
        left: 433px;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
    }
    .groupChild3 {
        position: absolute;
        top: 1246.59px;
        left: 835.02px;
        border-radius: 0 0 131px 131px;
        background-color: #ff038d;
        width: 262px;
        height: 131px;
    }
    .groupChild4 {
        position: absolute;
        top: 1142.88px;
        left: 1069.75px;
        box-shadow: 0px 5.458890438079834px 17.2px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        background-color: #fff;
        width: 207.4px;
        height: 207.4px;
        transform:  rotate(90deg);
        transform-origin: 0 0;
    }
    .p1 {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    .b7 {
        position: absolute;
        top: 1202px;
        left: 898px;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
    }
    .groupChild5 {
        position: absolute;
        top: 1247.03px;
        left: 1550.03px;
        border-radius: 0 0 131px 131px;
        background-color: #8200ff;
        width: 262px;
        height: 131px;
        transform:  rotate(-180deg);
        transform-origin: 0 0;
    }
    .groupChild6 {
        position: absolute;
        top: 1143.29px;
        left: 1522.73px;
        box-shadow: 0px 5.458890438079834px 17.2px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        background-color: #fff;
        width: 207.4px;
        height: 207.4px;
        transform:  rotate(90deg);
        transform-origin: 0 0;
    }
    .b8 {
        position: absolute;
        top: 1202px;
        left: 1351px;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
    }
    .lineIcon {
        position: absolute;
        top: 859.78px;
        /* left: 704.85px; */
        left: 706.85px;
    
        width: 102.5px;
        height: 102.5px;
        object-fit: contain;
    }
    .groupChild7 {
        position: absolute;
        top: 859.78px;
        left: 1113.3px;
        width: 102.5px;
        height: 102.5px;
        object-fit: contain;
    }
    .groupChild8 {
        position: absolute;
        top: 920.59px;
        left: 920.16px;
        width: 102.5px;
        height: 145px;
        object-fit: contain;
    }
    .groupChild9 {
        position: absolute;
        top: 576px;
        left: 1165.41px;
        border-radius: 50%;
        background: linear-gradient(128.82deg, #8200ff 27.59%, #ff038d);
        width: 385px;
        height: 385px;
        transform:  rotate(90deg);
        transform-origin: 0 0;
    }
    .groupChild10 {
        position: absolute;
        top: 616.54px;
        left: 1124.88px;
        border-radius: 50%;
        background-color: #fff;
        width: 304px;
        height: 304px;
        transform:  rotate(90deg);
        transform-origin: 0 0;
    }
    .vision {
        position: absolute;
        top: 747px;
        left: 900px;
        font-size: 50px;
        line-height: 43.44px;
        font-weight: 800;
        font-family: Roboto;
        text-align: center;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
    
}
@media (max-width: 1450px) {
    .shapingTheFutureContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
        /* letter-spacing: normal; */
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1303px;
        overflow: hidden;
        text-align: left;
        font-size: 15px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .aiContainer {
        position: absolute;
        top: 144px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
    }
    .ai {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 1303px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1280px;
        height: 1303px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 364.8px;
        width: 100%;
        z-index: 2;
        font-family: 15px;
    }
    .b5 {
        position: absolute;
        top: 0px;
        left: 0px;
        color: #999;
    }
    .b {
        position: absolute;
        top: 0px;
        left: 132.8px;
        color: #999;
    }
    .b1 {
        position: absolute;
        top: 0px;
        left: 239.2px;
        color: #999;
    }
    .div1 {
        position: absolute;
        top: 0px;
        left: 359.2px;
        font-weight: 800;
    }
    .ellipseDiv {
        position: absolute;
        top: -6px;
        left: 386px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .groupChild1 {
        position: absolute;
        /* top: 1247.03px; */
        top: 997.62px;

        /* left: 632.03px; */
        /* left: 423.46px; */
        left: 451px;

        border-radius: 0 0 105px 105px;
        background-color: #8200ff;
        width: 210px;
        height: 105px;
        transform:  rotate(180deg);
        transform-origin: 0 0;
    }
    .groupChild2 {
        position: absolute;
        /* top: 1144.29px; */
        top: 915.43px;

        /* left: 604.73px; */
        /* left: 405.17px; */
        left: 430px;

        /* box-shadow: 0px 5.458890438079834px 17.2px rgba(0, 0, 0, 0.15); */
        box-shadow: 0px 4.3671123504638672px 13.76px rgba(0, 0, 0, 0.15);

        border-radius: 50%;
        background-color: #fff;
        width: 167px;
        height: 167px;
        transform:  rotate(90deg);
        transform-origin: 0 0;
    }
    .b6 {
        position: absolute;
        /* top: 1227px; */
        top: 981.6px;

        /* left: 433px; */
        left: 290.11px;

        font-size: 23px;
        line-height: 32px;
        text-align: center;
    }
    .groupChild3 {
        position: absolute;
        /* top: 1246.59px; */
        top: 997.27px;

        /* left: 835.02px; */
        /* left: 559.46px; */
        left: 550px;


        border-radius: 0 0 105px 105px;
        background-color: #ff038d;
        width: 210px;
        height: 105px;
    }
    .groupChild4 {
        position: absolute;
        /* top: 1143.88px; */
        top: 915.1px;

        /* left: 1069.74px; */
        /* left: 716.73px; */
        left: 738px;

        box-shadow: 0px 4.3671123504638672px 13.76px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        background-color: #fff;
        width: 167px;
        height: 167px;
        transform:  rotate(90deg);
        transform-origin: 0 0;
    }
    .p1 {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    .b7 {
        position: absolute;
        /* top: 1202px; */
        top: 961.6px;

        /* left: 898px; */
        left: 601.66px;

        font-size: 23px;
        line-height: 32px;
        text-align: center;
    }
    .groupChild5 {
        position: absolute;
        /* top: 1247.03px; */
        top: 997.62px;

        /* left: 1550.03px; */
        /* left: 1038.52px; */
        left: 1068px;


        border-radius: 0 0 105px 105px;
        background-color: #8200ff;
        width: 210px;
        height: 105px;
        transform:  rotate(-180deg);
        transform-origin: 0 0;
    }
    .groupChild6 {
        position: absolute;
        /* top: 1144.29px; */
        top: 915.43px;

        /* left: 1522.73px; */
        /* left: 1020.23px; */
        left: 1047px;


        box-shadow: 0px 4.3671123504638672px 13.76px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        background-color: #fff;
        width: 167px;
        height: 167px;
        transform:  rotate(90deg);
        transform-origin: 0 0;
    }
    .b8 {
        position: absolute;
        top: 961.6px;

        /* left: 1351px; */
        left: 907px;

        font-size: 23px;
        line-height: 32px;
        text-align: center;
    }
    .lineIcon {
        position: absolute;
        /* top: 859.78px; */
        top: 687.82px;

        /* left: 704.84px; */
        left: 473.58px;
    
        /* width: 102.5px; */
        width: 82px;

        /* height: 102.5px; */
        height: 82px;

        object-fit: contain;
    }
    .groupChild7 {
        position: absolute;
        top: 687.82px;
        /* left: 1113.29px; */
        left: 745.9px;

        /* left: 1119.3px; */
    
        width: 82px;
        height: 82px;
        object-fit: contain;
    }
    .groupChild8 {
        position: absolute;
        /* top: 920.59px; */
        top: 736.47px;

        /* left: 920.15px; */
        left: 616.5px;

        width: 82px;
        height: 116px;
        object-fit: contain;
        /* left: 971.4px; */
    }
    .groupChild9 {
        position: absolute;
        /* top: 576px; */
        top: 460.8px;

        /* left: 1165.41px; */
        /* left: 780.82px; */
        left: 807px;

        border-radius: 50%;
        background: linear-gradient(128.82deg, #8200ff 27.59%, #ff038d);
        /* width: 385px; */
        width: 308px;

        /* height: 385px; */
        height: 308px;

        transform:  rotate(90deg);
        transform-origin: 0 0;
    }
    .groupChild10 {
        position: absolute;
        /* top: 616.54px; */
        top: 493.23px;

        /* left: 1124.87px; */
        /* left: 753.66px; */
        left: 775px;

        border-radius: 50%;
        background-color: #fff;
        /* width: 304px; */
        width: 243px;

        /* height: 304px; */
        height: 243px;

        transform:  rotate(90deg);
        transform-origin: 0 0;
    }
    .vision {
        position: absolute;
        /* top: 747px; */
        top: 597.6px;

        /* left: 900px; */
        left: 603px;

        font-size: 40px;
        line-height: 34.75px;
        font-weight: 800;
        font-family: Roboto;
        text-align: center;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}

.div2 {
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 800;
}
.b2 {
    position: absolute;
    top: 0px;
    left: 266px;
}
.tih {
    position: absolute;
    top: 0px;
    left: 516px;
    font-weight: 500;
    font-family: Roboto;
}
.b3 {
    position: absolute;
    top: 0px;
    left: 745px;
}
.contact {
    position: absolute;
    top: 0px;
    left: 978px;
    font-weight: 500;
    font-family: Roboto;
}
.parent {
    position: absolute;
    top: 28px;
    left: 439px;
    width: 1042px;
    height: 21px;
}
.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}

.groupItem {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}
.groupInner {
    position: absolute;
    top: 0px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.rectangleDiv {
    position: absolute;
    top: 94px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.div3 {
    position: absolute;
    top: 204px;
    left: 130px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.b4 {
    position: absolute;
    top: 277px;
    left: 130px;
}
.rdCenter {
    position: absolute;
    top: 315px;
    left: 130px;
}
.tel82322771700 {
    position: absolute;
    top: 353px;
    left: 130px;
}
.mailBizvdigmcom {
    position: absolute;
    top: 391px;
    left: 130px;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div4 {
    position: absolute;
    top: 204px;
    left: 1690px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div5 {
    position: absolute;
    top: 204px;
    left: 1750px;
    width: 40px;
    height: 40px;
}
.groupParent {
    position: absolute;
    top: 1534px;
    left: 0px;
    width: 1920px;
    height: 409px;
    font-size: 16px;
    color: #999;
}

.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}
.group {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    color: #3c3c3c;
    font-family: Roboto;
}

/* @media (max-width: 1920px) {
    .rectangleParent {
        width: 100%;
    }
} */
.b5:hover, .b:hover, .div1:hover, .b1:hover {
    cursor: pointer;
}