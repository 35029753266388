/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:839px) {
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 390px;
        height: 965px;
    }
    
    .icon {
        position: absolute;
        top: 50px;
        left: 19px;
        width: 92px;
        height: 24px;
        object-fit: cover;
    }
    
    .icon1 {
        position: absolute;
        top: 52px;
        left: 344px;
        width: 24px;
        height: 18px;
    }
    
    .div1 {
        position: absolute;
        top: 220px;
        left: 20px;
        line-height: 15px;
        font-weight: 800;
        cursor: pointer;
    }
    
    .div2 {
        position: absolute;
        top: 220px;
        left: 137px;
        line-height: 15px;
        color: #666;
        cursor: pointer;
    }
    
    .div3 {
        position: absolute;
        top: 220px;
        left: 91px;
        line-height: 15px;
        color: #666;
        cursor: pointer;
    }
    
    .bi {
        position: absolute;
        top: 285px;
        left: 20px;
        font-size: 16px;
        line-height: 15px;
        font-family: NanumSquare;
        color: #222;
        font-weight: 700;
    }
    
    .slogan {
        position: absolute;
        top: 716px;
        left: 20px;
        font-size: 16px;
        line-height: 15px;
        font-weight: 500;
        font-family: Roboto;
        color: #222;
    }
    
    .div4 {
        position: absolute;
        top: 220px;
        left: 196px;
        line-height: 15px;
        color: #666;
        cursor: pointer;
    }
    
    .ai {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    
    .p {
        margin-block-start: 0;
        margin-block-end: 8px;
    }
    
    .aiContainer {
        position: absolute;
        top: 124px;
        left: 20px;
        font-size: 18px;
        line-height: 18px;
        font-weight: 800;
    }
    
    .visualAiVirtual {
        margin-block-start: 0;
        margin-block-end: 8px;
        /* word-break: break-all; */
        overflow-wrap: break-word;
    }
    
    .visualAiVirtualContainer {
        position: absolute;
        /* display: flex; */
        top: 320px;
        left: 20px;
        line-height: 15px;
        color: #3c3c3c;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 20px;
    }
    
    .biIcon {
        position: absolute;
        top: 424px;
        left: 20px;
        width: 350px;
        height: 191px;
        opacity: 1;
    }
    
    .icon2 {
        position: absolute;
        top: 751px;
        left: 20px;
        width: 350px;
        height: 120px;
        opacity: 1;
    }
    
    .biIcon1 {
        position: absolute;
        top: 499px;
        left: 50px;
        width: 147px;
        height: 40px;
        object-fit: cover;
    }
    
    .biIcon2 {
        position: absolute;
        top: 474px;
        left: 259px;
        width: 81px;
        height: 90px;
        object-fit: cover;
    }
    
    .div5 {
        position: absolute;
        top: 1026px;
        left: 20px;
        line-height: 15px;
        font-weight: 800;
        color: #666;
    }
    
    .b {
        position: absolute;
        top: 1061px;
        left: 20px;
        line-height: 15px;
        color: #999;
    }
    
    .rdCenter {
        position: absolute;
        top: 1086px;
        left: 20px;
        line-height: 15px;
        color: #999;
    }
    
    .c304 {
        position: absolute;
        top: 1106px;
        left: 103px;
        line-height: 15px;
        color: #999;
    }
    
    .tel82322771700 {
        position: absolute;
        top: 1131px;
        left: 20px;
        line-height: 15px;
        font-weight: 500;
        font-family: Roboto;
        color: #999;
    }
    
    .mailBizvdigmcom {
        position: absolute;
        top: 1156px;
        left: 20px;
        line-height: 15px;
        font-weight: 500;
        font-family: Roboto;
        color: #999;
    }
    
    .icon3 {
        position: absolute;
        top: 974px;
        left: 2px;
        max-height: 100%;
        width: 386px;
    }
    
    .icon4 {
        position: absolute;
        top: 1221px;
        left: 326px;
        width: 44px;
        height: 44px;
    }
    
    .child {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #d73116;
        width: 24px;
        height: 24px;
    }
    
    .instagram328841319204Icon {
        position: absolute;
        top: 7px;
        left: 5px;
        width: 14px;
        height: 10px;
        object-fit: cover;
    }
    
    .div6 {
        position: absolute;
        top: 1191px;
        left: 20px;
        width: 24px;
        height: 24px;
    }
    
    .item {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #025db3;
        width: 24px;
        height: 24px;
    }
    
    .instagram328841319203Icon {
        position: absolute;
        top: 5.5px;
        left: 6.5px;
        width: 12px;
        height: 12px;
        object-fit: cover;
    }
    
    .div7 {
        position: absolute;
        top: 1191px;
        left: 54px;
        width: 24px;
        height: 24px;
    }
    
    .inner {
        position: absolute;
        top: 3px;
        left: 3px;
        background-color: #fff502;
        width: 250px;
        height: 20px;
        opacity: 0.5;
    }
    
    .inner1 {
        position: absolute;
        top: -6px;
        left: 3px;
        background-color: #fff502;
        width: 160px;
        height: 20px;
        opacity: 0.5;
    }
    
    .inner2 {
        position: absolute;
        top: 23px;
        left: 33px;
        background-color: #fff502;
        width: 215px;
        height: 20px;
        opacity: 0.5;
    }
    
    .div91 {
        position: absolute;
        top: -12px;
        left: 0px;
        line-height: 15px;
        font-weight: 800;
    }
    
    .div92 {
        position: absolute;
        top: 18px;
        left: 30px;
        line-height: 15px;
        font-weight: 800;
    }
    
    .div9 {
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 15px;
        font-weight: 800;
    }
    
    .div8 {
        position: absolute;
        top: 801px;
        left: 63px;
        width: 253px;
        height: 23px;
        font-size: 18px;
    }
    
    .icon5 {
        position: absolute;
        top: 102px;
        left: 385px;
        width: 0px;
        height: 1080px;
        object-fit: contain;
        opacity: 0.6;
    }
    
    .icon6 {
        position: absolute;
        top: 816px;
        left: 385px;
        width: 0px;
        height: 200px;
        object-fit: contain;
    }
    
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 390px;
        height: 965px;
        left: 50%;
        transform: translate(-50%);
    }
    
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 965px;
        overflow: hidden;
        text-align: left;
        font-size: 14px;
        color: #222;
        font-family: NanumSquare;
        font-weight: 400;
    }
    
    .groupItem {
        position: absolute;
        top: 425px;
        left: 20px;
        width: 350px;
        height: 191px;
    }
}
