.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1446px;
}

.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.aiAvatar1 {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
    font-family: Roboto;
    cursor: pointer;
}

.dVirtualStage {
    position: absolute;
    top: 220px;
    left: 181px;
    line-height: 15px;
    color: #666;
    font-family: Roboto;
    cursor: pointer;
}

.deepReal {
    position: absolute;
    top: 220px;
    left: 98px;
    line-height: 15px;
    color: #666;
    font-family: Roboto;
    cursor: pointer;
}

.p {
    margin-block-start: 0;
    margin-block-end: 10px;
}

.div {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
    font-family: NanumSquare;
}

.generativeAiAvatar {
    position: absolute;
    top: 515px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
}

.responsiveAiAvatar {
    position: absolute;
    top: 891px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
}

.aiAvatarNpc {
    position: absolute;
    top: 1267px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
}

.d {
    margin-block-start: 0;
    margin-block-end: 8px;
}

.dContainer {
    position: absolute;
    top: 550px;
    left: 20px;
    line-height: 15px;
    font-family: NanumSquare;
    color: #3c3c3c;
}

.pcMobile {
    margin: 0;
}

.aiPcContainer {
    position: absolute;
    top: 926px;
    left: 20px;
    line-height: 15px;
    font-family: NanumSquare;
    color: #3c3c3c;
}

.npcContainer {
    position: absolute;
    top: 1302px;
    left: 20px;
    line-height: 15px;
    font-family: NanumSquare;
    color: #3c3c3c;
}

.div1 {
    position: absolute;
    top: 1517px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    font-family: NanumSquare;
    color: #666;
}

.b {
    position: absolute;
    top: 1552px;
    left: 20px;
    line-height: 15px;
    font-family: NanumSquare;
    color: #999;
}

.rdCenter {
    position: absolute;
    top: 1577px;
    left: 20px;
    line-height: 15px;
    font-family: NanumSquare;
    color: #999;
}

.c304 {
    position: absolute;
    top: 1597px;
    left: 103px;
    line-height: 15px;
    font-family: NanumSquare;
    color: #999;
}

.tel82322771700 {
    position: absolute;
    top: 1622px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    color: #999;
}

.mailBizvdigmcom {
    position: absolute;
    top: 1647px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    color: #999;
}

.icon2 {
    position: absolute;
    top: 1465px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}

.icon3 {
    position: absolute;
    top: 1712px;
    left: 326px;
    width: 44px;
    height: 44px;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div2 {
    position: absolute;
    top: 1682px;
    left: 20px;
    width: 24px;
    height: 24px;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div3 {
    position: absolute;
    top: 1682px;
    left: 54px;
    width: 24px;
    height: 24px;
}

.responsiveAiAvatarImage {
    position: absolute;
    top: 661px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}

.generativeAiAvatarImage {
    position: absolute;
    top: 285px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}

.aiAvatarNpcImage {
    position: absolute;
    top: 1037px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}

.icon4 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 1652px;
    object-fit: contain;
    opacity: 0.6;
}

.icon5 {
    position: absolute;
    top: 567px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1446px;
    left: 50%;
    transform: translate(-50%);
}

.aiAvatar {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1446px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #222;
    font-family: Roboto;
}