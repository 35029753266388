/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1869px;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .div2 {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
    }
    .p {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 1869px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 1869px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 456px;
        width: 100%;
        z-index: 2;
        font-size: 18px;
    }
    .div6 {
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 800;
    }
    .b {
        position: absolute;
        top: 0px;
        left: 150px;
        color: #999;
    }
    .b1 {
        position: absolute;
        top: 0px;
        left: 316px;
        color: #999;
    }
    .ellipseDiv {
        position: absolute;
        top: -8px;
        left: 50px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .b5 {
        position: absolute;
        top: 1226px;
        left: 370px;
        font-size: 28px;
        /* font-family: Roboto; */
    }
    .div7 {
        position: absolute;
        top: 695px;
        left: 370px;
        font-size: 28px;
        font-weight: 800;
    }
    .p2 {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    .b6 {
        position: absolute;
        top: 778px;
        left: 370px;
        color: #666;
    }
    .b7 {
        position: absolute;
        top: 1569px;
        left: 651px;
        color: #666;
        text-align: center;
    }
    
    .talk76478631280Icon {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 660px;
        height: 400px;
        object-fit: cover;
    }
    .rectangleGroup {
        position: absolute;
        top: 576px;
        left: 890px;
        width: 660px;
        height: 400px;
    }
    .div8 {
        position: absolute;
        top: 89px;
        left: 80px;
        line-height: 31.8px;
        font-weight: 800;
    }
    .ellipseIcon {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 210px;
        height: 210px;
    }
    .group {
        position: absolute;
        top: 1309px;
        left: 553px;
        width: 210px;
        height: 210px;
        text-align: center;
        font-size: 28px;
    }
    .div9 {
        position: absolute;
        top: 89px;
        left: 79px;
        line-height: 31.8px;
        font-weight: 800;
    }
    .container {
        position: absolute;
        top: 1309px;
        left: 863px;
        width: 210px;
        height: 210px;
        text-align: center;
        font-size: 28px;
    }
    .groupDiv {
        position: absolute;
        top: 1309px;
        left: 1173px;
        width: 210px;
        height: 210px;
        text-align: center;
        font-size: 28px;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .div {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1495px;
        overflow: hidden;
        text-align: left;
        font-size: 15px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .div2 {
        position: absolute;
        top: 144px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
    }
    .p {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 1495px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1280px;
        height: 1495px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 364.8px;
        width: 100%;
        z-index: 2;
        font-size: 15px;
    }
    .div6 {
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 800;
    }
    .b {
        position: absolute;
        top: 0px;
        left: 124.8px;
        color: #999;
    }
    .b1 {
        position: absolute;
        top: 0px;
        left: 258.4px;
        color: #999;
    }
    .ellipseDiv {
        position: absolute;
        top: -6px;
        left: 41px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .b5 {
        position: absolute;
        /* top: 1227px; */
        top: 981.6px;

        /* left: 370px; */
        left: 247.9px;

        font-size: 23px;
        /* font-family: Roboto; */
    }
    .div7 {
        position: absolute;
        /* top: 695px; */
        top: 536px;

        left: 247.9px;
        font-size: 23px;
        font-weight: 800;
    }
    .p2 {
        margin-block-start: 0;
        margin-block-end: 8px;
    }
    .b6 {
        position: absolute;
        /* top: 778px; */
        top: 602.4px;

        left: 247.9px;
        color: #666;
    }
    .b7 {
        position: absolute;
        /* top: 1569px; */
        top: 1230px;

        /* left: 651px; */
        left: 385px;

        color: #666;
        text-align: center;
    }
    
    .talk76478631280Icon {
        position: absolute;
        top: 40px;
        left: 112.2px;
        width: 330px;
        height: 200px;
        object-fit: cover;
    }
    .rectangleGroup {
        position: absolute;
        top: 461.6px;
        left: 596.3px;
        width: 660px;
        height: 400px;
    }
    .div8 {
        position: absolute;
        top: 57px;
        left: 50px;
        line-height: 31.8px;
        font-weight: 800;
    }
    .ellipseIcon {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 140.7px;
        height: 140.7px;
    }
    .group {
        position: absolute;
        /* top: 1309px; */
        top: 1047.2px;

        /* left: 553px; */
        left: 370.51px;

        width: 140.7px;
        height: 140.7px;
        text-align: center;
        font-size: 23px;
    }
    .div9 {
        position: absolute;
        top: 57px;
        left: 50px;
        line-height: 31.8px;
        font-weight: 800;
    }
    .container {
        position: absolute;
        top: 1047.2px;
        /* left: 863px; */
        left: 578.21px;

        width: 140.7px;
        height: 140.7px;
        text-align: center;
        font-size: 23px;
    }
    .groupDiv {
        position: absolute;
        top: 1047.2px;
        left: 785.91px;
        width: 140.7px;
        height: 140.7px;
        text-align: center;
        font-size: 23px;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}

.b2 {
    position: absolute;
    top: 0px;
    left: 0px;
}
.b3 {
    position: absolute;
    top: 0px;
    left: 266px;
}
.tih {
    position: absolute;
    top: 0px;
    left: 516px;
    font-weight: 500;
    font-family: Roboto;
}
.div1 {
    position: absolute;
    top: 0px;
    left: 745px;
    font-weight: 800;
}
.contact {
    position: absolute;
    top: 0px;
    left: 978px;
    font-weight: 500;
    font-family: Roboto;
}
.parent {
    position: absolute;
    top: 28px;
    left: 439px;
    width: 1042px;
    height: 21px;
}
.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}

.groupItem {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}
.groupInner {
    position: absolute;
    top: 0px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.rectangleDiv {
    position: absolute;
    top: 94px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.div3 {
    position: absolute;
    top: 204px;
    left: 130px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.b4 {
    position: absolute;
    top: 277px;
    left: 130px;
}
.rdCenter {
    position: absolute;
    top: 315px;
    left: 130px;
}
.tel82322771700 {
    position: absolute;
    top: 353px;
    left: 130px;
}
.mailBizvdigmcom {
    position: absolute;
    top: 391px;
    left: 130px;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div4 {
    position: absolute;
    top: 204px;
    left: 1690px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div5 {
    position: absolute;
    top: 204px;
    left: 1750px;
    width: 40px;
    height: 40px;
}
.groupParent {
    position: absolute;
    top: 1775px;
    left: 0px;
    width: 1920px;
    height: 409px;
    font-size: 16px;
    color: #999;
}

.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}
.parent1 {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    color: #3c3c3c;
    font-family: Roboto;
}

.div6:hover, .b:hover, .b1:hover {
    cursor: pointer;
}