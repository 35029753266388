.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1682px;
}

.icon {
    position: absolute;
    top: 49px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 51px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.p {
    margin-block-start: 0;
    margin-block-end: 10px;
}

.p1 {
    margin: 0;
}

.div1 {
    position: absolute;
    top: 123px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
}

.div2 {
    position: absolute;
    top: 219px;
    left: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
    cursor: pointer;
}

.div3 {
    position: absolute;
    top: 219px;
    left: 79px;
    font-size: 14px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
    cursor: pointer;
}

.div4 {
    position: absolute;
    top: 219px;
    left: 151px;
    font-size: 14px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
    cursor: pointer;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    width: 170px;
    height: 245px;
}

.p2 {
    margin-block-start: 0;
    margin-block-end: 8px;
}

.div6 {
    position: absolute;
    top: 161px;
    left: 37px;
    line-height: 15px;
    font-weight: 800;
}

.div7 {
    position: absolute;
    top: 210px;
    left: 60px;
    font-size: 14px;
    line-height: 15px;
    color: #666;
}

.maskGroupIcon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 130px;
    height: 130px;
    object-fit: cover;
}

.div5 {
    position: absolute;
    top: 1079px;
    left: 20px;
    width: 170px;
    height: 245px;
    text-align: center;
}

.div9 {
    position: absolute;
    top: 161px;
    left: 32px;
    line-height: 15px;
    font-weight: 800;
}

.div10 {
    position: absolute;
    top: 210px;
    left: 51px;
    font-size: 14px;
    line-height: 15px;
    color: #666;
}

.div8 {
    position: absolute;
    top: 1079px;
    left: 200px;
    width: 170px;
    height: 245px;
    text-align: center;
}

.div12 {
    position: absolute;
    top: 161px;
    left: 46px;
    line-height: 15px;
    font-weight: 800;
}

.div13 {
    position: absolute;
    top: 210px;
    left: 26px;
    font-size: 14px;
    line-height: 15px;
    color: #666;
}

.div11 {
    position: absolute;
    top: 549px;
    left: 20px;
    width: 170px;
    height: 245px;
    text-align: center;
}

.div16 {
    position: absolute;
    top: 210px;
    left: 45px;
    font-size: 14px;
    line-height: 15px;
    color: #666;
}

.div14 {
    position: absolute;
    top: 549px;
    left: 200px;
    width: 170px;
    height: 245px;
    text-align: center;
}

.div18 {
    position: absolute;
    top: 161px;
    left: 53px;
    line-height: 15px;
    font-weight: 800;
}

.div17 {
    position: absolute;
    top: 284px;
    left: 20px;
    width: 170px;
    height: 245px;
    text-align: center;
}

.div21 {
    position: absolute;
    top: 161px;
    left: 54px;
    line-height: 15px;
    font-weight: 800;
}

.div22 {
    position: absolute;
    top: 210px;
    left: 37px;
    font-size: 14px;
    line-height: 15px;
    color: #666;
}

.div20 {
    position: absolute;
    top: 814px;
    left: 200px;
    width: 170px;
    height: 245px;
    text-align: center;
}

.div24 {
    position: absolute;
    top: 172px;
    left: 29px;
    line-height: 15px;
    font-weight: 800;
}

.div23 {
    position: absolute;
    top: 1344px;
    left: 200px;
    width: 170px;
    height: 245px;
    text-align: center;
}

.div26 {
    position: absolute;
    top: 1344px;
    left: 20px;
    width: 170px;
    height: 245px;
    text-align: center;
}

.div31 {
    position: absolute;
    top: 210px;
    left: 42px;
    font-size: 14px;
    line-height: 15px;
    color: #666;
}

.div29 {
    position: absolute;
    top: 814px;
    left: 20px;
    width: 170px;
    height: 245px;
    text-align: center;
}

.div33 {
    position: absolute;
    top: 161px;
    left: 39px;
    line-height: 15px;
    font-weight: 800;
}

.div32 {
    position: absolute;
    top: 284px;
    left: 200px;
    width: 170px;
    height: 245px;
    text-align: center;
}

.div35 {
    position: absolute;
    top: 1743px;
    left: 20px;
    font-size: 14px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}

.b {
    position: absolute;
    top: 1778px;
    left: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #999;
}

.rdCenter {
    position: absolute;
    top: 1803px;
    left: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #999;
}

.c304 {
    position: absolute;
    top: 1823px;
    left: 103px;
    font-size: 14px;
    line-height: 15px;
    color: #999;
}

.tel82322771700 {
    position: absolute;
    top: 1848px;
    left: 20px;
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.mailBizvdigmcom {
    position: absolute;
    top: 1873px;
    left: 20px;
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.icon2 {
    position: absolute;
    top: 1691px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}

.icon3 {
    position: absolute;
    top: 1938px;
    left: 326px;
    width: 44px;
    height: 44px;
}

.ellipseDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div36 {
    position: absolute;
    top: 1908px;
    left: 20px;
    width: 24px;
    height: 24px;
}

.child7 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div37 {
    position: absolute;
    top: 1908px;
    left: 54px;
    width: 24px;
    height: 24px;
}

.icon4 {
    position: absolute;
    top: 101px;
    left: 385px;
    width: 0px;
    height: 1879px;
    object-fit: contain;
    opacity: 0.6;
}

.icon5 {
    position: absolute;
    top: 567px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1682px;
    left: 50%;
    transform: translate(-50%);
}

.div {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1682px;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    color: #222;
    font-family: NanumSquare;
}