.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1080px;
}
.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.people1 {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
    cursor: pointer;
}
.recruitmentProcess {
    position: absolute;
    top: 220px;
    left: 155px;
    line-height: 15px;
    color: #666;
    cursor: pointer;
}
.benefits {
    position: absolute;
    top: 220px;
    left: 84px;
    line-height: 15px;
    color: #666;
    cursor: pointer;
}
.vdigmsSoughtAfterAttitudes {
    position: absolute;
    top: 749px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    color: #222;
}
.vdigmIs {
    margin-block-start: 0;
    margin-block-end: 10px;
}
.alwaysOpen {
    margin: 0;
}
.vdigmIsAlwaysContainer {
    position: absolute;
    top: 124px;
    left: 20px; 
    font-size: 18px;
    line-height: 18px;
}
.vdigmInc {
    position: absolute;
    top: 1117px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}
.headOffice {
    font-weight: 500;
    font-family: Roboto;
}
.headOffice10f1012293Bu {
    margin-block-start: 0;
    margin-block-end: 2px;
}
.headOffice10fContainer {
    position: absolute;
    top: 1152px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.rdCenterC304Container {
    position: absolute;
    top: 1194px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.tel {
    font-weight: 500;
}
.tel82322771700 {
    position: absolute;
    top: 1246px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.mailBizvdigmcom {
    position: absolute;
    top: 1271px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.icon2 {
    position: absolute;
    top: 1065px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.groupItem {
    position: absolute;
    top: 1336px;
    left: 326px;
    box-shadow: 0px 0px 8.27px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    background-color: #fff;
    width: 44px;
    height: 44px;
}
.groupInner {
    position: absolute;
    top: 1348px;
    left: 348px;
    width: 0px;
    height: 20px;
    object-fit: contain;
}
.ellipseDiv {
    position: absolute;
    top: 1306px;
    left: 20px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram3288413192017Icon {
    position: absolute;
    top: 1313px;
    left: 25px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.groupChild1 {
    position: absolute;
    top: 1306px;
    left: 54px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram3288413192018Icon {
    position: absolute;
    top: 1311.5px;
    left: 60.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.weAreAlways {
    position: absolute;
    top: 515px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    color: #222;
}
.vdigmIsA {
    margin-block-start: 0;
    margin-block-end: 6px;
}
.vdigmIsAContainer {
    position: absolute;
    top: 550px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}
.weAreSeekingContainer {
    position: absolute;
    top: 906px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 10px;
    background-color: #def0f9;
    width: 350px;
    height: 210px;
}
.talk76478631280Icon {
    position: absolute;
    top: 8px;
    left: 73px;
    width: 204px;
    height: 193px;
    object-fit: cover;
}
.div {
    position: absolute;
    top: 285px;
    left: 20px;
    width: 350px;
    height: 210px;
}
.ellipseIcon {
    position: absolute;
    top: 784px;
    left: 22px;
    width: 102px;
    height: 102px;
}
.groupChild2 {
    position: absolute;
    top: 784px;
    left: 145px;
    width: 102px;
    height: 102px;
}
.groupChild3 {
    position: absolute;
    top: 784px;
    left: 266px;
    width: 102px;
    height: 102px;
}
.sincerity {
    position: absolute;
    top: 828px;
    left: 42px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    color: inherit;
    text-align: center;
    text-decoration: none;
}
.communication {
    position: absolute;
    top: 828px;
    left: 150px;
    letter-spacing: -0.05em;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
}
.challenge {
    position: absolute;
    top: 828px;
    left: 281px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
}
.icon3 {
    position: absolute;
    top: 98px;
    left: 385px;
    width: 0px;
    height: 1280px;
    object-fit: contain;
    opacity: 0.6;
}
.icon4 {
    position: absolute;
    top: 690px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1080px;
    left: 50%;
    transform: translate(-50%);
}
.people {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1080px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #000;
    font-family: Roboto;
}

