.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1020px;
}

.icon {
    position: absolute;
    top: 49px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 51px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.p {
    margin-block-start: 0;
    margin-block-end: 10px;
}

.p1 {
    margin: 0;
}

.div1 {
    position: absolute;
    top: 123px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
}

.p2 {
    margin-block-start: 0;
    margin-block-end: 8px;
}

.div2 {
    position: absolute;
    top: 549px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}

.div3 {
    position: absolute;
    top: 219px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
    cursor: pointer;
}

.div4 {
    position: absolute;
    top: 219px;
    left: 79px;
    line-height: 15px;
    font-weight: 400;
    color: #666;
    cursor: pointer;
}

.div5 {
    position: absolute;
    top: 219px;
    left: 151px;
    line-height: 15px;
    font-weight: 400;
    color: #666;
    cursor: pointer;
}

.div6 {
    position: absolute;
    top: 514px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
}

.div7 {
    position: absolute;
    top: 710px;
    left: 20px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
}

.div8 {
    position: absolute;
    top: 866px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 10px;
    background-color: #def0f9;
    width: 350px;
    height: 210px;
}

.talk76478631280Icon {
    position: absolute;
    top: 8px;
    left: 73px;
    width: 204px;
    height: 193px;
    object-fit: cover;
}

.div9 {
    position: absolute;
    top: 284px;
    left: 20px;
    width: 350px;
    height: 210px;
}

.div11 {
    position: absolute;
    top: 43px;
    left: 34.86px;
    line-height: 15px;
    font-weight: 800;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
}

.div10 {
    position: absolute;
    top: 746px;
    left: 22px;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 16px;
}

.div13 {
    position: absolute;
    top: 43px;
    left: 34.58px;
    line-height: 15px;
    font-weight: 800;
}

.div12 {
    position: absolute;
    top: 746px;
    left: 145px;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 16px;
}

.div15 {
    position: absolute;
    top: 43px;
    left: 34px;
    line-height: 15px;
    font-weight: 800;
}

.div14 {
    position: absolute;
    top: 746px;
    left: 268px;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 16px;
}

.div16 {
    position: absolute;
    top: 1081px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}

.b {
    position: absolute;
    top: 1116px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.rdCenter {
    position: absolute;
    top: 1141px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.c304 {
    position: absolute;
    top: 1161px;
    left: 103px;
    line-height: 15px;
    color: #999;
}

.tel82322771700 {
    position: absolute;
    top: 1186px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.mailBizvdigmcom {
    position: absolute;
    top: 1211px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.icon2 {
    position: absolute;
    top: 1029px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}

.icon3 {
    position: absolute;
    top: 1276px;
    left: 326px;
    width: 44px;
    height: 44px;
}

.ellipseDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div17 {
    position: absolute;
    top: 1246px;
    left: 20px;
    width: 24px;
    height: 24px;
}

.child1 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div18 {
    position: absolute;
    top: 1246px;
    left: 54px;
    width: 24px;
    height: 24px;
}

.icon4 {
    position: absolute;
    top: 101px;
    left: 385px;
    width: 0px;
    height: 1217px;
    object-fit: contain;
    opacity: 0.6;
}

.icon5 {
    position: absolute;
    top: 567px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1020px;
    left: 50%;
    transform: translate(-50%);
}

.div {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1020px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #222;
    font-family: NanumSquare;
}