/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .tih {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2326px;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .div {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
    }
    .p {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 2326px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 2326px;
    }
    .tihtomorrowInHere {
        position: absolute;
        top: 456px;
        left: 370px;
        font-family: Roboto;
    }
    .ellipseDiv {
        position: absolute;
        top: 450px;
        left: 570px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .maskGroupIcon {
        position: absolute;
        top: 577px;
        left: 890px;
        width: 660px;
        height: 400px;
        object-fit: cover;
    }
    .b4 {
        position: absolute;
        top: 1227px;
        left: 370px;
        font-size: 28px;
        font-family: NanumSquare;
    }
    .ai {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    .aiContainer {
        position: absolute;
        top: 83px;
        left: 0px;
        font-size: 18px;
        font-family: NanumSquare;
        color: #666;
    }
    .tomorrowInHereParent {
        position: absolute;
        top: 616px;
        left: 370px;
        width: 411px;
        height: 253px;
        font-size: 28px;
        font-family: Roboto;
    }
    .vectorIcon {
        position: absolute;
        top: 4px;
        left: 64px;
        width: 10px;
        height: 10px;
    }
    .group {
        position: absolute;
        top: 919px;
        left: 370px;
        width: 74px;
        height: 18px;
        font-size: 16px;
        color: #999;
    }
    .tih2 {
        position: absolute;
        top: 1367px;
        left: 1109px;
        font-size: 28px;
        font-weight: 800;
    }
    .tihAiNpc {
        position: absolute;
        top: 1935px;
        left: 1109px;
        font-size: 28px;
        letter-spacing: -0.01em;
        font-weight: 800;
    }
    .ai1 {
        position: absolute;
        top: 1642px;
        left: 373px;
        font-size: 28px;
        letter-spacing: -0.01em;
        font-weight: 800;
        text-align: right;
    }
    .kContainer {
        position: absolute;
        top: 1431px;
        left: 1109px;
        color: #666;
    }
    .tihContainer {
        position: absolute;
        top: 1999px;
        left: 1109px;
        color: #666;
    }
    .personaAiContainer {
        position: absolute;
        top: 1706px;
        left: 442px;
        color: #666;
        text-align: right;
    }
    .lineIcon {
        position: absolute;
        top: 1411px;
        left: 1048px;
        max-height: 100%;
        width: 500px;
        object-fit: contain;
    }
    .groupChild1 {
        position: absolute;
        top: 1979px;
        left: 1048px;
        max-height: 100%;
        width: 500px;
        object-fit: contain;
    }
    .groupChild2 {
        position: absolute;
        top: 1686px;
        left: 372px;
        max-height: 100%;
        width: 488px;
        object-fit: contain;
    }
    .groupIcon {
        position: absolute;
        top: 1310px;
        left: 858.62px;
        width: 200px;
        height: 766px;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .tih {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 1860px;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #222;
        font-family: NanumSquare;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .div {
        position: absolute;
        top: 144px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
    }
    .p {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 1860px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1280px;
        height: 1860px;
    }
    .tihtomorrowInHere {
        position: absolute;
        top: 364.8px;
        left: 20%;
        font-family: Roboto;
    }
    .ellipseDiv {
        position: absolute;
        top: 359px;
        left: 445px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .maskGroupIcon {
        position: absolute;
        top: 461.6px;
        left: 596.3px;
        width: 442.2px;
        height: 268px;
        object-fit: cover;
    }
    .groupContainer {
        position: absolute;
        top: 470px;
        left: 247.9px;
        width: 442px;
        height: 335px;
        font-size: 23px;
    }
    .b4 {
        position: absolute;
        top: 981.6px;
        left: 247.9px;
        font-size: 23px;
        font-family: NanumSquare;
    }
    .ai {
        margin-block-start: 0;
        margin-block-end: 10px;
    }
    .aiContainer {
        position: absolute;
        top: 55.61px;
        left: 0px;
        font-size: 15px;
        font-family: NanumSquare;
        color: #666;
    }
    .tomorrowInHereParent {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 442px;
        height: 266px;
        font-size: 23px;
        font-family: Roboto;
    }
    .vectorIcon {
        position: absolute;
        top: 4px;
        left: 64px;
        width: 10px;
        height: 10px;
    }
    .group {
        position: absolute;
        top: 919px;
        left: 370px;
        width: 74px;
        height: 18px;
        font-size: 16px;
        color: #999;
    }
    .tih2 {
        position: absolute;
        top: 1077px;
        left: 719px;
        font-size: 23px;
        font-weight: 800;
    }
    .tihAiNpc {
        position: absolute;
        top: 1457px;
        left: 719px;
        font-size: 23px;
        letter-spacing: -0.01em;
        font-weight: 800;
    }
    .ai1 {
        position: absolute;
        top: 1272px;
        left: 223px;
        font-size: 23px;
        letter-spacing: -0.01em;
        font-weight: 800;
        text-align: right;
    }
    .kContainer {
        position: absolute;
        top: 1131px;
        left: 719px;
        color: #666;
        font-size: 15px;
    }
    .tihContainer {
        position: absolute;
        top: 1509px;
        left: 719px;
        color: #666;
        font-size: 15px;
    }
    .personaAiContainer {
        position: absolute;
        top: 1316px;
        left: 273px;
        color: #666;
        text-align: right;
        font-size: 15px;
    }
    .lineIcon {
        position: absolute;
        /* top: 1411px; */
        top: 1112.8px;

        /* left: 1048px; */
        left: 702.16px;

        max-height: 100%;
        width: 390.4px;
        object-fit: contain;
    }
    .groupChild1 {
        position: absolute;
        /* top: 1979px; */
        top: 1493.2px;
        
        /* left: 1048px; */
        left: 702.16px;

        max-height: 100%;
        width: 390.4px;
        object-fit: contain;
    }
    .groupChild2 {
        position: absolute;
        /* top: 1686px; */
        top: 1304.8px;

        /* left: 372px; */
        left: 249.24px;

        max-height: 100%;
        width: 390.4px;
        object-fit: contain;
    }
    .groupIcon {
        position: absolute;
        /* top: 1310px; */
        top: 1048px;

        /* left: 858.63px; */
        left: 575.28px;

        width: 134px;
        height: 513.22px;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}

.b {
    position: absolute;
    top: 0px;
    left: 0px;
}
.b1 {
    position: absolute;
    top: 0px;
    left: 266px;
}
.tih1 {
    position: absolute;
    top: 0px;
    left: 516px;
    font-family: Roboto;
}
.b2 {
    position: absolute;
    top: 0px;
    left: 745px;
}
.contact {
    position: absolute;
    top: 0px;
    left: 978px;
    font-weight: 500;
    font-family: Roboto;
}
.parent {
    position: absolute;
    top: 28px;
    left: 439px;
    width: 1042px;
    height: 21px;
}
.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}

.groupItem {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}
.groupInner {
    position: absolute;
    top: 0px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.rectangleDiv {
    position: absolute;
    top: 94px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.div1 {
    position: absolute;
    top: 204px;
    left: 130px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.b3 {
    position: absolute;
    top: 277px;
    left: 130px;
}
.rdCenter {
    position: absolute;
    top: 315px;
    left: 130px;
}
.tel82322771700 {
    position: absolute;
    top: 353px;
    left: 130px;
}
.mailBizvdigmcom {
    position: absolute;
    top: 391px;
    left: 130px;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div2 {
    position: absolute;
    top: 204px;
    left: 1690px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div3 {
    position: absolute;
    top: 204px;
    left: 1750px;
    width: 40px;
    height: 40px;
}
.groupParent {
    position: absolute;
    top: 2232px;
    left: 0px;
    width: 1920px;
    height: 409px;
    font-size: 16px;
    color: #999;
}


.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #666;
}
.container {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    color: #3c3c3c;
    font-family: Roboto;
}
.deleteLinkStyle {
    text-decoration: none;
    color: inherit;
}
.deleteLinkStyle:hover {
    text-decoration: none;
    color: inherit;
}
