.div {
    position: absolute;
    top: 750px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
}

.manager {
    position: absolute;
    top: 1275px;
    left: 50px;
    z-index: 10;
}

.div1Ko {
    position: absolute;
    top: 1006px;
    left: 20px;
    font-size: 16px; 
    line-height: 15px;
    font-weight: 800;
    color: #222;
    font-family: NanumSquare;
}

.div1En {
    position: absolute;
    top: 1004px;
    left: 20px;
    color: #222;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 93.75% */
}

.div2 {
    position: absolute;
    /* top: 1041px; */
    top: 1031px;

    left: 0px;
    width: 100%;
    height: 195px;

}

.bKo {
    position: absolute;
    top: 1006px;
    left: 326px;
    font-size: 12px;
    line-height: 15px;
    color: #666666;
    font-family: NanumSquare;
    font-weight: 700;
}

.bEn {
    position: absolute;
    top: 1004px;
    /* left: 326px; */
    left: 336px;

    font-size: 12px;
    line-height: 15px;
    color: #666666;
    font-family: Roboto;
    font-weight: 500;
}

.swiper1 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* object-fit: cover; */
    display: flex;
    /* align-items: center; */
}

.swiperMain {
    /* width: 100%;
    height: 100%; */
    /* position: absolute; */
    /* left: 0px; */
    /* top: 0px; */
    width: 380px;
    height: 275px;
    border-radius: 10px;
    /* object-fit: cover; */
    display: flex;
    /* align-items: center; */
}

.titleKo {
    font-family: NanumSquare;
    font-weight: 700;
    font-size: 12px;
}

.titleEn {
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
}

.youtubeLink {
    text-decoration: none;
    color: inherit;
}