/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .icon2 {
        position: absolute;
        left: 0px;
        top: 80px;
        /* width: 1920px; */
        width: 100%;
    
        height: 675px;
        object-fit: cover;
    }
    .swiperMain {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        align-items: center;
    }
    
    .ai2 {
        margin-block-start: 0;
        margin-block-end: 12px;
    }
    .p {
        margin: 0;
    }
    
    
    .aiContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 56px;
        line-height: 88px;
        font-weight: 800;
        color: #fff;
        z-index: 10;
        font-family: NanumSquare;
        /* font-stretch:wider; */
        /* letter-spacing: 0.05em; */
    }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .icon2 {
        position: absolute;
        left: 0px;
        top: 70px;
        /* width: 1920px; */
        width: 100%;
    
        /* height: 675px; */
        height: 540px;

        object-fit: cover;
    }
    
    /* .main {
        position: absolute;
        width: 100%;
        height: 675px;
        left: 0px;
        top: 0px;
    } */
    
    .swiperMain {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        align-items: center;
    }
    
    .ai2 {
        margin-block-start: 0;
        margin-block-end: 12px;
    }
    .p {
        margin: 0;
    }
    
    .aiContainer {
        position: absolute;
        top: 144px;
        left: 104px;
        font-size: 45px;
        line-height: 88px;
        font-weight: 800;
        color: #fff;
        z-index: 10;
        font-family: Roboto;
        /* font-stretch:wider; */
        /* letter-spacing: 0.05em; */
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) { /*스타일입력*/}



/* .main {
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 675px;
    display: flex;
    justify-content: center;
} */



/* @media (max-width: 1920px) {
    .icon2 {
        width: 100%;
    }
} */








.groupChild1 {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 1920px;
    height: 675px;
    object-fit: cover;
}