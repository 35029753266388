.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 704px;
}

.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.aiAvatar {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    color: #666;
}

.dVirtualStage1 {
    position: absolute;
    top: 220px;
    left: 180px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
}

.deepReal {
    position: absolute;
    top: 220px;
    left: 97px;
    line-height: 15px;
    color: #666;
}

.p {
    margin-block-start: 0;
    margin-block-end: 10px;
}

.p1 {
    margin: 0;
}

.div {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
    font-family: NanumSquare;
    color: #222;
}

.virtualStageAi {
    position: absolute;
    top: 515px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    color: #222;
}

.ai3d {
    margin-block-start: 0;
    margin-block-end: 8px;
}

.ai3dContainer {
    position: absolute;
    top: 550px;
    left: 20px;
    line-height: 15px;
    font-family: NanumSquare;
    color: #3c3c3c;
}

.icon2 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 900px;
    object-fit: contain;
    opacity: 0.6;
}

.div1 {
    position: absolute;
    top: 765px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    font-family: NanumSquare;
    color: #666;
}

.b {
    position: absolute;
    top: 800px;
    left: 20px;
    line-height: 15px;
    font-family: NanumSquare;
}

.rdCenter {
    position: absolute;
    top: 825px;
    left: 20px;
    line-height: 15px;
    font-family: NanumSquare;
}

.c304 {
    position: absolute;
    top: 845px;
    left: 103px;
    line-height: 15px;
    font-family: NanumSquare;
}

.tel82322771700 {
    position: absolute;
    top: 870px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
}

.mailBizvdigmcom {
    position: absolute;
    top: 895px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
}

.icon3 {
    position: absolute;
    top: 713px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}

.icon4 {
    position: absolute;
    top: 960px;
    left: 326px;
    width: 44px;
    height: 44px;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div2 {
    position: absolute;
    top: 930px;
    left: 20px;
    width: 24px;
    height: 24px;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div3 {
    position: absolute;
    top: 930px;
    left: 54px;
    width: 24px;
    height: 24px;
}

.stageImageIcon {
    position: absolute;
    top: 285px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}

.icon5 {
    position: absolute;
    top: 567px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 704px;
    left: 50%;
    transform: translate(-50%);
}

.dVirtualStage {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 704px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #999;
    font-family: Roboto;
}