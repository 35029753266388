/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .aiAvatar {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2527px;
        overflow: hidden;
        text-align: left;
        font-size: 18px;
        color: #222;
        /* font-family: NanumSquare; */
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 400px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .vdigmsTechnologySpearheadinContainer {
        position: absolute;
        top: 180px;
        left: 130px;
        font-size: 48px;
        line-height: 88px;
        font-weight: 800;
    }
    .vdigmsTechnologySpearheadin {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 2527px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1920px;
        height: 2527px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 456px;
        width: 100%;
        z-index: 2;
    }
    .aiAvatar1 {
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 600;
    }
    .deepReal {
        position: absolute;
        top: 0px;
        left: 175px;
        font-weight: 600;
        color: #999;
    }
    .dVirtualStage {
        position: absolute;
        top: 0px;
        left: 356px;
        font-weight: 600;
        color: #999;
    }
    .ellipseDiv {
        position: absolute;
        top: -4px;
        left: 75px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 8px;
        height: 8px;
    }
    .maskGroupIcon {
        position: absolute;
        top: 1227px;
        left: 370px;
        width: 660px;
        height: 400px;
        object-fit: cover;
    }
    .maskGroupIcon1 {
        position: absolute;
        top: 577px;
        left: 890px;
        width: 660px;
        height: 400px;
        object-fit: cover;
    }
    .maskGroupIcon2 {
        position: absolute;
        top: 1877px;
        left: 890px;
        width: 660px;
        height: 400px;
        object-fit: cover;
    }
    .generativeAiAvatar {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .createYour3d {
        margin-block-start: 0;
        margin-block-end: 6px;
    }
    .createYour3dContainer {
        position: absolute;
        top: 83px;
        left: 0px;
        font-size: 18px;
        color: #666;
    }
    .generativeAiAvatarParent {
        position: absolute;
        top: 684px;
        left: 370px;
        /* width: 392px; */
        width: 450px;
    
        height: 185px;
        font-size: 28px;
    }
    .responsiveAiAvatarParent {
        position: absolute;
        top: 1335px;
        left: 1130px;
        /* width: 385px; */
        width: 450px;
    
        height: 185px;
        font-size: 28px;
    }
    .aiAvatarNpcParent {
        position: absolute;
        top: 1985px;
        left: 370px;
        /* width: 415px; */
        width: 450px;
    
        height: 185px;
        font-size: 28px;
    }
}
@media (max-width: 1919px) {
    .rectangleParent1 {
        width: 100%;
    }
    
}
/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .aiAvatar {
        width: 100%;
        position: relative;
        background-color: #fff;
        height: 2021px;
        overflow: hidden;
        text-align: left;
        font-size: 15px;
        color: #222;
        /* font-family: NanumSquare; */
        font-family: Roboto;
    }
    .rectangleParent1 {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 300px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
    }
    .vdigmsTechnologySpearheadinContainer {
        position: absolute;
        top: 144px;
        left: 3%;
        font-size: 39px;
        line-height: 71px;
        font-weight: 800;
    }
    .vdigmsTechnologySpearheadin {
        margin: 0;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1280px;
        height: 2021px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .groupChild {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 1280px;
        height: 2021px;
    }
    .category {
        position: absolute;
        left: 19.27%;
        top: 364.8px;
        width: 100%;
        z-index: 2;
        font-size: 15px;
    }
    .aiAvatar1 {
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 600;
    }
    .deepReal {
        position: absolute;
        top: 0px;
        left: 140px;
        font-weight: 600;
        color: #999;
    }
    .dVirtualStage {
        position: absolute;
        top: 0px;
        left: 284.8px;
        font-weight: 600;
        color: #999;
    }
    .ellipseDiv {
        position: absolute;
        top: -4px;
        left: 60px;
        border-radius: 50%;
        background-color: #ff038d;
        width: 7px;
        height: 7px;
    }
    .maskGroupIcon {
        position: absolute;
        /* top: 1227px; */
        top: 981.6px;

        /* left: 370px; */
        left: 247.9px;

        /* width: 660px; */
        width: 442.2px;

        /* height: 400px; */
        height: 268px;

        object-fit: cover;
    }
    .maskGroupIcon1 {
        position: absolute;
        /* top: 577px; */
        top: 461.6px;

        /* left: 890px; */
        left: 596.3px;

        /* width: 660px; */
        width: 442.2px;

        /* height: 400px; */
        height: 268px;
        object-fit: cover;
    }
    .maskGroupIcon2 {
        position: absolute;
        /* top: 1877px; */
        top: 1501.6px;

        /* left: 890px; */
        left: 596.3px;

        /* width: 660px; */
        width: 442.2px;

        /* height: 400px; */
        height: 268px;
        object-fit: cover;
    }
    .generativeAiAvatar {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .createYour3d {
        margin-block-start: 0;
        margin-block-end: 4px;
    }
    .createYour3dContainer {
        position: absolute;
        /* top: 83px; */
        /* top: 66.4px; */
        top: 55.61px;

        left: 0px;
        font-size: 15px;
        color: #666;
    }
    .generativeAiAvatarParent {
        position: absolute;
        /* top: 684px; */
        top: 530px;

        /* left: 370px; */
        left: 247.9px;

        /* width: 392px; */
        width: 450px;
    
        height: 185px;
        font-size: 23px;
    }
    .responsiveAiAvatarParent {
        position: absolute;
        /* top: 1335px; */
        top: 1051px;

        /* left: 1130px; */
        /* left: 757.1px; */
        left: 720px;

        /* width: 385px; */
        width: 450px;
    
        height: 185px;
        font-size: 23px;
    }
    .aiAvatarNpcParent {
        position: absolute;
        /* top: 1985px; */
        top: 1571px;

        /* left: 370px; */
        left: 247.9px;

        /* width: 415px; */
        width: 450px;
    
        height: 185px;
        font-size: 23px;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}


.icon {
    position: absolute;
    top: 25px;
    left: 30px;
    width: 108px;
    height: 28px;
    object-fit: cover;
}


.groupItem {
    position: absolute;
    top: 78px;
    left: 0px;
    background-color: #d9d9d9;
    width: 1920px;
    height: 2px;
}
.groupInner {
    position: absolute;
    top: 0px;
    left: 1746px;
    width: 44px;
    height: 44px;
}
.rectangleDiv {
    position: absolute;
    top: 94px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.vdigmInc {
    position: absolute;
    top: 204px;
    left: 130px;
    font-size: 20px;
    font-weight: 800;
    color: #666;
}
.headOffice {
    position: absolute;
    top: 277px;
    left: 130px;
}
.f1012293 {
    position: absolute;
    top: 277px;
    left: 240px;
    font-weight: 500;
}
.c304SeoulAi {
    position: absolute;
    top: 316px;
    left: 240px;
    font-weight: 500;
}
.rdCenter {
    position: absolute;
    top: 316px;
    left: 130px;
}
.tel82322771700 {
    position: absolute;
    top: 355px;
    left: 130px;
    font-family: NanumSquare;
}
.mailBizvdigmcom {
    position: absolute;
    top: 393px;
    left: 130px;
    font-family: NanumSquare;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 40px;
    height: 40px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 11.67px;
    left: 8.33px;
    width: 23.3px;
    height: 16.7px;
    object-fit: cover;
}
.div {
    position: absolute;
    top: 204px;
    left: 1690px;
    width: 40px;
    height: 40px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 40px;
    height: 40px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 9.17px;
    left: 10.83px;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.div1 {
    position: absolute;
    top: 204px;
    left: 1750px;
    width: 40px;
    height: 40px;
}
.groupParent {
    position: absolute;
    top: 2433px;
    left: 0px;
    width: 1920px;
    height: 411px;
    font-size: 16px;
    color: #999;
}


.aboutUs {
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 500;
}
.solution {
    position: absolute;
    top: 0px;
    left: 274px;
}
.tih {
    position: absolute;
    top: 0px;
    left: 541px;
    font-weight: 500;
}
.career {
    position: absolute;
    top: 0px;
    left: 770px;
    font-weight: 500;
}
.contact {
    position: absolute;
    top: 0px;
    left: 1024px;
    font-weight: 500;
}
.aboutUsParent {
    position: absolute;
    top: 28px;
    left: 416px;
    width: 1088px;
    height: 21px;
}
.icon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
}
.kor {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 15px;
    font-weight: 600;
}
.eng {
    position: absolute;
    top: 2px;
    left: 93px;
    line-height: 15px;
    font-weight: 600;
    color: #3c3c3c;
}
.parent {
    position: absolute;
    top: 28px;
    left: 1761px;
    width: 129px;
    height: 18px;
    color: #666;
}

.aiAvatar1:hover, .deepReal:hover, .dVirtualStage:hover {
    cursor: pointer;
}

