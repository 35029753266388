.icon6 {
    position: fixed;
    /* top: 1614px; */
    /* top: 75.71%;   */
    top: 88%;  
    /* left: 326px; */
    left: 83.59%;
    width: 44px;
    height: 44px;
    z-index: 7;
}

/* body::-webkit-scrollbar {
    overflow-y: none;
} */
/* body::-webkit-scrollbar {
    display: none;
  }
  
body:hover::-webkit-scrollbar {
    display: auto;
  } */

  /* .body {
    overflow: hidden;
  }

  .body.with-scroll {
    overflow: auto;
  } */