.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1156px;
}
.rdCenter {
    position: absolute;
    top: 654px;
    left: 114px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
    z-index: 5;
}

.rdCenterBold {
    position: absolute;
    top: 654px;
    left: 114px;
    line-height: 15px;
    color: #222;
    font-weight: 600;
    z-index: 5;
}

.headOffice {
    position: absolute;
    top: 654px;
    left: 20px;
    line-height: 15px;
    font-weight: 400;
    color: #666;
    z-index: 5;
}

.headOfficeBold {
    position: absolute;
    top: 654px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
    color: #222;
    z-index: 5;
}

.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.businessContact {
    position: absolute;
    top: 138px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    color: #222;
}
.icon2 {
    position: absolute;
    top: 219px;
    left: 94px;
    width: 202px;
    height: 52px;
    object-fit: cover;
}
.groupItem {
    position: absolute;
    top: 689px;
    left: 20px;
    background-color: #d9d9d9;
    width: 350px;
    height: 350px;
}
.tel {
    position: absolute;
    top: 357px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
}
.eMail {
    position: absolute;
    top: 392px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
}
.headOfficeAdress {
    position: absolute;
    top: 427px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
}
.rdCenterAdress {
    position: absolute;
    top: 508px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
    display: inline-block;
    width: 130px;
}
.location {
    position: absolute;
    top: 619px;
    left: 20px;
    line-height: 15px;
    font-weight: 600;
    display: inline-block;
    width: 120px;
}
.div {
    position: absolute;
    top: 357px;
    left: 64px;
    line-height: 15px;
}

.div2 {
    position: absolute;
    top: 689px;
    left: 20px;
    line-height: 15px;
}

.republicOfKorea {
    margin: 0;
}
.f1012293Container {
    position: absolute;
    top: 452px;
    left: 20px;
    line-height: 15px;
    display: inline-block;
    width: 342px;
}
.c304SeoulAi {
    margin-block-start: 0;
    margin-block-end: 6px;
}
.c304SeoulAiContainer {
    position: absolute;
    top: 533px;
    left: 20px;
    line-height: 15px;
}
.bizvdigmcom {
    position: absolute;
    top: 392px;
    left: 84px;
    line-height: 15px;
}
.vdigmInc {
    position: absolute;
    top: 321px;
    left: 20px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    font-family: NanumSquare;
    color: #222;
}
.vdigmInc1 {
    position: absolute;
    top: 1193px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}
.headOffice1 {
    font-weight: 500;
    font-family: Roboto;
}
.headOffice10f1012293Bu {
    margin-block-start: 0;
    margin-block-end: 2px;
}
.headOffice10fContainer {
    position: absolute;
    top: 1228px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.rdCenterC304Container {
    position: absolute;
    top: 1270px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.tel82322771700 {
    position: absolute;
    top: 1322px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.mail {
    font-weight: 500;
}
.mailBizvdigmcom {
    position: absolute;
    top: 1347px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.icon3 {
    position: absolute;
    top: 1141px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.groupInner {
    position: absolute;
    top: 1412px;
    left: 326px;
    box-shadow: 0px 0px 8.27px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    background-color: #fff;
    width: 44px;
    height: 44px;
}
.lineIcon {
    position: absolute;
    top: 1424px;
    left: 348px;
    width: 0px;
    height: 20px;
    object-fit: contain;
}
.ellipseDiv {
    position: absolute;
    top: 1382px;
    left: 20px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram3288413192021Icon {
    position: absolute;
    top: 1389px;
    left: 25px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.groupChild1 {
    position: absolute;
    top: 1382px;
    left: 54px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram3288413192022Icon {
    position: absolute;
    top: 1387.5px;
    left: 60.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.icon4 {
    position: absolute;
    top: 98px;
    left: 385px;
    width: 0px;
    height: 1356px;
    object-fit: contain;
    opacity: 0.6;
}
.icon5 {
    position: absolute;
    top: 690px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1156px;
    left: 50%;
    transform: translate(-50%);
}
.contact {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1156px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #3c3c3c;
    font-family: Roboto;
}

