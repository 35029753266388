.icon6 {
    position: fixed;
    /* top: 1614px; */
    /* top: 75.71%;   */
    top: 88%;  
    /* left: 326px; */
    left: 88.8%;
    width: 44px;
    height: 44px;
    z-index: 2;
}

.groupChild5 {
    position: fixed;
    top: 88%;
    left: 88.8%;
    width: 44px;
    height: 44px;
    z-index: 7;
}

.groupChild5:hover {
    cursor: pointer;
}