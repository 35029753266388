/* .groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1024px;
}

.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.categoryButton {
    position: relative;
    margin-block-start: 0;
    margin-block-end: 8px;
}

.div1 {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
}

.div2 {
    position: absolute;
    top: 220px;
    left: 162px;
    line-height: 15px;
    color: #666;
}

.div3 {
    position: absolute;
    top: 220px;
    left: 97px;
    line-height: 15px;
    color: #666;
}

.bi {
    position: absolute;
    top: 285px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
}

.slogan {
    position: absolute;
    top: 716px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    font-family: Roboto;
}

.div4 {
    position: absolute;
    top: 220px;
    left: 299px;
    line-height: 15px;
    color: #666;
}

.ai {
    margin-block-start: 0;
    margin-block-end: 10px;
}

.p {
    margin-block-start: 0;
    margin-block-end: 8px;
}

.aiContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
}

.visualAiVirtual {
    margin-block-start: 0;
    margin-block-end: 8px;
    overflow-wrap: break-word;
}

.visualAiVirtualContainer {
    position: absolute;
    top: 320px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
    margin-bottom: 20px;
}

.biLogo {
    position: relative;
    margin-top: 50px;
    left: 0;
    top: 50;
}

.biIcon {
    position: absolute;
    top: 424px;
    left: 20px;
    width: 350px;
    height: 191px;
    opacity: 0.2;
}

.icon2 {
    position: absolute;
    top: 751px;
    left: 20px;
    width: 350px;
    height: 120px;
    opacity: 0.2;
}

.biIcon1 {
    position: absolute;
    top: 499px;
    left: 50px;
    width: 147px;
    height: 40px;
    object-fit: cover;
}

.biIcon2 {
    position: absolute;
    top: 474px;
    left: 259px;
    width: 81px;
    height: 90px;
    object-fit: cover;
}

.div5 {
    position: absolute;
    top: 1026px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}

.b {
    position: absolute;
    top: 1061px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.rdCenter {
    position: absolute;
    top: 1086px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.c304 {
    position: absolute;
    top: 1106px;
    left: 103px;
    line-height: 15px;
    color: #999;
}

.tel82322771700 {
    position: absolute;
    top: 1131px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.mailBizvdigmcom {
    position: absolute;
    top: 1156px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.icon3 {
    position: absolute;
    top: 974px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}

.icon4 {
    position: absolute;
    top: 1221px;
    left: 326px;
    width: 44px;
    height: 44px;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div6 {
    position: absolute;
    top: 1191px;
    left: 20px;
    width: 24px;
    height: 24px;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div7 {
    position: absolute;
    top: 1191px;
    left: 54px;
    width: 24px;
    height: 24px;
}

.inner {
    position: absolute;
    top: 3px;
    left: 3px;
    background-color: #fff502;
    width: 250px;
    height: 20px;
    opacity: 0.5;
}

.inner1 {
    position: absolute;
    top: -6px;
    left: -20px;
    background-color: #fff502;
    width: 230px;
    height: 20px;
    opacity: 0.5;
}

.inner2 {
    position: absolute;
    top: 23px;
    left: -12px;
    background-color: #fff502;
    width: 280px;
    height: 20px;
    opacity: 0.5;
}

.div91 {
    position: absolute;
    top: -12px;
    left: -30px;
    line-height: 15px;
    font-weight: 800;
    font-size: 13px;
    font-family: Roboto;
}

.div92 {
    position: absolute;
    top: 18px;
    left: -19.5px;
    line-height: 15px;
    font-weight: 800;
    font-size: 13px;
    font-family: Roboto;
}

.div9 {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 15px;
    font-weight: 800;
}

.div8 {
    position: absolute;
    top: 801px;
    left: 63px;
    width: 253px;
    height: 23px;
    font-size: 18px;
}

.icon5 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 1080px;
    object-fit: contain;
    opacity: 0.6;
}

.icon6 {
    position: absolute;
    top: 816px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 390px;
    height: 1024px;
}

.div {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1024px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #222;
    font-family: NanumSquare;
} */

.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1024px;
}
.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}
.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}
.aboutUs1 {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    font-weight: 700;
    cursor: pointer;
}
.organizationChart {
    position: absolute;
    top: 220px;
    left: 162px;
    line-height: 15px;
    color: #666;
    cursor: pointer;
}
.history {
    position: absolute;
    top: 220px;
    left: 97px;
    line-height: 15px;
    color: #666;
    cursor: pointer;
}
.vdigmBi {
    position: absolute;
    top: 285px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
}
.slogan {
    position: absolute;
    top: 751px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 500;
    color: #222;
}
.vision {
    position: absolute;
    top: 220px;
    left: 299px;
    line-height: 15px;
    color: #666;
    cursor: pointer;
}
.shapingTheFuture {
    margin-block-start: 0;
    margin-block-end: 10px;
}
.experiencesWithGenerative {
    margin: 0;
}
.shapingTheFutureContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    color: #000;
    font-weight: 700;
}
.theHexagonRepresents {
    margin-block-start: 0;
    margin-block-end: 6px;
}
.theHexagonRepresentsContainer {
    position: absolute;
    top: 320px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}
.icon2 {
    position: absolute;
    top: 786px;
    left: 20px;
    width: 350px;
    height: 120px;
    opacity: 1;
}
.groupItem {
    position: absolute;
    top: 460px;
    left: 20px;
    width: 350px;
    height: 191px;
}
.vdigmInc {
    position: absolute;
    top: 1061px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}
.headOffice {
    font-weight: 500;
    font-family: Roboto;
}
.headOffice10f1012293Bu {
    margin-block-start: 0;
    margin-block-end: 2px;
}
.headOffice10fContainer {
    position: absolute;
    top: 1096px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.rdCenterC304Container {
    position: absolute;
    top: 1138px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.tel {
    font-weight: 500;
}
.tel82322771700 {
    position: absolute;
    top: 1190px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.mailBizvdigmcom {
    position: absolute;
    top: 1215px;
    left: 20px;
    line-height: 15px;
    color: #999;
}
.icon3 {
    position: absolute;
    top: 1009px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}
.icon4 {
    position: absolute;
    top: 1280px;
    left: 326px;
    width: 44px;
    height: 44px;
}
.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}
.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}
.div {
    position: absolute;
    top: 1250px;
    left: 20px;
    width: 24px;
    height: 24px;
}
.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}
.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}
.div1 {
    position: absolute;
    top: 1250px;
    left: 54px;
    width: 24px;
    height: 24px;
}
.inner {
    position: absolute;
    top: 4px;
    left: 10px;
    background-color: #fff502;
    width: 240px;
    height: 16px;
    opacity: 0.5;
}
.rectangleDiv {
    position: absolute;
    top: 28px;
    left: 29px;
    background-color: #fff502;
    width: 291px;
    height: 16px;
    opacity: 0.5;
}

.sloganArticle {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.stepIntoThe {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 15px;
    font-weight: 800;
}
.whereYourImagination {
    position: absolute;
    top: 23px;
    left: 18px;
    line-height: 15px;
    font-weight: 800;
}
.div2 {
    position: absolute;
    top: 824px;
    left: 35px;
    width: 320px;
    height: 44px;
    font-size: 16px;
}
.icon5 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 1220px;
    object-fit: contain;
    opacity: 0.6;
}
.icon6 {
    position: absolute;
    top: 816px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1024px;
    left: 50%;
    transform: translate(-50%);
}
.aboutUs {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1024px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #222;
    font-family: Roboto;
    font-weight: 400;
}