.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 1920px;
    height: 1080px;
}
.div {
    position: absolute;
    top: 667px;
    left: 780px;
    border-radius: 10px;
    background-color: #eee;
    width: 360px;
    height: 50px;
}
.div1 {
    position: absolute;
    top: 527px;
    left: 780px;
    border-radius: 10px;
    background-color: #eee;
    width: 360px;
    height: 50px;
}
.adminId {
    position: absolute;
    top: 542px;
    left: 800px;
}
.vdigmBoss {
    position: absolute;
    /* top: 542px; */
    /* left: 800px; */
    top: 527px;
    left: 780px;
    font-size: 16px;
    font-weight: 500;
    color: #222;
    border-radius: 10px;
    background-color: #eee;
    border: none;
    width: 358px;
    height: 48px;
}

.div2 {
    position: absolute;
    top: 597px;
    left: 780px;
    border-radius: 10px;
    background-color: #eee;
    width: 360px;
    height: 50px;
}
.adminPassword {
    position: absolute;
    top: 612px;
    left: 800px;
}
.login {
    position: absolute;
    top: 680px;
    left: 935px;
    font-size: 20px;
    font-weight: 600;
}
.adminLogin {
    position: absolute;
    top: 357px;
    left: 858px;
    font-size: 36px;
    color: #3c3c3c;
}
.icon {
    position: absolute;
    top: 311px;
    left: 909px;
    width: 102px;
    height: 26px;
    object-fit: cover;
}
.div3 {
    position: absolute;
    top: 737px;
    left: 780px;
    font-size: 12px;
    font-weight: 800;
    font-family: NanumSquare;
    color: #666;
}
.div4 {
    position: absolute;
    top: 756px;
    left: 780px;
    font-size: 12px;
    font-family: NanumSquare;
    color: #666;
}
.b {
    position: absolute;
    top: 409px;
    left: 866px;
    font-family: NanumSquare;
    color: #3c3c3c;
}
.rectangleParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1920px;
    height: 1080px;
}
.login1 {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1080px;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    color: #999;
    font-family: Roboto;
}

