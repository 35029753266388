.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 1205px;
}

.icon {
    position: absolute;
    top: 49px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 51px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.p {
    margin-block-start: 0;
    margin-block-end: 10px;
}

.p1 {
    margin: 0;
}

.div1 {
    position: absolute;
    top: 123px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
}

.div2 {
    position: absolute;
    top: 219px;
    left: 20px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
    cursor: pointer;
}

.div3 {
    position: absolute;
    top: 219px;
    left: 79px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
    cursor: pointer;
}

.div4 {
    position: absolute;
    top: 219px;
    left: 151px;
    line-height: 15px;
    font-weight: 800;
    color: #222;
    cursor: pointer;
}

.div5 {
    position: absolute;
    top: 495px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
}

.div6 {
    position: absolute;
    top: 808px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
}

.div7 {
    position: absolute;
    top: 530px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}

.bizvdigmcom1 {
    font-weight: 600;
    font-family: Roboto;
    color: #222;
}

.bizvdigmcom {
    margin-block-start: 0;
    margin-block-end: 8px;
}

.bizvdigmcomContainer {
    position: absolute;
    top: 843px;
    left: 20px;
    line-height: 15px;
    color: #3c3c3c;
}

.div8 {
    position: absolute;
    top: 284px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
}

.child {
    position: absolute;
    top: 0px;
    left: 13px;
    border-radius: 50%;
    background-color: #ff038d;
    width: 64px;
    height: 64px;
}

.item {
    position: absolute;
    top: 0px;
    left: 184px;
    border-radius: 50%;
    background-color: #ff038d;
    width: 64px;
    height: 64px;
}

.inner {
    position: absolute;
    top: 12px;
    left: 99px;
    border-radius: 50%;
    background-color: #8200ff;
    width: 64px;
    height: 64px;
}

.ellipseDiv {
    position: absolute;
    top: 12px;
    left: 270px;
    border-radius: 50%;
    background-color: #8200ff;
    width: 64px;
    height: 64px;
}

.vectorIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 346px;
    height: 76px;
}

.div10 {
    position: absolute;
    top: 24px;
    left: 18px;
    line-height: 15px;
    font-weight: 800;
}

.div11 {
    position: absolute;
    top: 32px;
    left: 105px;
    line-height: 15px;
    font-weight: 800;
}

.div12 {
    position: absolute;
    top: 36px;
    left: 277px;
    line-height: 15px;
    font-weight: 800;
}

.div13 {
    position: absolute;
    top: 20px;
    left: 191px;
    line-height: 15px;
    font-weight: 800;
}

.div14 {
    position: absolute;
    top: 35px;
    left: 198px;
    font-size: 10px;
    line-height: 15px;
}

.div15 {
    position: absolute;
    top: 47px;
    left: 112px;
    font-size: 10px;
    line-height: 15px;
}

.div9 {
    position: absolute;
    top: 319px;
    left: 22px;
    width: 346px;
    height: 76px;
    text-align: center;
    color: #fff;
}

.rectangleDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: 0px 0px 5.7px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: #fff;
    width: 350px;
    height: 50px;
}

.imageIcon {
    position: absolute;
    top: 17px;
    left: 133px;
    width: 84px;
    height: 16px;
    object-fit: cover;
}

.div16 {
    position: absolute;
    top: 658px;
    left: 20px;
    width: 350px;
    height: 50px;
}

.typeLogoIcon {
    position: absolute;
    top: 15px;
    left: 133.5px;
    width: 83px;
    height: 20px;
    object-fit: cover;
}

.div17 {
    position: absolute;
    top: 588px;
    left: 20px;
    width: 350px;
    height: 50px;
}

.icon2 {
    position: absolute;
    top: 902px;
    left: 20px;
    width: 350px;
    height: 210px;
    object-fit: cover;
}

.div18 {
    position: absolute;
    top: 1266px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}

.b {
    position: absolute;
    top: 1301px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.rdCenter {
    position: absolute;
    top: 1326px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.c304 {
    position: absolute;
    top: 1346px;
    left: 103px;
    line-height: 15px;
    color: #999;
}

.tel82322771700 {
    position: absolute;
    top: 1371px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.mailBizvdigmcom {
    position: absolute;
    top: 1396px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.icon3 {
    position: absolute;
    top: 1214px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}

.icon4 {
    position: absolute;
    top: 1461px;
    left: 326px;
    width: 44px;
    height: 44px;
}

.child2 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div19 {
    position: absolute;
    top: 1431px;
    left: 20px;
    width: 24px;
    height: 24px;
}

.child3 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div20 {
    position: absolute;
    top: 1431px;
    left: 54px;
    width: 24px;
    height: 24px;
}

.icon5 {
    position: absolute;
    top: 83px;
    left: 385px;
    width: 0px;
    height: 1420px;
    object-fit: contain;
    opacity: 0.6;
}

.icon6 {
    position: absolute;
    top: 567px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 1205px;
    left: 50%;
    transform: translate(-50%);
}

.div {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1205px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #222;
    font-family: NanumSquare;
}