/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .footer {
        position: relative;
        left: 0px;
        margin-top: auto;
        background-color: #fff;
        width: 100%;
        position: block;
        z-index: 7;
        overflow: hidden;
        text-align: left;
        font-size: 14px;
        color: #666;
        height: 415px;
        transform: translateY(0%);
        /* display: flex; */
    
    }
    .groupChild4 {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #e5e5e5;
        /* width: 1920px; */
        width: 100%;
        /* width: 3000px; */
        height: 10px;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 1920px;
        height: 415px;
        font-size: 16px;
        color: #999;
        font-family: NanumSqure;
        font-weight: 700;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .group {
        position: absolute;
        top: 108px;
        left: 130px;
        width: 1920px;
        height: 415px;
        font-size: 16px;
        color: #999;
    }
    
    .div3 {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 20px;
        font-weight: 800;
        color: #666;
    
    
    
    
    }
    .b9 {
        position: absolute;
        top: 73px;
        left: 0px;
    
    
    
    
    }
    .rdCenter {
        position: absolute;
        top: 111px;
        left: 0px;
    
    
    
    
    }
    .tel82322771700 {
        position: absolute;
        top: 149px;
        left: 0px;
    
    
    
    
    }
    .mailBizvdigmcom {
        position: absolute;
        top: 187px;
        left: 0px;
    
    
    
    
    }
    .div4 {
        position: absolute;
        top: 108px;
        right: 320px;
        width: 40px; 
        height: 40px;
    
    
    
    
    }
    .child {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #d73116;
        width: 40px;
        height: 40px;
    
    
    
    
    }
    .instagram328841319204Icon {
        position: absolute;
        top: 11.67px;
        left: 8.33px;
        width: 23.3px;
        height: 16.7px;
        object-fit: cover;
    
    
    
    
    }
    
    .item {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #025db3;
        width: 40px;
        height: 40px;
    
    
    
    
    }
    .instagram328841319203Icon {
        position: absolute;
        top: 9.17px;
        left: 10.83px;
        width: 20px;
        height: 20px;
        object-fit: cover;
    
    
    
    
    }
    .div5 {
        position: absolute;
        top: 108px;
        right: 260px;
        width: 40px;
        height: 40px;
    
    
    
    
    
    }
    .tihLogo {
        position: absolute;
        top: 108px;
        right: 196px;
        width: 40px;
        height: 40px;
    }
    .tihLogoBackground {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 40px;
        height: 40px;
    }
    .tihLogoMask {
        position: absolute;
        top: 9.17px;
        left: 10.83px;
        width: 20px;
        height: 20px;
        object-fit: cover;
    }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1279px) {
    .footer {
        position: relative;
        left: 0px;
        margin-top: auto;
        background-color: #fff;
        width: 100%;
        position: block;
        z-index: 7;
        overflow: hidden;
        text-align: left;
        font-size: 13px;
        color: #666;
        height: 332px;
        transform: translateY(0%);
        /* display: flex; */
    
    }
    .groupChild4 {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #e5e5e5;
        /* width: 1920px; */
        width: 100%;
        /* width: 3000px; */
        height: 10px;
    }
    .rectangleParent {
        position: absolute;
        top: 0px;
        /* left: 0px; */
        width: 100%;
        height: 332px;
        font-size: 13px;
        color: #999;
        font-family: NanumSqure;
        font-weight: 700;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        overflow: hidden;
    }
    .group {
        position: absolute;
        top: 86.4px;
        left: 130px;
        width: 100%;
        height: 332px;
        font-size: 13px;
        color: #999;
    }
    
    .div3 {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 17px;
        font-weight: 800;
        color: #666;
    
    
    
    
    }
    .b9 {
        position: absolute;
        top: 58.4px;
        left: 0px;
    
    
    
    
    }
    .rdCenter {
        position: absolute;
        top: 88.8px;
        left: 0px;
    
    
    
    
    }
    .tel82322771700 {
        position: absolute;
        top: 119.2px;
        left: 0px;
    
    
    
    
    }
    .mailBizvdigmcom {
        position: absolute;
        top: 149.6px;
        left: 0px;
    
    
    
    
    }
    .div4 {
        position: absolute;
        top: 86.4px;
        right: 256px;
        width: 32px; 
        height: 32px;
        /* right: auto; */
        /* left: 946.66px; */
    
    
    
    }
    .child {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #d73116;
        width: 32px;
        height: 32px;
    
    
    
    
    }
    .instagram328841319204Icon {
        position: absolute;
        top: 9.336px;
        left: 7.33px;
        width: 18.64px;
        height: 13.36px;
        object-fit: cover;
    
    
    
    
    }
    
    .item {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #025db3;
        width: 32px;
        height: 32px;
    
    
    
    
    }
    .instagram328841319203Icon {
        position: absolute;
        top: 7.336px;
        left: 8.5px;
        width: 16px;
        height: 16px;
        object-fit: cover;
    
    
    
    
    }
    .div5 {
        position: absolute;
        top: 86.4px;
        right: 208px;
        width: 32px;
        height: 32px;
        /* right: auto; */
        /* right: 260px; */

        /* left: 1006.67px; */
    
    
    
    }
    .tihLogo {
        position: absolute;
        top: 86.4px;
        right: 158px;
        width: 32px;
        height: 32px;
    }
    .tihLogoBackground {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background-color: #8200ff;
        width: 32px;
        height: 32px;
    }
    .tihLogoMask {
        position: absolute;
        top: 7.336px;
        left: 8.5px;
        width: 16px;
        height: 16px;
        object-fit: cover;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}

@media (max-width: 1920px) {
    .rectangleParent {
        width: 100%;
    }
}






