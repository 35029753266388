.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 390px;
    height: 701px;
}

.icon {
    position: absolute;
    top: 50px;
    left: 19px;
    width: 92px;
    height: 24px;
    object-fit: cover;
}

.icon1 {
    position: absolute;
    top: 52px;
    left: 344px;
    width: 24px;
    height: 18px;
}

.div1 {
    position: absolute;
    top: 220px;
    left: 20px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
}

.div2 {
    position: absolute;
    top: 220px;
    left: 137px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
}

.div3 {
    position: absolute;
    top: 220px;
    left: 91px;
    line-height: 15px;
    color: #666;
    font-weight: 400;
}

.div4 {
    position: absolute;
    top: 220px;
    left: 196px;
    line-height: 15px;
    font-weight: 800;
}

.ai {
    margin-block-start: 0;
    margin-block-end: 10px;
}

.p {
    margin: 0;
}

.aiContainer {
    position: absolute;
    top: 124px;
    left: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
    color: #222;
}

.div5 {
    position: absolute;
    top: 762px;
    left: 20px;
    line-height: 15px;
    font-weight: 800;
    color: #666;
}

.b {
    position: absolute;
    top: 797px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.rdCenter {
    position: absolute;
    top: 822px;
    left: 20px;
    line-height: 15px;
    color: #999;
}

.c304 {
    position: absolute;
    top: 842px;
    left: 103px;
    line-height: 15px;
    color: #999;
}

.tel82322771700 {
    position: absolute;
    top: 867px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.mailBizvdigmcom {
    position: absolute;
    top: 892px;
    left: 20px;
    line-height: 15px;
    font-weight: 500;
    font-family: Roboto;
    color: #999;
}

.icon2 {
    position: absolute;
    top: 710px;
    left: 2px;
    max-height: 100%;
    width: 386px;
}

.icon3 {
    position: absolute;
    top: 957px;
    left: 326px;
    width: 44px;
    height: 44px;
}

.child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #d73116;
    width: 24px;
    height: 24px;
}

.instagram328841319204Icon {
    position: absolute;
    top: 7px;
    left: 5px;
    width: 14px;
    height: 10px;
    object-fit: cover;
}

.div6 {
    position: absolute;
    top: 927px;
    left: 20px;
    width: 24px;
    height: 24px;
}

.item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #025db3;
    width: 24px;
    height: 24px;
}

.instagram328841319203Icon {
    position: absolute;
    top: 5.5px;
    left: 6.5px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.div7 {
    position: absolute;
    top: 927px;
    left: 54px;
    width: 24px;
    height: 24px;
}

.groupItem {
    position: absolute;
    top: 990px;
    left: 326px;
    box-shadow: 0px 0px 8.27px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    background-color: #fff;
    width: 44px;
    height: 44px;
}


.groupInner {
    position: absolute;
    top: 10px;
    left: 86px;
    box-shadow: 0px 2px 6.3px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    background-color: #fff;
    width: 76px;
    height: 76px;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.b1 {
    position: absolute;
    top: 40px;
    left: 14px;
    line-height: 15px;
    font-weight: 700;
}

.ellipseParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 96px;
    height: 96px;
}

.div8 {
    position: absolute;
    top: 512px;
    left: 21px;
    width: 96px;
    height: 96px;
    text-align: center;
}

.groupChild2 {
    position: absolute;
    top: 48px;
    left: 0px;
    border-radius: 50%;
    border-radius: 0 0 48px 48px;
    background-color: #ff038d;
    width: 96px;
    height: 48px;
}

.p1 {
    margin-block-start: 0;
    margin-block-end: 6px;
}

.b3 {
    position: absolute;
    top: 32px;
    left: 14px;
    line-height: 15px;
    font-weight: 700 ;
}

.div9 {
    position: absolute;
    top: 512px;
    left: 148px;
    width: 96px;
    height: 96px;
    text-align: center;
}

.inner {
    position: absolute;
    top: 48px;
    left: 96px;
    border-radius: 0 0 48px 48px;
    background-color: #8200ff;
    width: 96px;
    height: 48px;
    transform: rotate(-180deg);
    transform-origin: 0 0;
}

.b4 {
    position: absolute;
    top: 30px;
    left: 14px;
    line-height: 15px;
}

.div10 {
    position: absolute;
    top: 512px;
    left: 274px;
    width: 96px;
    height: 96px;
    text-align: center;
}

.lineIcon {
    position: absolute;
    top: 98px;
    left: 2px;
    width: 35.4px;
    height: 35.4px;
    object-fit: contain;
}

.child2 {
    position: absolute;
    top: 98px;
    left: 141px;
    width: 35.4px;
    height: 35.4px;
    object-fit: contain;
}

.child3 {
    position: absolute;
    top: 119px;
    left: 68px;
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.child4 {
    position: absolute;
    top: 0px;
    left: 159px;
    border-radius: 50%;
    background: linear-gradient(128.82deg, #8200ff 27.59%, #ff038d);
    width: 133px;
    height: 133px;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.child5 {
    position: absolute;
    top: 14px;
    left: 145px;
    border-radius: 50%;
    background-color: #fff;
    width: 105px;
    height: 105px;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.vision {
    position: absolute;
    top: 59px;
    left: 66px;
    line-height: 15px;
    font-weight: 800;
}

.div11 {
    position: absolute;
    top: 321px;
    left: 103px;
    width: 176.4px;
    height: 169px;
    text-align: center;
    font-size: 18px;
    font-family: Roboto;
}

.div12 {
    position: absolute;
    top: 285px;
    left: 20px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
}

.icon4 {
    position: absolute;
    top: 102px;
    left: 385px;
    width: 0px;
    height: 897px;
    object-fit: contain;
    opacity: 0.6;
}

.icon5 {
    position: absolute;
    top: 567px;
    left: 385px;
    width: 0px;
    height: 200px;
    object-fit: contain;
}

.rectangleParent {
    position: absolute;
    top: 0px;
    /* left: 0px; */
    width: 390px;
    height: 701px;
    left: 50%;
    transform: translate(-50%);
}

.div {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 701px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #222;
    font-family: NanumSquare;
}