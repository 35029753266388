/* PC (해상도 1024px)*/
@media all and (min-width:1280px) {
    .youtubes {
        position: absolute;
        /* left: 19.27%; */
        width: 1165px;
        left: 50%; /* 요소의 가운데를 부모 요소의 가운데로 이동 */
        transform: translateX(-50%); /* 요소의 가운데를 부모 요소의 가운데로 이동 */
    }
    .title1111 {
        position: absolute;
        top: 1352px;
        left: 0%;
        font-size: 24px;
        font-weight: 800;
        font-family: NanumSquare;
    }
    .title1111En {
        position: absolute;
        /* top: 1352px; */
        top: 1353px;
        /* left: 370px; */
        left: 0%;
    
        font-size: 24px;
        font-weight: 800;
        font-family: Roboto;
    }
    .visitKo {
        position: absolute;
        top: 1356px;
        /* left: 1430px; */
        /* left: 1445px; */
        left: 93%;
    
    
        color: #666;
        text-align: right;
    }
    .visitEn {
        position: absolute;
        top: 1357px;
        /* left: 1505px; */
        /* left: 1535px; */
        /* left: 79.95%; */
        /* left: 60.7%; */
        left: 100%;
    
        color: #666;
        text-align: right;
        font-family: Roboto;
    }
    .div2 {
        position: absolute;
        top: 1380px;
        /* top: 1429px; */
    
        left: 0%;
        width: 1200px;
        /* width: 62.5%; */
    
        /* height: 275px; */
        height: 400px;
    
    
    
    }
    .youtubeLink {
        text-decoration: none; /* 링크 밑줄 제거 */
        color: inherit;
    }
    .swiperMain {
        position: relative;
        top: -30px;
        /* width: 100%;
        height: 100%; */
        
        width: 380px;
        height: 275px;
    
        border-radius: 10px;
        /* object-fit: cover; */
        display: flex;
        /* align-items: center; */
    
    
    
    }
    
    .title {
        position: absolute;
        top: 291px;
        /* text-align: center; */
        transform: translateX(-50%);
        /* left: -10px; */
        line-height: 18.75px;
        color: #3c3c3c;
        /* font-family: Roboto; */
        font-weight: 600;
        font-size: 16px;
        text-decoration: none;
    
    
    }
}

/* 테블릿 가로, 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:840px) and (max-width:1279px) {
    .youtubes {
        position: absolute;
        /* left: 19.27%; */
        width: 700px;
        left: 50%; /* 요소의 가운데를 부모 요소의 가운데로 이동 */
        transform: translateX(-50%); /* 요소의 가운데를 부모 요소의 가운데로 이동 */
    }
    .title1111 {
        position: absolute;
        /* top: 1352px; */
        top: 1081.6px;

        left: 0%;
        font-size: 20px;
        font-weight: 800;
        font-family: NanumSquare;
    }
    .title1111En {
        position: absolute;
        /* top: 1352px; */
        top: 1081.6px;
        /* left: 370px; */
        left: 0%;
    
        font-size: 20px;
        font-weight: 800;
        font-family: Roboto;
    }
    .visitKo {
        position: absolute;
        /* top: 1356px; */
        top: 1085.6px;

        /* left: 1430px; */
        /* left: 1445px; */
        /* left: 1450px; */
        left: 90%;
        font-size: 15px;
        font-family: NanumSquare;
    
        color: #666;
        text-align: right;
    }
    .visitEn {
        position: absolute;
        top: 1085.6px;
        /* left: 1505px; */
        /* left: 1535px; */
        /* left: 79.95%; */
        /* left: 60.7%; */
        left: 100%;
        font-size: 15px;
        color: #666;
        text-align: right;
        font-family: Roboto;
    }
    .div2 {
        position: absolute;
        top: 1104px;
        /* top: 1429px; */
    
        left: 0%;
        width: 730px;
        /* width: 62.5%; */
    
        /* height: 275px; */
        height: 275px;
    
    
    
    }
    .youtubeLink {
        text-decoration: none; /* 링크 밑줄 제거 */
        color: inherit;
    }
    .swiperMain {
        position: relative;
        top: -30px;
        /* width: 100%;
        height: 100%; */
        
        width: 304px;
        height: 167.75px;
    
        border-radius: 10px;
        /* object-fit: cover; */
        display: flex;
        /* align-items: center; */
    
    
    
    }
    
    .title {
        position: absolute;
        top: 180px;
        /* text-align: center; */
        transform: translateX(-50%);
        /* left: -10px; */
        line-height: 18.75px;
        color: #3c3c3c;
        /* font-family: Roboto; */
        font-weight: 600;
        font-size: 13px;
        text-decoration: none;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    /*스타일입력*/
}



.div {
    position: absolute;
    top: 750px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    color: #222;



}
.manager {
    position: absolute;
    top: 1275px;
    left: 50px;
    z-index: 10;



}
.div1 {
    position: absolute;
    top: 1006px;
    left: 20px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 800;
    color: #222;



}

.b {
    position: absolute;
    top: 1006px;
    left: 326px;
    font-size: 12px;
    line-height: 15px;
    color: #666666;



}

.swiper1 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* object-fit: cover; */
    display: flex;
    /* align-items: center; */



}


/* .titleKo {
    position: relative;
    top: 917px;
    left: 304px;

    line-height: 50px;
    color: #3c3c3c;
}
.titleEn {
    position: relative;
    top: 917px;
    left: 324px;

    line-height: 50px;
    color: #3c3c3c;
} */

.groupItem1111 {
    position: absolute;
    top: 1429px;
    left: 370px;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 380px;
    height: 275px;
}
.groupInner1111 {
    position: absolute;
    top: 1429px;
    left: 770px;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 380px;
    height: 275px;
}
.rectangleDiv1111 {
    position: absolute;
    top: 1429px;
    left: 1170px;
    border-radius: 10px;
    background-color: #d9d9d9;
    width: 380px;
    height: 275px;
}
.groupIcon1111 {
    position: absolute;
    top: 1754px;
    left: 892px;
    width: 136px;
    height: 14px;
}

.ellipseDiv1111 {
    position: absolute;
    top: 716px;
    left: 956px;
    border-radius: 50%;
    background-color: #fff;
    width: 8px;
    height: 8px;
}
.groupChild21111 {
    position: absolute;
    top: 716px;
    left: 938px;
    border-radius: 50%;
    background-color: #ff038d;
    width: 8px;
    height: 8px;
}
.groupChild31111 {
    position: absolute;
    top: 716px;
    left: 974px;
    border-radius: 50%;
    background-color: #fff;
    width: 8px;
    height: 8px;
}

.groupChild41111 {
    position: absolute;
    top: 2018px;
    left: 0px;
    background-color: #e5e5e5;
    width: 1920px;
    height: 10px;
}
.groupChild51111 {
    position: absolute;
    top: 1924px;
    left: 1746px;
    width: 44px;
    height: 44px;
}